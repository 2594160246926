import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import { useCamera } from "./useCamera";

const AnimateCamera = (props) => {
  const { intitalCameraSettings, initialControlsSettings } = props;

  const { cameraRef, controlsRef, onChange } = useCamera();

  return (
    <>
      <OrbitControls
        onChange={onChange}
        ref={controlsRef}
        {...initialControlsSettings}
      />
      <PerspectiveCamera
        ref={cameraRef}
        makeDefault
        {...intitalCameraSettings}
      />
    </>
  );
};
export default AnimateCamera;
