import { useState, useEffect, useRef, useMemo, memo, useCallback } from "react"
import { createPortal } from "react-dom"
import styled from "styled-components/macro"
import gsap from "gsap"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import MenuButton from "@common/ui/MenuButton"
import Logo from "@common/ui/Logo"
import ListItem from "@common/ui/ListItem"
import { useProjectDataStore } from "@state/projectDataStore"
import { getColor } from "@utils/getColor"

interface Route {
  navLabel: string
  pageType: string
  include: string
  id: string
}

interface Branding {
  navAlign?: "left" | "right"
  navShapeSize?: string
  colors?: {
    background?: string
    primary?: string
  }
}

function WindowNav() {
  const { brandingData, navigationData } = useProjectDataStore((s) => ({
    brandingData: s.brandingData,
    navigationData: s.navigationData,
  }))

  const routes =
    navigationData?.menuItems.filter((item) => item.include === "true") || []

  const withTour = [
    ...routes.slice(0, 4),
    { navLabel: "Show Home", include: "true", pageType: "showhome" },
    ...routes.slice(4),
  ]

  const [open, setOpen] = useState(false)
  const [animating, setAnimating] = useState(false)
  console.log(animating)
  const [dimensions, setDimensions] = useState([innerWidth, innerHeight])
  const [shapeColor, setShapeColor] = useState("var(--accent)")
  const menuRef = useRef<HTMLElement>(null)
  const downCoords = useRef([0, 0])
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const openNavParam = searchParams.get("openNav")
    if (openNavParam !== null) {
      setTimeout(() => setOpen(true), 500)
    }
  }, [searchParams])

  const navigate = useNavigate()

  const handleDown = useCallback(
    function handleDown(e, route, i) {
      if (animating || !open) return
      navigate(route)
      setShapeColor(`var(--${hoverColors[i]})`)
      downCoords.current = [e.clientX, e.clientY]
      gsap.set("#mask-rect-element", {
        attr: {
          x: downCoords.current[0],
          y: downCoords.current[1],
        },
      })
    },
    [animating, open],
  )

  function handleUp() {
    gsap.to("#mask-rect-element", {
      attr: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
      },
      duration: 1,
      ease: "power1.inOut",
      onComplete: () => {
        setOpen(false)
        setTimeout(() => {
          setAnimating(false)
        }, 300)
      },
    })
  }

  useEffect(() => {
    if (!menuRef.current) return
    gsap.to(menuRef.current, {
      autoAlpha: open ? 1 : 0,
      delay: open ? 0 : 0.2,
      onStart: () => {
        open &&
          gsap.set("#mask-rect-element", {
            attr: {
              width: 0,
              height: 0,
            },
          })
      },
    })
  }, [open])

  const align = brandingData?.navAlign || "left"
  const shapeSize = brandingData?.navShapeSize || "min(10vh, 10vw)"
  const logoWidth = navigationData?.logoWidth || 12.25
  const navLinkFontColour = getColor(navigationData?.fontColour)
  const hoverColors = [
    "accent",
    "secondary",
    "accent",
    "secondary",
    "accent",
    "green",
    "secondary",
    "green",
  ]
  const { search } = useLocation()

  useEffect(() => {
    window.addEventListener("resize", () =>
      setDimensions([innerWidth, innerHeight]),
    )

    return () => {
      window.removeEventListener("resize", () =>
        setDimensions([innerWidth, innerHeight]),
      )
    }
  }, [])

  return (
    <>
      <Nav
        $bgColor={getColor(navigationData?.bgColor)}
        $shapeSize={shapeSize}
        $align={align}
        ref={menuRef}
      >
        <LinksWrapper>
          {withTour
            ?.filter(
              (route) => route?.include === "true" && route?.path !== "splash",
            )
            ?.map(({ navLabel, pageType }, i) => {
              return (
                <ListItem
                  brandingData={brandingData}
                  fontSize={6}
                  fontColour={navLinkFontColour}
                  key={`${pageType}`}
                  onPointerEnter={() => {
                    setShapeColor(`var(--${hoverColors[i]})`)
                  }}
                  onPointerLeave={() => {
                    setShapeColor("var(--accent)")
                  }}
                  onPointerDown={(e) => {
                    if (animating) true
                    setAnimating(true)
                    handleDown(e, `${pageType}${search}`, i)
                  }}
                  onPointerUp={handleUp}
                >
                  {navLabel}
                </ListItem>
              )
            })}
        </LinksWrapper>
        {navigationData?.shapeColour !== "none" && (
          <>
            {
              <svg
                width="105"
                height="91"
                viewBox="0 0 105 91"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ position: "absolute", bottom: "0", left: "10%" }}
              >
                <path
                  d="M104.377 91H0.143066L53.4202 0.579091L104.377 91Z"
                  fill={shapeColor}
                />
              </svg>
            }
            {
              <svg
                width="105"
                height="91"
                viewBox="0 0 105 91"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ position: "absolute", top: "0", right: "10%" }}
              >
                <path
                  d="M104.377 0H0.143066L53.4202 90.4209L104.377 0Z"
                  fill={shapeColor}
                />
              </svg>
            }
          </>
        )}
      </Nav>
      <Icons $shapeSize={shapeSize} $align={align}>
        <MenuButton
          brandingData={brandingData}
          navigationData={navigationData}
          onClick={() => {
            setOpen(!open)
          }}
          animationCue={open}
        />
        <Logo
          width={logoWidth}
          onPointerUp={() => {
            navigate("/")
            setOpen(false)
          }}
          style={{
            zIndex: 10,
            pointerEvents: "auto",
          }}
        />
      </Icons>
      <SVGMask innerWidth={dimensions[0]} innerHeight={dimensions[1]} />
    </>
  )
}

export default WindowNav

type TNavStyledProps = {
  $bgColor?: string
  $shapeSize: string
  // $shapeColor?: string
  $align: "left" | "right"
}

const Nav = styled.nav<TNavStyledProps>`
  background-color: ${(p) => p.$bgColor || "var(--primary)"};
  --shapeSize: ${(p) => p.$shapeSize};
  --align: ${(p) => p.$align};

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  mask: url(#use-mask-gsap);
  & svg path {
    transition: fill 0.4s ease-in-out;
  }
  @media (max-width: 480px) {
    svg {
      max-width: 40px;
      height: auto;
    }
  }
  @media (max-width: 720px) {
    svg {
      max-width: 60px;
      height: auto;
    }
  }
  @media (min-width: 2051px) and (max-width: 3839px) {
    svg {
      width: 150px;
      max-width: 150px;
      height: auto;
    }
  }
  @media (min-width: 3840px){
    svg {
      width: 300px;
      max-width: 300px;
      height: auto;
    }
  }
`

const Icons = styled.div<{ $shapeSize: string; $align: "left" | "right" }>`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  gap: 2rem;
  flex-direction: ${(p) => (p.$align === "left" ? "row" : "row-reverse")};
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  pointer-events: none;
  width: 100%;

  @media (min-width: 721px) and (max-width: 1080px) {
    & div {
      height: 5rem;
      width: auto;
    }
  }
`

const LinksWrapper = styled.div`
  position: absolute;
  width: 65%;
  left: 17.5%;
  top: 10%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1.5rem;
  --size: clamp(10px, 4rem, min(4.5vh, 4.5vw));

  & h1 {
    font-size: var(--size) !important;
    opacity: 1 !important;
    transition: color 0.4s ease-in-out !important;
    padding: 0.8rem 0 0.8rem 0;

    :nth-last-of-type(1),
    :nth-last-of-type(2) {
      font-size: calc(var(--size) * 0.5) !important;
    }
    :nth-last-of-type(2) {
      border-radius: 0 !important;
      border-top: 1px solid white;
    }

    :hover {
      :nth-of-type(even) {
        color: var(--secondary) !important;
      }
      :nth-of-type(odd) {
        color: var(--accent) !important;
      }
      :nth-last-of-type(1),
      :nth-last-of-type(3) {
        color: var(--green) !important;
      }
    }
  }
`

const SVGMask = memo(
  ({
    innerWidth,
    innerHeight,
  }: {
    innerWidth: number
    innerHeight: number
  }) => {
    return createPortal(
      <svg
        id="masking-svg-defs"
        width="100%"
        height="100%"
        viewBox={`0 0 ${innerWidth} ${innerHeight}`}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          pointerEvents: "none",
        }}
      >
        <defs>
          <mask id="use-mask-gsap">
            <rect width="100%" height="100%" x="0" y="0" fill="white" />
            <rect
              id="mask-rect-element"
              width="0%"
              height="0%"
              x="50%"
              y="50%"
              fill="black"
              style={{
                transformOrigin: "center",
                transformBox: "fill-box",
              }}
            >
              <title>Mask Rectangle</title>
            </rect>
          </mask>
        </defs>
      </svg>,
      document.body,
    )
  },
)
