import React from "react";
import styled from "styled-components";
import { useOrbit3DStore } from "../state/orbit3DStore";
import Title from "@common/ui/Title";

export type ViewLayoutHandle = {
  on: () => void;
  off: () => void;
};

export const ViewUI = ({ pointsMapped }) => {
  return (
    <>
      {pointsMapped.map((info, index) => (
        <ViewLayout key={index} index={index} text={info.text} />
      ))}
    </>
  );
};

const ViewLayout = ({ text, index }) => {
  const { title, subTitle, body } = text;
  const [active, setActive] = React.useState(false);
  const activeView = useOrbit3DStore((s) => s.activeView);

  React.useEffect(() => {
    if (activeView[0] === index) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [activeView]);

  return (
    <OrbitInfo
      style={{
        opacity: active ? 1 : 0,
      }}
      id="orbit-info"
    >
      <Title main={title} sub={subTitle} body={body} align="left" />
    </OrbitInfo>
  );
};

const OrbitInfo = styled.div`
  z-index: 1;
  transition: opacity 0.5s;
  pointer-events: none;
  position: absolute;
  bottom: 8rem;
  left: 2rem;
  font-size: 2.5rem;
  width: clamp(30vw, 35vw, 1000px);
`;

export default ViewUI;
