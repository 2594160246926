import { Billboard, Html } from "@react-three/drei"
import { useStore } from "@state/store"
import { useRef } from "react"
import { Vector3 } from "three"

const BuildingTouchPoints = () => {
  const filteredUnits = useStore((s) => s.filteredUnits)

  const residencePopup = useStore((s) => s.residencePopup)
  const filters = useStore((s) => s.filters)

  const listUnits = (list, blockname) => {
    return list.filter((unit) => {
      return unit.active && unit?.unitName.includes(blockname)
    })
  }

  const data = {
    "0": {
      positions: { x: 9, y: 10, z: -16 },
      title: "Oak House",
      value: listUnits(filteredUnits, "N01B").length,
    },
    "1": {
      positions: { x: 5, y: 10, z: 2 },
      title: "Cherry Point",
      value: listUnits(filteredUnits, "S01C").length,
    },
  }
  return (
    <>
      <TouchPoint
        data={data["0"]}
        index={1}
        active={
          !filters?.blocks?.includes("S01C") &&
          !residencePopup &&
          !filters?.blocks?.length
        }
      />
      <TouchPoint
        data={data["1"]}
        index={1}
        active={
          !filters?.blocks?.includes("N01B") &&
          !residencePopup &&
          !filters?.blocks?.length
        }
      />
    </>
  )
}

const TouchPoint = (props) => {
  const { data, handleClickTouchPoint, active } = props
  const { positions } = data

  const hide = data.value === 0

  const domRef = useRef()

  if (hide) {
    return null
  }

  return (
    <group
      scale={10}
      onClick={() => {
        handleClickTouchPoint(data)
      }}
      position={new Vector3(positions.x, positions.y, positions.z)}
    >
      <Billboard lockX={true} lockY={false} lockZ={false}>
        {data?.title && (
          <group>
            <Html zIndexRange={[1, 0]} color="white" center={true}>
              <div
                ref={domRef}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "19em",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1
                  style={{
                    fontSize: "2.75rem",
                    fontWeight: 600,
                    color: "#fff",
                  }}
                >
                  {data.title}
                </h1>
                <h2
                  style={{
                    fontFamily: "secondaryFont",
                    fontSize: "2.25rem",
                    fontWeight: 600,
                    color: "#f98557",
                  }}
                >
                  {data.value} Available units
                </h2>
              </div>
            </Html>
          </group>
        )}
      </Billboard>
    </group>
  )
}

export default BuildingTouchPoints
