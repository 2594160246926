import styled from "styled-components/macro"

const Location = ({ children }) => {
  return <LocationWr>{children}</LocationWr>
}

const LocationWr = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: var(--primary);
  color: #fff;
  padding: 8em 2em 2em 2em;
  gap: 2em;

  .map-categories,
  .map-categories-top {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid #fff;

    &.visible {
      opacity: 1;
      z-index: 1;
      transition: all 1s;
    }

    &.hidden {
      opacity: 0;
      z-index: -1;
      transition: all 0.3s;
    }

    .buttons {
      min-width: 6em;
      border: none;
      flex: 1;

      &.active {
        background-color: var(--accent);
      }
    }
  }

  .map-categories-top {
    margin-top: 8em;
  }

  .locationCategory {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .boxes {
    position: relative;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    margin-top: 3em;
    gap: 2em;
    flex: 1;
    overflow-y: auto;
    /* height: calc(100% - 7em + 5px); */
    height: calc(100% - 15em + 5px);

    &.visible {
      opacity: 1;
      z-index: 1;
      transition: all 1s;
    }

    &.hidden {
      opacity: 0;
      z-index: -1;
      transition: all 0.3s;
    }

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  .map-poi-box {
    border: 1px solid #fff;
    background-color: var(--primary);

    &.active {
      border: 2px solid var(--secondary);
    }
  }

  .areas {
    z-index: 10;
    display: flex;
    flex-direction: row;
    top: 15%;
    left: 0;
    position: absolute;
    width: 15%;
    height: 100px;
  }

  .map-wr {
    position: relative;
    height: 100%;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;

    #map {
      flex: 1;
      height: 100%;
      width: 100%;

      .marker {
        opacity: 0;
        transition:
          opacity 400ms ease-in,
          transform 400ms ease-in;
      }

      .marker {
        opacity: 1;
      }
    }
  }

  .list-wr {
    position: relative;
    width: 25%;
    height: 100%;
  }

  @media (min-width: 1370px) {
    .map-wr {
      width: 80%;
    }

    .list-wr {
      width: 20%;
    }
  }

  .poi-filter {
    display: flex;
    width: 100%;
    border: 1px solid #ffffff;

    & div {
      width: 100%;
      height: 100%;
      text-align: center;
      padding: 1em;
      font-size: 1.15rem;
      border: none;
      transition-property: background-color, color;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;
    }
  }
`

export default Location
