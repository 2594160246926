import Logo from "@common/ui/Logo"
import TextButton from "@common/ui/TextButton"
import { useProjectDataStore } from "@state/projectDataStore"
import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components/macro"
import SplashEnter from "./components/SplashEnter"
import { getColor } from "@utils/getColor"

function Fullscreen_Splash() {
  const { splashData, brandingData } = useProjectDataStore((s) => ({
    splashData: s.splashData,
    brandingData: s.brandingData,
  }))
  const logo = brandingData?.logos[splashData?.logo]
  const logoWidth = splashData?.logoWidth
  //
  const backgroundType = splashData?.splashBg
  const backgroundMedia =
    backgroundType === "image/video"
      ? "/images/westHamSplash-2.jpg"
      : `var(--${backgroundType})`
  const isBackgroundMedia = backgroundMedia?.includes(".")
  const backgroundOpacity = splashData?.splashBgMediaOpacity
  //
  const heroCopy = splashData?.heroCopy
  const heroCopyFont = splashData?.heroCopyFont
  const heroCopyFontColor = splashData?.heroCopyColour
  const heroCopyStyle = splashData?.heroCopyStyle
  //
  const buttonBgColour = splashData?.buttonBgColour
  const buttonWidth = splashData?.buttonWidth
  const buttonBgLink = splashData?.buttonBgLink

  //
  const words = heroCopy?.trim().split(/\s+/)
  const allWords = words?.slice(0, -1).join(" ")
  let lastWord
  if (words) lastWord = words[words?.length - 1]
  const navigate = useNavigate()

  return (
    <SplashWr
      style={{
        backgroundColor: isBackgroundMedia ? "black" : backgroundMedia,
      }}
    >
      <SplashBgVideo
        style={{
          opacity: backgroundOpacity || 0.5,
          zIndex: -1,
        }}
        autoPlay
        muted
        loop
        playsInline
        src="/WEST_HAM.mp4"
      />
      {/* <SplashLogo
        style={{
          width: `${logoWidth || 40}rem`,
        }}
        src={logo?.publicURL}
      /> */}
      <Button>
        <SplashText
          $fontColor={getColor(heroCopyFontColor)}
          $font={heroCopyFont}
        >
          {allWords}{" "}
          {heroCopyStyle === "italicEnd" ? <span>{lastWord}</span> : lastWord}
        </SplashText>
        <SplashEnter
          icon="assets/icons/up-arrow.svg"
          width={buttonWidth}
          buttonBgColour={buttonBgColour}
          onClick={() => navigate(`/${buttonBgLink || "residences"}`)}
        />
      </Button>
    </SplashWr>
  )
}

const SplashWr = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .enterButton {
    border-radius: 100%;
    width: 3.25rem;
    height: 3.25rem;
    transform: rotate(90deg);
    color: var(--accent);
    border: 1px solid var(--accent);
  }
`

const SplashBgImg = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  object-fit: cover;
`
const SplashBgVideo = styled.video`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  object-fit: cover;
`

const SplashLogo = styled.img`
  margin: auto;
  width: 25%;
`

const SplashText = styled.div<{ $font: string; $fontColor: string }>`
  font-size: 4rem;
  font-family: ${({ $font }) => $font};
  color: ${({ $fontColor }) => $fontColor};

  span {
    font-family: ${({ $font }) =>
      $font === "primaryFont" ? "secondaryFont" : "primaryFont"};
    font-style: italic;
    letter-spacing: 0.05rem;
    color: ${({ $fontColor }) =>
      $fontColor === "var(--accent)" ? `var(--primary)` : `var(--accent)`};
  }
`

const Button = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 2rem;
  display: flex;
  gap: 2rem;
  align-items: center;
`

export default Fullscreen_Splash
