export function loopArrayIndex(dir: boolean, array: any[], index: number) {
  const length = array.length;
  let nextIndex;
  if (dir) {
    nextIndex = (index + 1) % length;
  } else {
    nextIndex = (index - 1 + length) % length;
  }
  return nextIndex;
}

export const poundFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "GBP",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})