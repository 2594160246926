export const poisWithDistances = [
  {
    name: "Star Lane Pizza Bar",
    description: " ",
    category: "Leisure",
    coordinates: {
      lat: 51.5213023534721,
      lng: 0.0025663613635026825,
    },
    distances: {
      BICYCLING: {
        distance: "0.5 mi",
        duration: "2 mins",
      },
      WALKING: {
        distance: "0.5 mi",
        duration: "11 mins",
      },
      DRIVING: {
        distance: "0.6 mi",
        duration: "4 mins",
      },
      TRANSIT: {
        distance: "0.5 mi",
        duration: "11 mins",
      },
    },
  },
  {
    name: "Studio Baristas",
    description: "",
    category: "Leisure",
    coordinates: {
      lat: 51.521520037523786,
      lng: 0.001910373003560795,
    },
    distances: {
      BICYCLING: {
        distance: "0.6 mi",
        duration: "3 mins",
      },
      WALKING: {
        distance: "0.6 mi",
        duration: "12 mins",
      },
      DRIVING: {
        distance: "0.6 mi",
        duration: "4 mins",
      },
      TRANSIT: {
        distance: "0.6 mi",
        duration: "12 mins",
      },
    },
  },
  {
    name: "Yapix",
    category: "Leisure",
    coordinates: {
      lat: 51.51489,
      lng: 0.01058,
    },
    distances: {
      BICYCLING: {
        distance: "1.1 mi",
        duration: "8 mins",
      },
      WALKING: {
        distance: "1.1 mi",
        duration: "25 mins",
      },
      DRIVING: {
        distance: "2.0 mi",
        duration: "9 mins",
      },
      TRANSIT: {
        distance: "1.2 mi",
        duration: "17 mins",
      },
    },
  },
  {
    name: "Rosetta Arts",
    category: "Leisure",
    coordinates: {
      lat: 51.52513,
      lng: 0.00756,
    },
    distances: {
      BICYCLING: {
        distance: "0.8 mi",
        duration: "5 mins",
      },
      WALKING: {
        distance: "0.8 mi",
        duration: "17 mins",
      },
      DRIVING: {
        distance: "0.8 mi",
        duration: "3 mins",
      },
      TRANSIT: {
        distance: "0.8 mi",
        duration: "17 mins",
      },
    },
  },
  {
    name: "Cody Dock",
    category: "Leisure",
    coordinates: {
      lat: 51.51914,
      lng: -0.00169,
    },
    distances: {
      BICYCLING: {
        distance: "0.8 mi",
        duration: "4 mins",
      },
      WALKING: {
        distance: "0.7 mi",
        duration: "16 mins",
      },
      DRIVING: {
        distance: "0.7 mi",
        duration: "5 mins",
      },
      TRANSIT: {
        distance: "0.7 mi",
        duration: "16 mins",
      },
    },
  },
  {
    name: "Arch1",
    category: "Leisure",
    coordinates: {
      lat: 51.52258,
      lng: 0.00496,
    },
    distances: {
      BICYCLING: {
        distance: "0.6 mi",
        duration: "4 mins",
      },
      WALKING: {
        distance: "0.6 mi",
        duration: "14 mins",
      },
      DRIVING: {
        distance: "0.7 mi",
        duration: "3 mins",
      },
      TRANSIT: {
        distance: "0.6 mi",
        duration: "14 mins",
      },
    },
  },
  {
    name: "LDN East",
    category: "Leisure",
    coordinates: {
      lat: 51.51718,
      lng: 0.00452,
    },
    distances: {
      BICYCLING: {
        distance: "0.7 mi",
        duration: "4 mins",
      },
      WALKING: {
        distance: "0.7 mi",
        duration: "16 mins",
      },
      DRIVING: {
        distance: "0.7 mi",
        duration: "3 mins",
      },
      TRANSIT: {
        distance: "0.7 mi",
        duration: "16 mins",
      },
    },
  },
  {
    name: "East London RFC",
    category: "Leisure",
    coordinates: {
      lat: 51.5293,
      lng: 0.0109,
    },
    distances: {
      BICYCLING: {
        distance: "1.2 mi",
        duration: "7 mins",
      },
      WALKING: {
        distance: "1.2 mi",
        duration: "25 mins",
      },
      DRIVING: {
        distance: "1.2 mi",
        duration: "6 mins",
      },
      TRANSIT: {
        distance: "1.3 mi",
        duration: "21 mins",
      },
    },
  },
  {
    name: "New Docklands Steam Baths",
    category: "Leisure",
    coordinates: {
      lat: 51.5174,
      lng: 0.00545,
    },
    distances: {
      BICYCLING: {
        distance: "1.1 mi",
        duration: "6 mins",
      },
      WALKING: {
        distance: "1.1 mi",
        duration: "24 mins",
      },
      DRIVING: {
        distance: "1.0 mi",
        duration: "4 mins",
      },
      TRANSIT: {
        distance: "1.0 mi",
        duration: "16 mins",
      },
    },
  },
  {
    name: "Memorial Park",
    category: "Nature",
    coordinates: {
      lat: 51.52628,
      lng: 0.01037,
    },
    distances: {
      BICYCLING: {
        distance: "0.9 mi",
        duration: "5 mins",
      },
      WALKING: {
        distance: "0.9 mi",
        duration: "20 mins",
      },
      DRIVING: {
        distance: "0.9 mi",
        duration: "4 mins",
      },
      TRANSIT: {
        distance: "0.9 mi",
        duration: "20 mins",
      },
    },
  },
  {
    name: "Westfield Stratford",
    category: "Retail",
    coordinates: {
      lat: 51.54238,
      lng: -0.00436,
    },
    distances: {
      BICYCLING: {
        distance: "2.8 mi",
        duration: "16 mins",
      },
      WALKING: {
        distance: "2.3 mi",
        duration: "51 mins",
      },
      DRIVING: {
        distance: "3.7 mi",
        duration: "16 mins",
      },
      TRANSIT: {
        distance: "2.3 mi",
        duration: "21 mins",
      },
    },
  },
  {
    name: "Morrisons Canning Town",
    category: "Retail",
    coordinates: {
      lat: 51.51676,
      lng: 0.01466,
    },
    distances: {
      BICYCLING: {
        distance: "1.4 mi",
        duration: "9 mins",
      },
      WALKING: {
        distance: "1.2 mi",
        duration: "27 mins",
      },
      DRIVING: {
        distance: "2.6 mi",
        duration: "9 mins",
      },
      TRANSIT: {
        distance: "1.1 mi",
        duration: "20 mins",
      },
    },
  },
  {
    name: "Hackney Marshes",
    category: "Nature",
    coordinates: {
      lat: 51.55837,
      lng: -0.02911,
    },
    distances: {
      BICYCLING: {
        distance: "4.8 mi",
        duration: "25 mins",
      },
      WALKING: {
        distance: "4.1 mi",
        duration: "1 hour 30 mins",
      },
      DRIVING: {
        distance: "6.1 mi",
        duration: "19 mins",
      },
      TRANSIT: {
        distance: "5.0 mi",
        duration: "54 mins",
      },
    },
  },
  {
    name: "West Ham Park",
    category: "Nature",
    coordinates: {
      lat: 51.54,
      lng: 0.02013,
    },
    distances: {
      BICYCLING: {
        distance: "2.5 mi",
        duration: "15 mins",
      },
      WALKING: {
        distance: "2.2 mi",
        duration: "48 mins",
      },
      DRIVING: {
        distance: "2.6 mi",
        duration: "11 mins",
      },
      TRANSIT: {
        distance: "2.4 mi",
        duration: "34 mins",
      },
    },
  },
  {
    name: "Grange Food and Wine",
    category: "Retail",
    coordinates: {
      lat: 51.52532,
      lng: 0.01241,
    },
    distances: {
      BICYCLING: {
        distance: "1.3 mi",
        duration: "7 mins",
      },
      WALKING: {
        distance: "1.0 mi",
        duration: "21 mins",
      },
      DRIVING: {
        distance: "1.3 mi",
        duration: "6 mins",
      },
      TRANSIT: {
        distance: "1.0 mi",
        duration: "21 mins",
      },
    },
  },
]
