import * as React from "react"
import styled from "styled-components/macro"
import DOMPurify from "dompurify"
import { useCallback } from "react"
import { useMapStore } from "@common/features/GoogleMaps/mapStore"
import SvgIcon from "@common/ui/SvgIcon"
import IconLabel from "@common/ui/IconLabel"
import TextButton from "@common/ui/TextButton"

type TravelMode = {
  mode: string
  iconUrl: string
  label: string
}

const travelModes: TravelMode[] = [
  { mode: "WALKING", iconUrl: "/assets/icons/walking.svg", label: "Walking" },
  { mode: "DRIVING", iconUrl: "/assets/icons/driving.svg", label: "Driving" },
  { mode: "TRANSIT", iconUrl: "/assets/icons/tube.svg", label: "Transit" },
  { mode: "BICYCLING", iconUrl: "/assets/icons/cycling.svg", label: "Cycling" },
]

export interface IDirectionsPanelProps {
  panelRef?: React.RefObject<HTMLDivElement>
  routeSteps: google.maps.DirectionsStep[]
  onClose?: () => void
}

export default function DirectionsPanel(props: IDirectionsPanelProps) {
  const { routeSteps, onClose } = props

  const { setTravelMode, travelMode, activePOI } = useMapStore((state) => ({
    travelMode: state.travelMode,
    setTravelMode: state.setTravelMode,
    activePOI: state.activePOI,
  }))

  const handleRouteCalculation = useCallback(
    (travelMode: string) => {
      setTravelMode(travelMode as any)
    },
    [setTravelMode],
  )
  return (
    <Wrapper>
      <div className="content" style={{ paddingBottom: "2em" }}>
        <div className="travel-modes-container">
          <div className="directions-text">{activePOI?.name}</div>
          <div className="travel-modes">
            {travelModes.map(({ mode, iconUrl, label }) => (
              <div
                key={mode}
                className={`mode ${mode.toLowerCase()}`}
                onClick={() => handleRouteCalculation(mode)}
              >
                <span
                  className={`icon-wrapper ${
                    travelMode === mode ? "active" : ""
                  }`}
                >
                  <SvgIcon
                    url={iconUrl}
                    color={
                      travelMode === mode ? "#fff" : "rgba(18, 18, 18, 0.4)"
                    }
                  />
                </span>
                <span>
                  {mode === "TRANSIT" &&
                  activePOI?.distances?.TRANSIT?.duration ===
                    activePOI?.distances?.WALKING?.duration &&
                  activePOI?.distances?.TRANSIT?.distance ===
                    activePOI?.distances?.WALKING?.distance
                    ? "N/A"
                    : activePOI?.distances?.[mode]?.duration}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="content directions-steps">
        <div className="custom-panel">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
            }}
          >
            <div
              style={{ fontSize: "1.5em", fontWeight: 700, margin: "1em 0" }}
            >
              Directions
            </div>
            {activePOI?.distances && (
              <IconLabel
                icon={`/assets/icons/distance.svg`}
                iconColor="#fff"
                label={activePOI?.distances?.[travelMode]?.distance}
                variant="ghost"
              />
            )}
          </div>

          <ul className="steps">
            {routeSteps.map((step, index) => {
              const sanitizedInstructions = DOMPurify.sanitize(
                step.instructions,
              )
              const distance = step?.distance
              const duration = step?.duration

              return (
                <li key={index}>
                  <div className="rombus"></div>
                  <div
                    className="instructions"
                    dangerouslySetInnerHTML={{ __html: sanitizedInstructions }}
                  ></div>
                  <div className="time">
                    {duration?.text}
                    <span style={{ margin: "1em" }} /> {distance?.text}
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      <TextButton
        style={{ marginTop: "20px" }}
        color="secondary"
        variant="outline"
        onClick={onClose}
      >
        Close
      </TextButton>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 2em 0 2em;
  flex-direction: column;

  .content {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    &.directions-steps {
      flex: 1;
      overflow-y: auto;
      height: 100%;
    }

    .travel-modes-container {
      text-transform: uppercase;

      .directions-text {
        font-size: 1.5em;
        font-weight: bold;
        margin-bottom: 2em;
      }

      .travel-modes {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .mode {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1em;
          cursor: pointer;

          .icon-wrapper {
            height: 4em;
            width: 4em;
            padding: 1em;
            transition: background 0.3s;
            background-color: var(--secondary);
          }

          span {
            font-size: 1em;
          }

          img {
            width: 3em;
            height: 3em;
            padding: 0.8em;
          }
        }
      }

      .distance-icon {
        margin-top: 2em;
      }
    }

    .custom-panel {
      color: #fff;

      .header {
        font-size: 1.3em;
        font-weight: bold;
        padding: 1rem 0;
      }

      .steps {
        position: relative;
        list-style: none;
        display: flex;
        flex-direction: column;
        margin: 0;
        padding-left: 1em;

        .line {
          width: 0px;
          height: 100%;
          position: absolute;
        }
      }

      li {
        position: relative;
        padding: 1em 0em;

        &:last-child {
          border-left: 1.5px dashed transparent;
        }

        .rombus {
          position: absolute;
          width: 0.6rem;
          height: 0.6rem;
          background-color: #fff;
          top: 1.6em;
          transform: rotate(45deg);
          margin-left: -1rem;
        }

        .time {
          opacity: 0.6;
          font-weight: 600;
          font-size: 0.7em;
          position: relative;
          text-transform: uppercase;
          letter-spacing: 0.2em;
          line-height: 2;
          margin-left: 1em;
        }

        .instructions {
          line-height: 1.5;
          font-size: 1.3em;
          margin-left: 0.5em;
        }
      }
    }
  }
`
