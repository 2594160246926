import { create } from "zustand"
import { poisWithDistances } from "./poisWithDistances"

export type POI = {
  iconPath?: any
  category?: string | null
  place: string
  custom?: boolean
  image?: string
  description?: string
  size?: number
  coordinates: { lat: number; lng: number }
}

export type TravelMode = "WALKING" | "DRIVING" | "TRANSIT" | "BICYCLING"
export type TransitMode = "SUBWAY" | "BUS" | "RAIL" | "TRAIN" | "TRAM"

type DistanceInfo = {
  distance: string
  duration: string
}

export type Distances = Record<TravelMode, DistanceInfo>

// I made those optional because I want to set the activePOI by clicking on the map
// and I only want to pass the name of the POI rather than the whole object
export type Place = {
  name: string | undefined
  description?: string | undefined
  category?: string | undefined
  image?: string | undefined
  coordinates?: {
    lat: number
    lng: number
  }
  distances?: Record<TravelMode, DistanceInfo>
}

export interface MapState {
  activePOI: Place | null
  setActivePOI: (activePOI: Place | null) => void
  activeSection: "pois" | "directions"
  setActiveSection: (activeSection: "pois" | "directions") => void
  currentCategory: string
  setCurrentCategory: (category: string | undefined) => void
  travelMode: TravelMode
  setTravelMode: (travelMode: TravelMode) => void
  transitMode: TransitMode
  setTransitMode: (transitMode: TransitMode) => void
  routeSteps: google.maps.DirectionsStep[]
  setRouteSteps: (routeSteps: google.maps.DirectionsStep[]) => void
  mapInstance: google.maps.Map | null
  setMapinstance: (map: google.maps.Map | null) => void
  placesState: Place[]
}

export const useMapStore = create<MapState>()((set) => ({
  activePOI: null,
  setActivePOI: (activePOI: Place | null) => {
    set({ activePOI })
  },
  currentCategory: "all",
  setCurrentCategory: (currentCategory: string | undefined) => {
    set({ currentCategory })
  },
  travelMode: "WALKING",
  setTravelMode: (travelMode: TravelMode) => {
    set({ travelMode })
  },
  transitMode: "SUBWAY",
  setTransitMode: (transitMode: TransitMode) => {
    set({ transitMode })
  },
  routeSteps: [],
  setRouteSteps: (routeSteps: google.maps.DirectionsStep[]) => {
    set({ routeSteps })
  },
  activeSection: "pois",
  setActiveSection: (activeSection: "pois" | "directions") => {
    set({ activeSection })
  },
  mapInstance: null,
  setMapinstance: (map) => {
    set({ mapInstance: map })
  },
  placesState: poisWithDistances,
}))
