
  export const initialProjectState = {
  "brandingData": {
    "relationTo": "branding",
    "name": "Branding - West Ham",
    "colours": {
      "primary": "#25303b",
      "secondary": "#ff7b4f",
      "accent": "#ebb3f7"
    },
    "fonts": {
      "primaryFont": {
        "id": "6712447baa5c84cdc27dac2a",
        "filename": "westHamPrimary.ttf",
        "mimeType": "font/ttf",
        "filesize": 176352,
        "createdAt": "2024-10-18T11:20:27.754Z",
        "updatedAt": "2024-10-18T11:20:27.817Z",
        "publicURL": "/payload/projectFontMedia/westHamPrimary.ttf",
        "thumbnailURL": null,
        "url": "https://luna.vmistudio.com/api/projectFontMedia/file/westHamPrimary.ttf"
      },
      "secondaryFont": {
        "id": "67124481aa5c84cdc27dac4b",
        "filename": "westHamSecondary.otf",
        "mimeType": "font/otf",
        "filesize": 111256,
        "createdAt": "2024-10-18T11:20:33.990Z",
        "updatedAt": "2024-10-18T11:20:34.096Z",
        "publicURL": "/payload/projectFontMedia/westHamSecondary.otf",
        "thumbnailURL": null,
        "url": "https://luna.vmistudio.com/api/projectFontMedia/file/westHamSecondary.otf"
      }
    },
    "logos": {
      "lightColourLogo": {
        "id": "671244c6aa5c84cdc27dac92",
        "filename": "westHamLight.png",
        "mimeType": "image/png",
        "filesize": 18396,
        "width": 694,
        "height": 152,
        "focalX": 50,
        "focalY": 50,
        "createdAt": "2024-10-18T11:21:42.675Z",
        "updatedAt": "2024-10-18T11:21:42.736Z",
        "publicURL": "/payload/projectLogoMedia/westHamLight.png",
        "thumbnailURL": null,
        "url": "https://luna.vmistudio.com/api/projectLogoMedia/file/westHamLight.png"
      },
      "darkColourLogo": {
        "id": "671244cfaa5c84cdc27dacb4",
        "filename": "westHamDark.png",
        "mimeType": "image/png",
        "filesize": 16316,
        "width": 880,
        "height": 192,
        "focalX": 50,
        "focalY": 50,
        "createdAt": "2024-10-18T11:21:51.602Z",
        "updatedAt": "2024-10-18T11:21:51.660Z",
        "publicURL": "/payload/projectLogoMedia/westHamDark.png",
        "thumbnailURL": null,
        "url": "https://luna.vmistudio.com/api/projectLogoMedia/file/westHamDark.png"
      }
    },
    "bgImage": "6712448eaa5c84cdc27dac71"
  },
  "navigationData": {
    "relationTo": "navigation",
    "name": "Navigation - West Ham",
    "menuItems": [
      {
        "include": "true",
        "navLabel": "Destination",
        "pageType": "orbits",
        "id": "67122594858aa60026fdd838"
      },
      {
        "include": "true",
        "navLabel": "Transport & Lifestyle",
        "pageType": "map",
        "id": "67122594858aa60026fdd833"
      },
      {
        "include": "true",
        "navLabel": "Apartment Finder",
        "pageType": "residences",
        "id": "67122594858aa60026fdd832"
      },
      {
        "include": "true",
        "navLabel": "Gallery",
        "pageType": "gallery",
        "id": "67122594858aa60026fdd834"
      },
      {
        "include": "true",
        "navLabel": "Favourites",
        "pageType": "favourites",
        "id": "67122594858aa60026fdd835"
      },
      {
        "include": "false",
        "navLabel": "Explorer",
        "pageType": "explorer",
        "id": "67122594858aa60026fdd836"
      },
      {
        "include": "false",
        "navLabel": "Development",
        "pageType": "development",
        "id": "67122594858aa60026fdd837"
      },
      {
        "include": "true",
        "navLabel": "Shared Ownership",
        "pageType": "sharedOwnership",
        "id": "67122594858aa60026fdd839"
      },
      {
        "include": "true",
        "navLabel": "Peabody & Partners",
        "pageType": "about",
        "id": "67122594858aa60026fdd83a"
      },
      {
        "include": "false",
        "navLabel": "Info1 Page",
        "pageType": "info1",
        "id": "67122594858aa60026fdd83b"
      }
    ],
    "layout": "fullscreen",
    "logo": "lightColourLogo",
    "logoWidth": 12.25,
    "fontColour": "white",
    "bgColor": "primary",
    "burgerBackgroundColour": "primary",
    "burgerBorderColour": "accent",
    "burgerWidth": 3.5,
    "shapeColour": "secondary"
  },
  "explorerData": null,
  "sharedOwnershipData": {
    "relationTo": "sharedOwnership",
    "name": "Shared Ownership - West Ham",
    "feature": "sharedOwnership"
  },
  "aboutData": {
    "relationTo": "about",
    "name": "About - West Ham",
    "sections": [
      {
        "title": "Peabody",
        "description": [
          {
            "title": "Building History",
            "desc": "Formed over 160 years ago by the American financier and\nphilanthropist George Peabody, Peabody is one of the\nUK’s oldest and largest housing associations responsible\nfor more than 107,000 homes and around 220,000\nresidents across London and the Home Counties.",
            "id": "6712474f7330ed002d7eda72"
          },
          {
            "title": "Social Impact",
            "desc": "Our social purpose is why we exist. We re-invest the\nsurplus made from the sale of our homes to provide\nmore homes and services to those who need it most. We\ndeliver services that support customers and the wider\ncommunities that we work in, and invest in local projects\nthrough our Community Foundation.",
            "id": "671247557330ed002d7eda73"
          },
          {
            "title": "",
            "desc": "",
            "img": {
              "id": "67124b2629ce53533d1243c4",
              "filename": "about1.jpg",
              "mimeType": "image/jpeg",
              "filesize": 363141,
              "width": 938,
              "height": 520,
              "focalX": 50,
              "focalY": 50,
              "createdAt": "2024-10-18T11:48:54.250Z",
              "updatedAt": "2024-10-18T11:48:54.308Z",
              "publicURL": "/payload/galleryMedia/about1.jpg",
              "thumbnailURL": null,
              "url": "https://luna.vmistudio.com/api/galleryMedia/file/about1.jpg"
            },
            "id": "67124b3e52326f002648a78c"
          },
          {
            "title": "",
            "desc": " ",
            "img": {
              "id": "67124ca529ce53533d12480d",
              "filename": "about2.jpg",
              "mimeType": "image/jpeg",
              "filesize": 612857,
              "width": 1174,
              "height": 862,
              "focalX": 50,
              "focalY": 50,
              "createdAt": "2024-10-18T11:55:17.916Z",
              "updatedAt": "2024-10-18T11:55:17.971Z",
              "publicURL": "/payload/galleryMedia/about2.jpg",
              "thumbnailURL": null,
              "url": "https://luna.vmistudio.com/api/galleryMedia/file/about2.jpg"
            },
            "id": "67124c9e52326f002648a794"
          },
          {
            "title": "Customer Focus",
            "desc": "Whether it’s your first or forever home, we know that\nbuying a new place can be a demanding time.\nOur sales team is committed to guiding you through the\nprocess and helping you every step of the way. Rated\n“Excellent” on Trustpilot, we’re proud to offer you a\nservice that makes a difference.",
            "id": "67124b5e52326f002648a78f"
          },
          {
            "title": "Quality  and Design",
            "desc": "Peabody’s reputation is one of the most respected in\nthe housing industry renowned for quality, multi-award-\nwinning and innovative design. We work with industry\nexperts and continually monitor quality throughout the\nbuilding process to reduce our impact on climate change,\nlessen our carbon footprint and create properties people\nare proud to call home.",
            "id": "67124b6a52326f002648a790"
          },
          {
            "title": "Sustainability",
            "desc": "Our Greener Homes Programme is our commitment to the\nenvironment and will aim to minimise our impact on the planet\nand support thriving neighbourhoods.",
            "id": "67124b7252326f002648a791"
          },
          {
            "title": "More than a place to live",
            "desc": "We at Peabody have a proud legacy of helping\ngenerations of homebuyers and residents find their place\nin thriving neighbourhoods, communities and homes for\nover 160 years.",
            "id": "67124b7852326f002648a792"
          }
        ],
        "stats": [],
        "id": "671247447330ed002d7eda71"
      },
      {
        "title": "The Architects",
        "description": [
          {
            "title": "Customer focus",
            "desc": "Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam sem fringilla ut morbi tincidunt. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam se.",
            "id": "67124dae52326f002648a798"
          },
          {
            "title": "Customer focus",
            "desc": "Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam sem fringilla ut morbi tincidunt. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam se.",
            "id": "67124db452326f002648a799"
          }
        ],
        "main": "Architects",
        "mainName": "Peter Taylor",
        "quote": "The vision was to create a well-connected city quarter with nature at its core parkland living, with London at your doorstep.",
        "by": "Andrew Taylor & Pankaj Patel",
        "by2": "FOUNDING DIRECTORS, PATEL TAYLOR",
        "image": {
          "id": "67124d2f29ce53533d1248e2",
          "filename": "about3.jpg",
          "mimeType": "image/jpeg",
          "filesize": 4017229,
          "width": 2748,
          "height": 3052,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-18T11:57:35.328Z",
          "updatedAt": "2024-10-18T11:57:35.384Z",
          "publicURL": "/payload/galleryMedia/about3.jpg",
          "thumbnailURL": null,
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/about3.jpg"
        },
        "stats": [
          {
            "text": "70%",
            "type": "Statistic Info",
            "id": "67124d3552326f002648a795"
          },
          {
            "text": "5k",
            "type": "Statistic Info",
            "id": "67124d8152326f002648a796"
          },
          {
            "text": "100+",
            "type": "Statistic Info",
            "id": "67124d8652326f002648a797"
          }
        ],
        "id": "67124b4852326f002648a78d"
      },
      {
        "title": "Community",
        "description": [
          {
            "title": "Michael at Star Lane Pizza",
            "desc": "We’re all about good eats.",
            "img": {
              "id": "67582b553ac89aec3d0b9e83",
              "publicURL": "/payload/galleryMedia/west-ham-community.mp4",
              "filename": "west-ham-community.mp4",
              "mimeType": "video/mp4",
              "filesize": 32459775,
              "createdAt": "2024-12-10T11:51:49.816Z",
              "updatedAt": "2024-12-10T11:51:49.816Z",
              "url": "https://luna.vmistudio.com/api/galleryMedia/file/west-ham-community.mp4",
              "thumbnailURL": null
            },
            "id": "67124f4f52326f002648a79d"
          },
          {
            "title": "Matt at Cody Dock",
            "desc": "Where nature and community bloom",
            "img": null,
            "id": "67124f6852326f002648a79e"
          },
          {
            "title": "Amy at Rosetta Arts",
            "desc": "Art is part of our identity",
            "id": "67582ba1394360bd2af26a29"
          }
        ],
        "stats": [],
        "id": "67124f4352326f002648a79c"
      }
    ],
    "feature": "about"
  },
  "info1Data": {
    "relationTo": "info1",
    "name": "Info1 - West Ham",
    "feature": "info1",
    "style": "full",
    "sections": []
  },
  "developmentData": {
    "relationTo": "development",
    "name": "Development - West Ham",
    "feature": "development"
  },
  "favouritesData": {
    "relationTo": "favourites",
    "name": "Favourites - West Ham",
    "feature": "favourites",
    "shareType": {
      "shareAppURL": "https://luna-pwa.vercel.app",
      "hiddenProjectId": {
        "id": "67122594092d9036925b38c1",
        "name": "West Ham",
        "organisation": "670e76cd7ae1d67934334ad5",
        "published": true,
        "createdAt": "2024-10-18T09:08:36.526Z",
        "updatedAt": "2024-10-24T15:11:10.543Z",
        "pages": [
          {
            "relationTo": "branding",
            "value": "67122594092d9036925b38d1"
          },
          {
            "relationTo": "navigation",
            "value": "67122594092d9036925b38d2"
          },
          {
            "relationTo": "splash",
            "value": "67122594092d9036925b38cd"
          },
          {
            "relationTo": "residences",
            "value": "67122594092d9036925b38ce"
          },
          {
            "relationTo": "map",
            "value": "67122594092d9036925b38d3"
          },
          {
            "relationTo": "gallery",
            "value": "67122594092d9036925b38cf"
          },
          {
            "relationTo": "favourites",
            "value": "67122594092d9036925b38d0"
          },
          {
            "relationTo": "orbits",
            "value": "67122594092d9036925b38c3"
          },
          {
            "relationTo": "explorer",
            "value": "67122594092d9036925b38c4"
          },
          {
            "relationTo": "development",
            "value": "67122594092d9036925b38c5"
          },
          {
            "relationTo": "sharedOwnership",
            "value": "67122594092d9036925b38c6"
          },
          {
            "relationTo": "about",
            "value": "67122594092d9036925b38c7"
          },
          {
            "relationTo": "info1",
            "value": "67122594092d9036925b38c8"
          },
          {
            "relationTo": "unitTypes",
            "value": "67122594092d9036925b38ca"
          },
          {
            "relationTo": "unitViews",
            "value": "67122594092d9036925b38cb"
          },
          {
            "relationTo": "unitDetails",
            "value": "67122594092d9036925b38cc"
          },
          {
            "relationTo": "unitAvailability",
            "value": "671a63809d9dc294c18f2e40"
          }
        ],
        "deployHook": "https://api.vercel.com/v1/integrations/deploy/prj_ZuIge9774D8V7BxhumXfy6Q6qJMB/8frCDOBIVR"
      },
      "options": "qrCodeOnly"
    },
    "emailSettings": {
      "ccAgentList": []
    }
  },
  "galleryData": {
    "relationTo": "gallery",
    "name": "Gallery - West Ham",
    "feature": "masonrygallery",
    "scroll": "horizontal",
    "enableCategories": true,
    "enableRounded": false,
    "style": "window",
    "spacing": "large",
    "categoryStyle": "type1",
    "categoryColor": "black",
    "images": [
      {
        "content": {
          "id": "671f697cee5ee5f3d8e8a760",
          "publicURL": "/payload/galleryMedia/c6_aerial2_02.webp",
          "filename": "c6_aerial2_02.webp",
          "mimeType": "image/webp",
          "filesize": 286680,
          "width": 2109,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:37:48.645Z",
          "updatedAt": "2024-10-28T10:37:48.645Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/c6_aerial2_02.webp",
          "thumbnailURL": null
        },
        "category": "Exteriors",
        "id": "671f6967042af00026886d30"
      },
      {
        "content": {
          "id": "671f68baee5ee5f3d8e8a5a5",
          "publicURL": "/payload/galleryMedia/c1_CO1_amenity_space_opt3_fin.webp",
          "filename": "c1_CO1_amenity_space_opt3_fin.webp",
          "mimeType": "image/webp",
          "filesize": 528266,
          "width": 1622,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:34:34.322Z",
          "updatedAt": "2024-10-28T10:34:34.322Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/c1_CO1_amenity_space_opt3_fin.webp",
          "thumbnailURL": null
        },
        "category": "Exteriors",
        "id": "671f68a5042af00026886c4c"
      },
      {
        "content": {
          "id": "671f6931ee5ee5f3d8e8a6c9",
          "publicURL": "/payload/galleryMedia/c3_NO1B_streetscape_NEW_fin.webp",
          "filename": "c3_NO1B_streetscape_NEW_fin.webp",
          "mimeType": "image/webp",
          "filesize": 372534,
          "width": 1940,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:36:33.926Z",
          "updatedAt": "2024-10-28T10:36:33.926Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/c3_NO1B_streetscape_NEW_fin.webp",
          "thumbnailURL": null
        },
        "category": "Exteriors",
        "id": "671f6922042af00026886d2c"
      },
      {
        "content": {
          "id": "671f6946ee5ee5f3d8e8a6f4",
          "publicURL": "/payload/galleryMedia/c4_Park_opt4.webp",
          "filename": "c4_Park_opt4.webp",
          "mimeType": "image/webp",
          "filesize": 286808,
          "width": 1125,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:36:54.290Z",
          "updatedAt": "2024-10-28T10:36:54.290Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/c4_Park_opt4.webp",
          "thumbnailURL": null
        },
        "category": "Exteriors",
        "id": "671f6937042af00026886d2d"
      },
      {
        "content": {
          "id": "671f691bee5ee5f3d8e8a69e",
          "publicURL": "/payload/galleryMedia/c2_NO1B_HERO_opt6.webp",
          "filename": "c2_NO1B_HERO_opt6.webp",
          "mimeType": "image/webp",
          "filesize": 492666,
          "width": 1782,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:36:11.683Z",
          "updatedAt": "2024-10-28T10:36:11.683Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/c2_NO1B_HERO_opt6.webp",
          "thumbnailURL": null
        },
        "category": "Exteriors",
        "id": "671f6905042af00026886d2b"
      },
      {
        "content": {
          "id": "671f6956ee5ee5f3d8e8a718",
          "publicURL": "/payload/galleryMedia/c5_SO1C_HERO_opt7.webp",
          "filename": "c5_SO1C_HERO_opt7.webp",
          "mimeType": "image/webp",
          "filesize": 218888,
          "width": 1195,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:37:10.505Z",
          "updatedAt": "2024-10-28T10:37:10.505Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/c5_SO1C_HERO_opt7.webp",
          "thumbnailURL": null
        },
        "category": "Exteriors",
        "id": "671f6948042af00026886d2e"
      },
      {
        "content": {
          "id": "671f6965ee5ee5f3d8e8a73c",
          "publicURL": "/payload/galleryMedia/c6_aerial1.webp",
          "filename": "c6_aerial1.webp",
          "mimeType": "image/webp",
          "filesize": 361638,
          "width": 2438,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:37:25.039Z",
          "updatedAt": "2024-10-28T10:37:25.039Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/c6_aerial1.webp",
          "thumbnailURL": null
        },
        "category": "Exteriors",
        "id": "671f6958042af00026886d2f"
      },
      {
        "content": {
          "id": "671f6ad7ee5ee5f3d8e8a8e8",
          "publicURL": "/payload/galleryMedia/West Ham-5.webp",
          "filename": "West Ham-5.webp",
          "mimeType": "image/webp",
          "filesize": 408302,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:43:35.114Z",
          "updatedAt": "2024-10-28T10:43:35.114Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-5.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6abc042af00026886e0f"
      },
      {
        "content": {
          "id": "671f6ae6ee5ee5f3d8e8a90f",
          "publicURL": "/payload/galleryMedia/West Ham-41.webp",
          "filename": "West Ham-41.webp",
          "mimeType": "image/webp",
          "filesize": 172620,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:43:50.598Z",
          "updatedAt": "2024-10-28T10:43:50.598Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-41.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6ad9042af00026886e10"
      },
      {
        "content": {
          "id": "671f6af6ee5ee5f3d8e8a936",
          "publicURL": "/payload/galleryMedia/West Ham-53.webp",
          "filename": "West Ham-53.webp",
          "mimeType": "image/webp",
          "filesize": 158612,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:44:06.830Z",
          "updatedAt": "2024-10-28T10:44:06.830Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-53.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6ae9042af00026886e11"
      },
      {
        "content": {
          "id": "671f6b06ee5ee5f3d8e8a960",
          "publicURL": "/payload/galleryMedia/West Ham-71.webp",
          "filename": "West Ham-71.webp",
          "mimeType": "image/webp",
          "filesize": 245258,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:44:22.067Z",
          "updatedAt": "2024-10-28T10:44:22.067Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-71.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6afa042af00026886e12"
      },
      {
        "content": {
          "id": "671f6b16ee5ee5f3d8e8a987",
          "publicURL": "/payload/galleryMedia/West Ham-102.webp",
          "filename": "West Ham-102.webp",
          "mimeType": "image/webp",
          "filesize": 170134,
          "width": 937,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:44:38.119Z",
          "updatedAt": "2024-10-28T10:44:38.119Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-102.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6b0a042af00026886e13"
      },
      {
        "content": {
          "id": "671f6b27ee5ee5f3d8e8a9b1",
          "publicURL": "/payload/galleryMedia/West Ham-103.webp",
          "filename": "West Ham-103.webp",
          "mimeType": "image/webp",
          "filesize": 143198,
          "width": 2109,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:44:55.219Z",
          "updatedAt": "2024-10-28T10:44:55.219Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-103.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6b18042af00026886e14"
      },
      {
        "content": {
          "id": "671f6b36ee5ee5f3d8e8a9d8",
          "publicURL": "/payload/galleryMedia/West Ham-108.webp",
          "filename": "West Ham-108.webp",
          "mimeType": "image/webp",
          "filesize": 87440,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:45:10.049Z",
          "updatedAt": "2024-10-28T10:45:10.049Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-108.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6b2a042af00026886e15"
      },
      {
        "content": {
          "id": "671f6b6fee5ee5f3d8e8aa30",
          "publicURL": "/payload/galleryMedia/West Ham-137.webp",
          "filename": "West Ham-137.webp",
          "mimeType": "image/webp",
          "filesize": 251446,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:46:07.726Z",
          "updatedAt": "2024-10-28T10:46:07.726Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-137.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6b60042af00026886e17"
      },
      {
        "content": {
          "id": "671f6b7fee5ee5f3d8e8aa5a",
          "publicURL": "/payload/galleryMedia/West Ham-139.webp",
          "filename": "West Ham-139.webp",
          "mimeType": "image/webp",
          "filesize": 437342,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:46:23.530Z",
          "updatedAt": "2024-10-28T10:46:23.530Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-139.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6b71042af00026886e18"
      },
      {
        "content": {
          "id": "671f6b8dee5ee5f3d8e8aa84",
          "publicURL": "/payload/galleryMedia/West Ham-146.webp",
          "filename": "West Ham-146.webp",
          "mimeType": "image/webp",
          "filesize": 76264,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:46:37.788Z",
          "updatedAt": "2024-10-28T10:46:37.788Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-146.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6b81042af00026886e19"
      },
      {
        "content": {
          "id": "671f6b9dee5ee5f3d8e8aaae",
          "publicURL": "/payload/galleryMedia/West Ham-154.webp",
          "filename": "West Ham-154.webp",
          "mimeType": "image/webp",
          "filesize": 601190,
          "width": 2109,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:46:53.147Z",
          "updatedAt": "2024-10-28T10:46:53.147Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-154.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6b8f042af00026886e1a"
      },
      {
        "content": {
          "id": "671f6babee5ee5f3d8e8aad8",
          "publicURL": "/payload/galleryMedia/West Ham-156.webp",
          "filename": "West Ham-156.webp",
          "mimeType": "image/webp",
          "filesize": 280152,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:47:07.605Z",
          "updatedAt": "2024-10-28T10:47:07.605Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-156.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6b9f042af00026886e1b"
      },
      {
        "content": {
          "id": "671f6bc2ee5ee5f3d8e8ab02",
          "publicURL": "/payload/galleryMedia/West Ham-166.webp",
          "filename": "West Ham-166.webp",
          "mimeType": "image/webp",
          "filesize": 449578,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:47:30.189Z",
          "updatedAt": "2024-10-28T10:47:30.189Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-166.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6bad042af00026886e1c"
      },
      {
        "content": {
          "id": "671f6bcfee5ee5f3d8e8ab2c",
          "publicURL": "/payload/galleryMedia/West Ham-169.webp",
          "filename": "West Ham-169.webp",
          "mimeType": "image/webp",
          "filesize": 120850,
          "width": 938,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:47:43.700Z",
          "updatedAt": "2024-10-28T10:47:43.700Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-169.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6bc3042af00026886e1d"
      },
      {
        "content": {
          "id": "671f6be9ee5ee5f3d8e8ab5a",
          "publicURL": "/payload/galleryMedia/West Ham-175.webp",
          "filename": "West Ham-175.webp",
          "mimeType": "image/webp",
          "filesize": 246432,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:48:09.641Z",
          "updatedAt": "2024-10-28T10:48:09.641Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-175.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6bd3042af00026886e1e"
      },
      {
        "content": {
          "id": "671f6bf8ee5ee5f3d8e8ab84",
          "publicURL": "/payload/galleryMedia/West Ham-176.webp",
          "filename": "West Ham-176.webp",
          "mimeType": "image/webp",
          "filesize": 324582,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:48:24.324Z",
          "updatedAt": "2024-10-28T10:48:24.324Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-176.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6beb042af00026886e1f"
      },
      {
        "content": {
          "id": "671f6c08ee5ee5f3d8e8abab",
          "publicURL": "/payload/galleryMedia/West Ham-181.webp",
          "filename": "West Ham-181.webp",
          "mimeType": "image/webp",
          "filesize": 178388,
          "width": 2109,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:48:40.515Z",
          "updatedAt": "2024-10-28T10:48:40.515Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-181.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6bfa042af00026886e20"
      },
      {
        "content": {
          "id": "671f6c19ee5ee5f3d8e8abd5",
          "publicURL": "/payload/galleryMedia/West Ham-216.webp",
          "filename": "West Ham-216.webp",
          "mimeType": "image/webp",
          "filesize": 172206,
          "width": 938,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:48:57.993Z",
          "updatedAt": "2024-10-28T10:48:57.993Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-216.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6c0a042af00026886e21"
      },
      {
        "content": {
          "id": "671f6c29ee5ee5f3d8e8abfc",
          "publicURL": "/payload/galleryMedia/West Ham-219.webp",
          "filename": "West Ham-219.webp",
          "mimeType": "image/webp",
          "filesize": 306294,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:49:13.190Z",
          "updatedAt": "2024-10-28T10:49:13.190Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-219.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6c1c042af00026886e22"
      },
      {
        "content": {
          "id": "671f6c4dee5ee5f3d8e8ac2e",
          "publicURL": "/payload/galleryMedia/West Ham-234.webp",
          "filename": "West Ham-234.webp",
          "mimeType": "image/webp",
          "filesize": 431338,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:49:49.329Z",
          "updatedAt": "2024-10-28T10:49:49.329Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-234.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6c2d042af00026886e23"
      },
      {
        "content": {
          "id": "671f6c61ee5ee5f3d8e8ac5c",
          "publicURL": "/payload/galleryMedia/West Ham-270.webp",
          "filename": "West Ham-270.webp",
          "mimeType": "image/webp",
          "filesize": 296956,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:50:09.219Z",
          "updatedAt": "2024-10-28T10:50:09.219Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-270.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6c50042af00026886e24"
      },
      {
        "content": {
          "id": "671f6c70ee5ee5f3d8e8ac82",
          "publicURL": "/payload/galleryMedia/West Ham-273.webp",
          "filename": "West Ham-273.webp",
          "mimeType": "image/webp",
          "filesize": 361786,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:50:24.327Z",
          "updatedAt": "2024-10-28T10:50:24.327Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-273.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6c53042af00026886e25"
      },
      {
        "content": {
          "id": "671f6c7fee5ee5f3d8e8acac",
          "publicURL": "/payload/galleryMedia/West Ham-278.webp",
          "filename": "West Ham-278.webp",
          "mimeType": "image/webp",
          "filesize": 314746,
          "width": 2109,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:50:39.325Z",
          "updatedAt": "2024-10-28T10:50:39.325Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-278.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6c72042af00026886e26"
      },
      {
        "content": {
          "id": "671f6c8fee5ee5f3d8e8acd6",
          "publicURL": "/payload/galleryMedia/West Ham-325.webp",
          "filename": "West Ham-325.webp",
          "mimeType": "image/webp",
          "filesize": 234228,
          "width": 2109,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:50:55.142Z",
          "updatedAt": "2024-10-28T10:50:55.142Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-325.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6c81042af00026886e27"
      },
      {
        "content": {
          "id": "673f1a36a0480a03f68bfbcd",
          "publicURL": "/payload/galleryMedia/studio.webp",
          "filename": "studio.webp",
          "mimeType": "image/webp",
          "filesize": 274476,
          "width": 2529,
          "height": 1688,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-21T11:32:06.701Z",
          "updatedAt": "2024-11-21T11:32:06.701Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/studio.webp",
          "thumbnailURL": null
        },
        "category": "Interiors",
        "id": "673f19281128c70026d045b6"
      },
      {
        "content": {
          "id": "673f1a45a0480a03f68bfc14",
          "publicURL": "/payload/galleryMedia/living.webp",
          "filename": "living.webp",
          "mimeType": "image/webp",
          "filesize": 620186,
          "width": 2529,
          "height": 1688,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-21T11:32:21.371Z",
          "updatedAt": "2024-11-21T11:32:21.371Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/living.webp",
          "thumbnailURL": null
        },
        "category": "Interiors",
        "id": "673f1a391128c70026d045c1"
      },
      {
        "content": {
          "id": "673f1a5aa0480a03f68bfc3b",
          "publicURL": "/payload/galleryMedia/living-3.webp",
          "filename": "living-3.webp",
          "mimeType": "image/webp",
          "filesize": 337234,
          "width": 2528,
          "height": 1688,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-21T11:32:42.676Z",
          "updatedAt": "2024-11-21T11:32:42.676Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/living-3.webp",
          "thumbnailURL": null
        },
        "category": "Interiors",
        "id": "673f1a4a1128c70026d045c2"
      },
      {
        "content": {
          "id": "673f1a66a0480a03f68bfc62",
          "publicURL": "/payload/galleryMedia/living-2.webp",
          "filename": "living-2.webp",
          "mimeType": "image/webp",
          "filesize": 417684,
          "width": 2529,
          "height": 1688,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-21T11:32:54.841Z",
          "updatedAt": "2024-11-21T11:32:54.841Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/living-2.webp",
          "thumbnailURL": null
        },
        "category": "Interiors",
        "id": "673f1a5d1128c70026d045c3"
      },
      {
        "content": {
          "id": "673f1a72a0480a03f68bfc89",
          "publicURL": "/payload/galleryMedia/living-1.webp",
          "filename": "living-1.webp",
          "mimeType": "image/webp",
          "filesize": 445234,
          "width": 2529,
          "height": 1688,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-21T11:33:06.611Z",
          "updatedAt": "2024-11-21T11:33:06.611Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/living-1.webp",
          "thumbnailURL": null
        },
        "category": "Interiors",
        "id": "673f1a691128c70026d045c4"
      },
      {
        "content": {
          "id": "673f1a82a0480a03f68bfcb0",
          "publicURL": "/payload/galleryMedia/kitchen.webp",
          "filename": "kitchen.webp",
          "mimeType": "image/webp",
          "filesize": 514562,
          "width": 2529,
          "height": 1688,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-21T11:33:22.686Z",
          "updatedAt": "2024-11-21T11:33:22.686Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/kitchen.webp",
          "thumbnailURL": null
        },
        "category": "Interiors",
        "id": "673f1a771128c70026d045c5"
      },
      {
        "content": {
          "id": "673f1aa3a0480a03f68bfce3",
          "publicURL": "/payload/galleryMedia/kitchen-2.webp",
          "filename": "kitchen-2.webp",
          "mimeType": "image/webp",
          "filesize": 445280,
          "width": 2529,
          "height": 1688,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-21T11:33:55.846Z",
          "updatedAt": "2024-11-21T11:33:55.846Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/kitchen-2.webp",
          "thumbnailURL": null
        },
        "category": "Interiors",
        "id": "673f1a881128c70026d045c6"
      },
      {
        "content": {
          "id": "673f1abda0480a03f68bfd0e",
          "publicURL": "/payload/galleryMedia/kitchen-1.webp",
          "filename": "kitchen-1.webp",
          "mimeType": "image/webp",
          "filesize": 280018,
          "width": 2529,
          "height": 1688,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-21T11:34:21.168Z",
          "updatedAt": "2024-11-21T11:34:21.168Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/kitchen-1.webp",
          "thumbnailURL": null
        },
        "category": "Interiors",
        "id": "673f1aa61128c70026d045c7"
      },
      {
        "content": {
          "id": "673f1acaa0480a03f68bfd35",
          "publicURL": "/payload/galleryMedia/bedroom.webp",
          "filename": "bedroom.webp",
          "mimeType": "image/webp",
          "filesize": 410590,
          "width": 2532,
          "height": 1688,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-21T11:34:34.446Z",
          "updatedAt": "2024-11-21T11:34:34.446Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/bedroom.webp",
          "thumbnailURL": null
        },
        "category": "Interiors",
        "id": "673f1abf1128c70026d045c8"
      },
      {
        "content": {
          "id": "673f1ad6a0480a03f68bfd5c",
          "publicURL": "/payload/galleryMedia/bedroom-2.webp",
          "filename": "bedroom-2.webp",
          "mimeType": "image/webp",
          "filesize": 522518,
          "width": 2529,
          "height": 1688,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-21T11:34:46.984Z",
          "updatedAt": "2024-11-21T11:34:46.984Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/bedroom-2.webp",
          "thumbnailURL": null
        },
        "category": "Interiors",
        "id": "673f1acd1128c70026d045c9"
      },
      {
        "content": {
          "id": "673f1ae4a0480a03f68bfd83",
          "publicURL": "/payload/galleryMedia/bedroom-1.webp",
          "filename": "bedroom-1.webp",
          "mimeType": "image/webp",
          "filesize": 668738,
          "width": 2529,
          "height": 1688,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-21T11:35:00.422Z",
          "updatedAt": "2024-11-21T11:35:00.422Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/bedroom-1.webp",
          "thumbnailURL": null
        },
        "category": "Interiors",
        "id": "673f1ad91128c70026d045ca"
      },
      {
        "content": {
          "id": "673f1af1a0480a03f68bfdaa",
          "publicURL": "/payload/galleryMedia/bath.webp",
          "filename": "bath.webp",
          "mimeType": "image/webp",
          "filesize": 482292,
          "width": 2529,
          "height": 1688,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-21T11:35:13.100Z",
          "updatedAt": "2024-11-21T11:35:13.100Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/bath.webp",
          "thumbnailURL": null
        },
        "category": "Interiors",
        "id": "673f1ae71128c70026d045cb"
      }
    ]
  },
  "mapData": {
    "relationTo": "map",
    "name": "Map - West Ham",
    "feature": "googleMaps",
    "mapSettings": {
      "zoom": 16,
      "unitSystem": "IMPERIAL",
      "withDirections": true
    },
    "mapPOIS": {
      "poiSettings": {
        "poiVisibility": "scaleActive",
        "poiIcon": {
          "id": "6712503129ce53533d124f74",
          "filename": "poi.png",
          "mimeType": "image/png",
          "filesize": 4083,
          "width": 60,
          "height": 60,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-18T12:10:25.577Z",
          "updatedAt": "2024-10-18T12:10:25.577Z",
          "url": "https://luna.vmistudio.com/api/organisationMedia/file/poi.png",
          "thumbnailURL": null
        }
      },
      "mainMarker": {
        "place": "West Ham Village",
        "description": "West Ham Village Development Location",
        "iconPath": {
          "id": "671a0b398168a18eb3451545",
          "publicURL": "/payload/organisationMedia/logo-2.png",
          "filename": "logo-2.png",
          "mimeType": "image/png",
          "filesize": 45795,
          "width": 2638,
          "height": 554,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-24T08:54:17.837Z",
          "updatedAt": "2024-10-24T08:54:17.837Z",
          "url": "https://luna.vmistudio.com/api/organisationMedia/file/logo-2.png",
          "thumbnailURL": null
        },
        "coordinates": {
          "lat": 51.52296682095639,
          "lng": 0.004356971225846532
        }
      },
      "pois": [
        {
          "place": "Star Lane Pizza Bar",
          "description": " ",
          "category": "Leisure",
          "coordinates": {
            "lat": 51.5213023534721,
            "lng": 0.0025663613635026825
          },
          "id": "6720a22d042af00026887411"
        },
        {
          "place": "Studio Baristas",
          "description": "",
          "category": "Leisure",
          "coordinates": {
            "lat": 51.521520037523786,
            "lng": 0.001910373003560795
          },
          "id": "6720a2cc042af00026887481"
        },
        {
          "place": "Yapix",
          "category": "Leisure",
          "coordinates": {
            "lat": 51.51489,
            "lng": 0.01058
          },
          "id": "6720a3fa042af00026887560"
        },
        {
          "place": "Rosetta Arts",
          "category": "Leisure",
          "coordinates": {
            "lat": 51.52513,
            "lng": 0.00756
          },
          "id": "6720a43c042af00026887561"
        },
        {
          "place": "Cody Dock",
          "category": "Leisure",
          "coordinates": {
            "lat": 51.51914,
            "lng": -0.00169
          },
          "id": "6720a45a042af00026887562"
        },
        {
          "place": "Arch1",
          "category": "Leisure",
          "coordinates": {
            "lat": 51.52258,
            "lng": 0.00496
          },
          "id": "6720a4d8042af000268875d2"
        },
        {
          "place": "LDN East",
          "category": "Leisure",
          "coordinates": {
            "lat": 51.51718,
            "lng": 0.00452
          },
          "id": "6720a505042af000268875d3"
        },
        {
          "place": "East London RFC",
          "category": "Leisure",
          "coordinates": {
            "lat": 51.5293,
            "lng": 0.0109
          },
          "id": "6720a598042af00026887643"
        },
        {
          "place": "New Docklands Steam Baths",
          "category": "Leisure",
          "coordinates": {
            "lat": 51.5174,
            "lng": 0.00545
          },
          "id": "6720a5e7042af00026887722"
        },
        {
          "place": "Memorial Park",
          "category": "Nature",
          "coordinates": {
            "lat": 51.52628,
            "lng": 0.01037
          },
          "id": "6720a656042af00026887723"
        },
        {
          "place": "Westfield Stratford",
          "category": "Retail",
          "coordinates": {
            "lat": 51.54238,
            "lng": -0.00436
          },
          "id": "6720a759042af00026887793"
        },
        {
          "place": "Morrisons Canning Town",
          "category": "Retail",
          "coordinates": {
            "lat": 51.51676,
            "lng": 0.01466
          },
          "id": "6720a7de042af00026887794"
        },
        {
          "place": "Hackney Marshes",
          "category": "Nature",
          "coordinates": {
            "lat": 51.55837,
            "lng": -0.02911
          },
          "id": "6720a857042af00026887804"
        },
        {
          "place": "West Ham Park",
          "category": "Nature",
          "coordinates": {
            "lat": 51.54,
            "lng": 0.02013
          },
          "id": "6720a8c1042af00026887874"
        },
        {
          "place": "Grange Food and Wine",
          "category": "Retail",
          "coordinates": {
            "lat": 51.52532,
            "lng": 0.01241
          },
          "id": "6720a97e042af000268878e4"
        }
      ]
    },
    "mapStyling": {
      "styleKey": "silver",
      "style": [],
      "customColors": {
        "landscapeColor": "#ebc000",
        "waterColor": "#1b00ff",
        "parksColor": "#ffdd01",
        "arterialColor": "#ffffff",
        "localColor": "#ffffff",
        "labelsColor": "#eae7d3"
      }
    },
    "categories": [
      "Leisure",
      "Retail",
      "Nature"
    ]
  },
  "residencesData": {
    "relationTo": "residences",
    "feature": "residencesType1",
    "categories": [
      "Floorplan",
      "View",
      "Specifications"
    ],
    "name": "Residences - West Ham"
  },
  "orbitData": {
    "relationTo": "orbits",
    "name": "Orbits - West Ham",
    "feature": "orbit3d",
    "cameraPoints": [
      {
        "title": "Welcome to West Ham Village.",
        "subTitle": "A remarkable collection of Shared Ownership apartments situated in Zone 2",
        "body": "Just moments from West Ham station in Berkeley’s TwelveTrees Park, discover this exciting new neighbourhood. With 12 acres of parkland, shops, and restaurants, this well-connected area of east London blends urban life with tranquillity. Isn’t it time you came home to your peaceful oasis in the city?",
        "positions": {
          "x": 0.13716703312747883,
          "y": 0.13776017086592215,
          "z": 0.16171126459442597
        },
        "targets": {
          "x": 0,
          "y": 0,
          "z": -1
        },
        "id": "671246277330ed002d7eda56"
      },
      {
        "title": "East London's",
        "subTitle": "Best kept Secret",
        "body": "With its rich heritage, diverse creative scene, and proximity to stunning green and blue spaces, West Ham combines urban convenience with a welcoming atmosphere. Discover the hidden gems and thriving energy that make West Ham an exceptional place to live, work, and play.",
        "positions": {
          "x": 0.040955497008338566,
          "y": 0.16083660438919942,
          "z": 0.24380504491982283
        },
        "targets": {
          "x": 0,
          "y": 0,
          "z": -1
        },
        "id": "671246357330ed002d7eda59"
      },
      {
        "title": "Refreshing Connections",
        "subTitle": "Where nature and community blooms",
        "body": "West Ham is set to become one of east London’s most dynamic locations. With excellent transport links, vibrant culture, and picturesque waterside walks, there’s so much to explore and enjoy.",
        "positions": {
          "x": -0.0967079247979765,
          "y": 0.15279397695919308,
          "z": 0.2329983773790439
        },
        "targets": {
          "x": 0,
          "y": 0,
          "z": 0
        },
        "id": "671246437330ed002d7eda5c"
      },
      {
        "title": "East End Grit Meet High End Spec",
        "subTitle": "Welcome to your new sanctuary on a picturesque, landscaped avenue.",
        "body": "Each spacious apartment is designed for comfort and convenience, featuring practical details like full- height glass splashbacks, ample storage, and built-in wardrobes.",
        "positions": {
          "x": 1,
          "y": 1,
          "z": 0
        },
        "targets": {
          "x": 0,
          "y": 0,
          "z": 0
        },
        "id": "673c933e1128c70026cfd1d3"
      }
    ],
    "touchPoints": []
  },
  "splashData": {
    "relationTo": "splash",
    "name": "Splash - West Ham",
    "heroCopy": "STUDIO, 1&2 BEDROOM apartments",
    "heroCopyFont": "primaryFont",
    "heroCopyColour": "white",
    "heroCopyStyle": "italicEnd",
    "buttonBgColour": "accent",
    "buttonWidth": 4,
    "buttonBgLink": "orbits",
    "logo": "lightColourLogo",
    "logoWidth": 50,
    "splashBg": "image/video",
    "splashBgMediaOpacity": 1,
    "splashBgMedia": {
      "id": "673c8e4ba0480a03f68b0129",
      "publicURL": "/payload/splashMedia/TRINITY_PARK.mp4",
      "filename": "TRINITY_PARK.mp4",
      "mimeType": "video/mp4",
      "filesize": 79807660,
      "createdAt": "2024-11-19T13:10:35.352Z",
      "updatedAt": "2024-11-19T13:10:35.352Z",
      "url": "https://luna.vmistudio.com/api/splashMedia/file/TRINITY_PARK.mp4",
      "thumbnailURL": null
    }
  },
  "availabilityData": {
    "relationTo": "unitAvailability",
    "name": "Unit Availability - West Ham",
    "data": [
      {
        "unitName": "N01B-04-01",
        "price": 200000,
        "floor": 4,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48bb"
      },
      {
        "unitName": "N01B-04-02",
        "price": 300000,
        "floor": 4,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "SE",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48bc"
      },
      {
        "unitName": "N01B-04-03",
        "price": 350000,
        "floor": 4,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 3-2B/4P",
        "aspect": "NW/SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48bd"
      },
      {
        "unitName": "N01B-04-04",
        "price": 280000,
        "floor": 4,
        "beds": 0,
        "baths": null,
        "unitType": "TYPE 1-STUDIO",
        "aspect": "NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48be"
      },
      {
        "unitName": "N01B-04-05",
        "price": 355000,
        "floor": 4,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "NW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48bf"
      },
      {
        "unitName": "N01B-04-06",
        "price": 384000,
        "floor": 4,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 2-1B/2P",
        "aspect": "NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48c0"
      },
      {
        "unitName": "N01B-04-07",
        "price": 413000,
        "floor": 4,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "NW/SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48c1"
      },
      {
        "unitName": "N01B-04-08",
        "price": 442000,
        "floor": 4,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/3P",
        "aspect": "SE",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48c2"
      },
      {
        "unitName": "N01B-05-01",
        "price": 471000,
        "floor": 5,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48c3"
      },
      {
        "unitName": "N01B-05-02",
        "price": 500000,
        "floor": 5,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "SE",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48c4"
      },
      {
        "unitName": "N01B-05-03",
        "price": 529000,
        "floor": 5,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 3-2B/4P",
        "aspect": "NW/SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48c5"
      },
      {
        "unitName": "N01B-05-04",
        "price": 558000,
        "floor": 5,
        "beds": 0,
        "baths": null,
        "unitType": "TYPE 1-STUDIO",
        "aspect": "NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48c6"
      },
      {
        "unitName": "N01B-05-05",
        "price": 587000,
        "floor": 5,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "NW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48c7"
      },
      {
        "unitName": "N01B-05-06",
        "price": 616000,
        "floor": 5,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 2-1B/2P",
        "aspect": "NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48c8"
      },
      {
        "unitName": "N01B-05-07",
        "price": 645000,
        "floor": 5,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "NW/SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48c9"
      },
      {
        "unitName": "N01B-05-08",
        "price": 674000,
        "floor": 5,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/3P",
        "aspect": "SE",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48ca"
      },
      {
        "unitName": "N01B-06-01",
        "price": 703000,
        "floor": 6,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48cb"
      },
      {
        "unitName": "N01B-06-02",
        "price": 732000,
        "floor": 6,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "SE",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48cc"
      },
      {
        "unitName": "N01B-06-03",
        "price": 761000,
        "floor": 6,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 3-2B/4P",
        "aspect": "NW/SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48cd"
      },
      {
        "unitName": "N01B-06-04",
        "price": 790000,
        "floor": 6,
        "beds": 0,
        "baths": null,
        "unitType": "TYPE 1-STUDIO",
        "aspect": "NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48ce"
      },
      {
        "unitName": "N01B-06-05",
        "price": 819000,
        "floor": 6,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "NW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48cf"
      },
      {
        "unitName": "N01B-06-06",
        "price": 848000,
        "floor": 6,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 2-1B/2P",
        "aspect": "NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48d0"
      },
      {
        "unitName": "N01B-06-07",
        "price": 877000,
        "floor": 6,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "NW/SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48d1"
      },
      {
        "unitName": "N01B-06-08",
        "price": 906000,
        "floor": 6,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/3P",
        "aspect": "SE",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48d2"
      },
      {
        "unitName": "N01B-07-01",
        "price": 935000,
        "floor": 7,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48d3"
      },
      {
        "unitName": "N01B-07-02",
        "price": 964000,
        "floor": 7,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "SE",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48d4"
      },
      {
        "unitName": "N01B-07-03",
        "price": 993000,
        "floor": 7,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 3-2B/4P",
        "aspect": "NW/SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48d5"
      },
      {
        "unitName": "N01B-07-04",
        "price": 1022000,
        "floor": 7,
        "beds": 0,
        "baths": null,
        "unitType": "TYPE 1-STUDIO",
        "aspect": "NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48d6"
      },
      {
        "unitName": "N01B-07-05",
        "price": 1051000,
        "floor": 7,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "NW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48d7"
      },
      {
        "unitName": "N01B-07-06",
        "price": 1080000,
        "floor": 7,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 2-1B/2P",
        "aspect": "NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48d8"
      },
      {
        "unitName": "N01B-07-07",
        "price": 1109000,
        "floor": 7,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "NW/SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48d9"
      },
      {
        "unitName": "N01B-07-08",
        "price": 1138000,
        "floor": 7,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/3P",
        "aspect": "SE",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48da"
      },
      {
        "unitName": "N01B-08-01",
        "price": 1167000,
        "floor": 8,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48db"
      },
      {
        "unitName": "N01B-08-02",
        "price": 1196000,
        "floor": 8,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "SE",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48dc"
      },
      {
        "unitName": "N01B-08-03",
        "price": 1225000,
        "floor": 8,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 3-2B/4P",
        "aspect": "NW/SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48dd"
      },
      {
        "unitName": "N01B-08-04",
        "price": 1254000,
        "floor": 8,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 4-2B/4P",
        "aspect": "NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48de"
      },
      {
        "unitName": "N01B-08-05",
        "price": 1283000,
        "floor": 8,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 5-2B/4P",
        "aspect": "NW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48df"
      },
      {
        "unitName": "N01B-08-06",
        "price": 1312000,
        "floor": 8,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "NW/SE",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48e0"
      },
      {
        "unitName": "N01B-08-07",
        "price": 1341000,
        "floor": 8,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/3P",
        "aspect": "SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48e1"
      },
      {
        "unitName": "N01B-09-01",
        "price": 1370000,
        "floor": 9,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SE",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48e2"
      },
      {
        "unitName": "N01B-09-02",
        "price": 1399000,
        "floor": 9,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48e3"
      },
      {
        "unitName": "N01B-09-03",
        "price": 1428000,
        "floor": 9,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 3-2B/4P",
        "aspect": "NW/SE",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48e4"
      },
      {
        "unitName": "N01B-09-04",
        "price": 1457000,
        "floor": 9,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 4-2B/4P",
        "aspect": "NW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48e5"
      },
      {
        "unitName": "N01B-09-05",
        "price": 1486000,
        "floor": 9,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 5-2B/4P",
        "aspect": "NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48e6"
      },
      {
        "unitName": "N01B-09-06",
        "price": 1515000,
        "floor": 9,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "NW/SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48e7"
      },
      {
        "unitName": "N01B-09-07",
        "price": 1544000,
        "floor": 9,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/3P",
        "aspect": "SE",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48e8"
      },
      {
        "unitName": "S01C-02-01",
        "price": 1573000,
        "floor": 2,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "E",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48e9"
      },
      {
        "unitName": "S01C-02-02",
        "price": 1602000,
        "floor": 2,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 2-1B/2P",
        "aspect": "E/SW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48ea"
      },
      {
        "unitName": "S01C-02-03",
        "price": 1631000,
        "floor": 2,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "SW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48eb"
      },
      {
        "unitName": "S01C-02-04",
        "price": 1660000,
        "floor": 2,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2 V-2B/3P",
        "aspect": "SW/NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48ec"
      },
      {
        "unitName": "S01C-02-05",
        "price": 1689000,
        "floor": 2,
        "beds": 0,
        "baths": null,
        "unitType": "TYPE 1-STUDIO",
        "aspect": "NW/E",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48ed"
      },
      {
        "unitName": "S01C-03-01",
        "price": 1718000,
        "floor": 3,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "E",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48ee"
      },
      {
        "unitName": "S01C-03-02",
        "price": 1747000,
        "floor": 3,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 3-2B/3P",
        "aspect": "E/SW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48ef"
      },
      {
        "unitName": "S01C-03-03",
        "price": 1776000,
        "floor": 3,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "SW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48f0"
      },
      {
        "unitName": "S01C-03-04",
        "price": 1805000,
        "floor": 3,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SW/NW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48f1"
      },
      {
        "unitName": "S01C-03-05",
        "price": 1834000,
        "floor": 3,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 3-1B/2P",
        "aspect": "NW/E",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48f2"
      },
      {
        "unitName": "S01C-04-01",
        "price": 1863000,
        "floor": 4,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "E",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48f3"
      },
      {
        "unitName": "S01C-04-02",
        "price": 1892000,
        "floor": 4,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "E/SW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48f4"
      },
      {
        "unitName": "S01C-04-03",
        "price": 1921000,
        "floor": 4,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "SW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48f5"
      },
      {
        "unitName": "S01C-04-04",
        "price": 1950000,
        "floor": 4,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SW/NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48f6"
      },
      {
        "unitName": "S01C-04-05",
        "price": 1979000,
        "floor": 4,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 3-1B/2P",
        "aspect": "NW/E",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48f7"
      },
      {
        "unitName": "S01C-05-01",
        "price": 2008000,
        "floor": 5,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "E",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48f8"
      },
      {
        "unitName": "S01C-05-02",
        "price": 2037000,
        "floor": 5,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "E/SW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48f9"
      },
      {
        "unitName": "S01C-05-03",
        "price": 2066000,
        "floor": 5,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "SW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48fa"
      },
      {
        "unitName": "S01C-05-04",
        "price": 2095000,
        "floor": 5,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SW/NW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48fb"
      },
      {
        "unitName": "S01C-05-05",
        "price": 2124000,
        "floor": 5,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 3-1B/2P",
        "aspect": "NW/E",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48fc"
      },
      {
        "unitName": "S01C-06-01",
        "price": 2153000,
        "floor": 6,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "E",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48fd"
      },
      {
        "unitName": "S01C-06-02",
        "price": 2182000,
        "floor": 6,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "E/SW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e48fe"
      },
      {
        "unitName": "S01C-06-03",
        "price": 2211000,
        "floor": 6,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "SW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e48ff"
      },
      {
        "unitName": "S01C-06-04",
        "price": 2240000,
        "floor": 6,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SW/NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e4900"
      },
      {
        "unitName": "S01C-06-05",
        "price": 2269000,
        "floor": 6,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 3-1B/2P",
        "aspect": "NW/E",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e4901"
      },
      {
        "unitName": "S01C-07-01",
        "price": 2298000,
        "floor": 7,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "E",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e4902"
      },
      {
        "unitName": "S01C-07-02",
        "price": 2327000,
        "floor": 7,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "E/SW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e4903"
      },
      {
        "unitName": "S01C-07-03",
        "price": 2356000,
        "floor": 7,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "SW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e4904"
      },
      {
        "unitName": "S01C-07-04",
        "price": 2385000,
        "floor": 7,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SW/NW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e4905"
      },
      {
        "unitName": "S01C-07-05",
        "price": 2414000,
        "floor": 7,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 3-1B/2P",
        "aspect": "NW/E",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e4906"
      },
      {
        "unitName": "S01C-08-01",
        "price": 2443000,
        "floor": 8,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "E",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e4907"
      },
      {
        "unitName": "S01C-08-02",
        "price": 2472000,
        "floor": 8,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "E/SW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e4908"
      },
      {
        "unitName": "S01C-08-03",
        "price": 2501000,
        "floor": 8,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "SW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e4909"
      },
      {
        "unitName": "S01C-08-04",
        "price": 2530000,
        "floor": 8,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SW/NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e490a"
      },
      {
        "unitName": "S01C-08-05",
        "price": 2559000,
        "floor": 8,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 3-1B/2P",
        "aspect": "NW/E",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e490b"
      },
      {
        "unitName": "S01C-09-01",
        "price": 2588000,
        "floor": 9,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "E",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e490c"
      },
      {
        "unitName": "S01C-09-02",
        "price": 2617000,
        "floor": 9,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "E/SW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e490d"
      },
      {
        "unitName": "S01C-09-03",
        "price": 2646000,
        "floor": 9,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "SW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e490e"
      },
      {
        "unitName": "S01C-09-04",
        "price": 2675000,
        "floor": 9,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SW/NW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e490f"
      },
      {
        "unitName": "S01C-09-05",
        "price": 2704000,
        "floor": 9,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 3-1B/2P",
        "aspect": "NW/E",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e4910"
      },
      {
        "unitName": "S01C-10-01",
        "price": 2733000,
        "floor": 10,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "E",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e4911"
      },
      {
        "unitName": "S01C-10-02",
        "price": 2762000,
        "floor": 10,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "E/SW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e4912"
      },
      {
        "unitName": "S01C-10-03",
        "price": 2791000,
        "floor": 10,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "SW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e4913"
      },
      {
        "unitName": "S01C-10-04",
        "price": 2820000,
        "floor": 10,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SW/NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e4914"
      },
      {
        "unitName": "S01C-10-05",
        "price": 2849000,
        "floor": 10,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 3-1B/2P",
        "aspect": "NW/E",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e4915"
      },
      {
        "unitName": "S01C-11-01",
        "price": 2878000,
        "floor": 11,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "E",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e4916"
      },
      {
        "unitName": "S01C-11-02",
        "price": 2907000,
        "floor": 11,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "E/SW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e4917"
      },
      {
        "unitName": "S01C-11-03",
        "price": 2936000,
        "floor": 11,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "SW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e4918"
      },
      {
        "unitName": "S01C-11-04",
        "price": 2965000,
        "floor": 11,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SW/NW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e4919"
      },
      {
        "unitName": "S01C-11-05",
        "price": 2994000,
        "floor": 11,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 3-1B/2P",
        "aspect": "NW/E",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e491a"
      },
      {
        "unitName": "S01C-12-01",
        "price": 3023000,
        "floor": 12,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "E",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e491b"
      },
      {
        "unitName": "S01C-12-02",
        "price": 3052000,
        "floor": 12,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "E/SW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e491c"
      },
      {
        "unitName": "S01C-12-03",
        "price": 3081000,
        "floor": 12,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "SW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e491d"
      },
      {
        "unitName": "S01C-12-04",
        "price": 3110000,
        "floor": 12,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SW/NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e491e"
      },
      {
        "unitName": "S01C-12-05",
        "price": 3139000,
        "floor": 12,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 3-1B/2P",
        "aspect": "NW/E",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e491f"
      },
      {
        "unitName": "S01C-13-01",
        "price": 3168000,
        "floor": 13,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "E",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e4920"
      },
      {
        "unitName": "S01C-13-02",
        "price": 3197000,
        "floor": 13,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "E/SW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e4921"
      },
      {
        "unitName": "S01C-13-03",
        "price": 3226000,
        "floor": 13,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "SW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e4922"
      },
      {
        "unitName": "S01C-13-04",
        "price": 3255000,
        "floor": 13,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SW/NW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e4923"
      },
      {
        "unitName": "S01C-13-05",
        "price": 3284000,
        "floor": 13,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 3-1B/2P",
        "aspect": "NW/E",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e4924"
      },
      {
        "unitName": "S01C-14-01",
        "price": 3313000,
        "floor": 14,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "E",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e4925"
      },
      {
        "unitName": "S01C-14-02",
        "price": 3342000,
        "floor": 14,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "E/SW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e4926"
      },
      {
        "unitName": "S01C-14-03",
        "price": 3371000,
        "floor": 14,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "SW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e4927"
      },
      {
        "unitName": "S01C-14-04",
        "price": 3400000,
        "floor": 14,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SW/NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "6758348a07465a00261e4928"
      },
      {
        "unitName": "S01C-14-05",
        "price": 3429000,
        "floor": 14,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 3-1B/2P",
        "aspect": "NW/E",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "6758348a07465a00261e4929"
      }
    ],
    "csv": null
  },
  "typeData": {
    "relationTo": "unitTypes",
    "name": "Unit Types - West Ham",
    "unitTypes": [
      {
        "name": "TYPE 1-1B/2P",
        "beds": 1,
        "baths": 1,
        "floorplan": {
          "id": "673ddfc2a0480a03f68b8164",
          "publicURL": "/payload/floorPlanMedia/TYPE 1-1B2P.webp",
          "filename": "TYPE 1-1B2P.webp",
          "mimeType": "image/webp",
          "filesize": 1141266,
          "width": 4961,
          "height": 3508,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-20T13:10:26.309Z",
          "updatedAt": "2024-11-20T13:10:26.309Z",
          "url": "https://luna.vmistudio.com/api/floorPlanMedia/file/TYPE 1-1B2P.webp",
          "thumbnailURL": null
        },
        "id": "673dc7e71128c70026d01457"
      },
      {
        "name": "TYPE 1-2B/4P",
        "beds": 1,
        "baths": 1,
        "floorplan": {
          "id": "673ddfcea0480a03f68b8194",
          "publicURL": "/payload/floorPlanMedia/TYPE 1-2B4P.webp",
          "filename": "TYPE 1-2B4P.webp",
          "mimeType": "image/webp",
          "filesize": 49572,
          "width": 1191,
          "height": 842,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-20T13:10:38.837Z",
          "updatedAt": "2024-11-20T13:10:38.837Z",
          "url": "https://luna.vmistudio.com/api/floorPlanMedia/file/TYPE 1-2B4P.webp",
          "thumbnailURL": null
        },
        "id": "673dc82d1128c70026d01458"
      },
      {
        "name": "TYPE 1-STUDIO",
        "beds": 1,
        "baths": 1,
        "floorplan": {
          "id": "673ddfe3a0480a03f68b8215",
          "publicURL": "/payload/floorPlanMedia/TYPE 1-STUDIO.webp",
          "filename": "TYPE 1-STUDIO.webp",
          "mimeType": "image/webp",
          "filesize": 750734,
          "width": 4961,
          "height": 3508,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-20T13:10:59.054Z",
          "updatedAt": "2024-11-20T13:10:59.054Z",
          "url": "https://luna.vmistudio.com/api/floorPlanMedia/file/TYPE 1-STUDIO.webp",
          "thumbnailURL": null
        },
        "id": "673dc8471128c70026d01459"
      },
      {
        "name": "TYPE 2 V-2B/3P",
        "beds": 1,
        "baths": 1,
        "floorplan": {
          "id": "673ddff3a0480a03f68b823f",
          "publicURL": "/payload/floorPlanMedia/TYPE 2 V-2B3P.webp",
          "filename": "TYPE 2 V-2B3P.webp",
          "mimeType": "image/webp",
          "filesize": 911590,
          "width": 5669,
          "height": 3508,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-20T13:11:15.082Z",
          "updatedAt": "2024-11-20T13:11:15.082Z",
          "url": "https://luna.vmistudio.com/api/floorPlanMedia/file/TYPE 2 V-2B3P.webp",
          "thumbnailURL": null
        },
        "id": "673dc85d1128c70026d0145a"
      },
      {
        "name": "TYPE 2-1B/2P",
        "beds": 1,
        "baths": 1,
        "floorplan": {
          "id": "673de009a0480a03f68b82c7",
          "publicURL": "/payload/floorPlanMedia/TYPE 2-1B2P.webp",
          "filename": "TYPE 2-1B2P.webp",
          "mimeType": "image/webp",
          "filesize": 835896,
          "width": 4961,
          "height": 3508,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-20T13:11:37.413Z",
          "updatedAt": "2024-11-20T13:11:37.413Z",
          "url": "https://luna.vmistudio.com/api/floorPlanMedia/file/TYPE 2-1B2P.webp",
          "thumbnailURL": null
        },
        "id": "673dc8731128c70026d0145b"
      },
      {
        "name": "TYPE 2-2B/3P",
        "beds": 1,
        "baths": 1,
        "floorplan": {
          "id": "673de014a0480a03f68b82f1",
          "publicURL": "/payload/floorPlanMedia/TYPE 2-2B3P.webp",
          "filename": "TYPE 2-2B3P.webp",
          "mimeType": "image/webp",
          "filesize": 42552,
          "width": 1191,
          "height": 842,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-20T13:11:48.449Z",
          "updatedAt": "2024-11-20T13:11:48.449Z",
          "url": "https://luna.vmistudio.com/api/floorPlanMedia/file/TYPE 2-2B3P.webp",
          "thumbnailURL": null
        },
        "id": "673dc8871128c70026d0145c"
      },
      {
        "name": "TYPE 2-2B/4P",
        "beds": 1,
        "baths": 1,
        "floorplan": {
          "id": "673de024a0480a03f68b8310",
          "publicURL": "/payload/floorPlanMedia/TYPE 2-2B4P.webp",
          "filename": "TYPE 2-2B4P.webp",
          "mimeType": "image/webp",
          "filesize": 1432710,
          "width": 4961,
          "height": 3508,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-20T13:12:04.359Z",
          "updatedAt": "2024-11-20T13:12:04.359Z",
          "url": "https://luna.vmistudio.com/api/floorPlanMedia/file/TYPE 2-2B4P.webp",
          "thumbnailURL": null
        },
        "id": "673dc8a01128c70026d0145d"
      },
      {
        "name": "TYPE 3-1B/2P",
        "beds": 1,
        "baths": 1,
        "floorplan": {
          "id": "673de036a0480a03f68b832f",
          "publicURL": "/payload/floorPlanMedia/TYPE 3-1B2P.webp",
          "filename": "TYPE 3-1B2P.webp",
          "mimeType": "image/webp",
          "filesize": 639928,
          "width": 4961,
          "height": 3508,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-20T13:12:22.130Z",
          "updatedAt": "2024-11-20T13:12:22.130Z",
          "url": "https://luna.vmistudio.com/api/floorPlanMedia/file/TYPE 3-1B2P.webp",
          "thumbnailURL": null
        },
        "id": "673dc8b81128c70026d0145e"
      },
      {
        "name": "TYPE 3-2B/3P",
        "beds": 1,
        "baths": 1,
        "floorplan": {
          "id": "673de044a0480a03f68b8351",
          "publicURL": "/payload/floorPlanMedia/TYPE 3-2B3P.webp",
          "filename": "TYPE 3-2B3P.webp",
          "mimeType": "image/webp",
          "filesize": 1160314,
          "width": 4961,
          "height": 3508,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-20T13:12:36.668Z",
          "updatedAt": "2024-11-20T13:12:36.668Z",
          "url": "https://luna.vmistudio.com/api/floorPlanMedia/file/TYPE 3-2B3P.webp",
          "thumbnailURL": null
        },
        "id": "673dc8cf1128c70026d0145f"
      }
    ]
  },
  "viewsData": {
    "relationTo": "unitViews",
    "name": "Unit Views - West Ham",
    "viewMedia": [
      {
        "image": {
          "id": "67404fa7a0480a03f68c1f4a",
          "publicURL": "/payload/viewMedia/L4-E.webp",
          "filename": "L4-E.webp",
          "mimeType": "image/webp",
          "filesize": 529044,
          "width": 3000,
          "height": 1511,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:32:23.661Z",
          "updatedAt": "2024-11-22T09:32:23.661Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L4-E.webp",
          "thumbnailURL": null
        },
        "id": "673f64001128c70026d0494b"
      },
      {
        "image": {
          "id": "67404fb9a0480a03f68c1f67",
          "publicURL": "/payload/viewMedia/L4-N.webp",
          "filename": "L4-N.webp",
          "mimeType": "image/webp",
          "filesize": 431000,
          "width": 3000,
          "height": 1576,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:32:41.638Z",
          "updatedAt": "2024-11-22T09:32:41.638Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L4-N.webp",
          "thumbnailURL": null
        },
        "id": "673f641a1128c70026d0494c"
      },
      {
        "image": {
          "id": "67404fc0a0480a03f68c1f78",
          "publicURL": "/payload/viewMedia/L4-NE.webp",
          "filename": "L4-NE.webp",
          "mimeType": "image/webp",
          "filesize": 458238,
          "width": 2927,
          "height": 1688,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:32:48.001Z",
          "updatedAt": "2024-11-22T09:32:48.001Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L4-NE.webp",
          "thumbnailURL": null
        },
        "id": "673f64351128c70026d04957"
      },
      {
        "image": {
          "id": "67404fcea0480a03f68c1f8d",
          "publicURL": "/payload/viewMedia/L4-NW.webp",
          "filename": "L4-NW.webp",
          "mimeType": "image/webp",
          "filesize": 559358,
          "width": 2879,
          "height": 1688,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:33:02.476Z",
          "updatedAt": "2024-11-22T09:33:02.476Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L4-NW.webp",
          "thumbnailURL": null
        },
        "id": "673f64551128c70026d04958"
      },
      {
        "image": {
          "id": "67404fd4a0480a03f68c1f9e",
          "publicURL": "/payload/viewMedia/L4-S.webp",
          "filename": "L4-S.webp",
          "mimeType": "image/webp",
          "filesize": 434364,
          "width": 3000,
          "height": 1476,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:33:08.737Z",
          "updatedAt": "2024-11-22T09:33:08.737Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L4-S.webp",
          "thumbnailURL": null
        },
        "id": "673f64611128c70026d04959"
      },
      {
        "image": {
          "id": "67404fe7a0480a03f68c1fb3",
          "publicURL": "/payload/viewMedia/L4-SE.webp",
          "filename": "L4-SE.webp",
          "mimeType": "image/webp",
          "filesize": 451758,
          "width": 3000,
          "height": 1520,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:33:27.371Z",
          "updatedAt": "2024-11-22T09:33:27.371Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L4-SE.webp",
          "thumbnailURL": null
        },
        "id": "673f646b1128c70026d0495a"
      },
      {
        "image": {
          "id": "67404ff1a0480a03f68c1fc8",
          "publicURL": "/payload/viewMedia/L4-SW.webp",
          "filename": "L4-SW.webp",
          "mimeType": "image/webp",
          "filesize": 402526,
          "width": 3000,
          "height": 1505,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:33:37.400Z",
          "updatedAt": "2024-11-22T09:33:37.400Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L4-SW.webp",
          "thumbnailURL": null
        },
        "id": "673f64761128c70026d0495b"
      },
      {
        "image": {
          "id": "67404ffaa0480a03f68c1fd9",
          "publicURL": "/payload/viewMedia/L4-W.webp",
          "filename": "L4-W.webp",
          "mimeType": "image/webp",
          "filesize": 512704,
          "width": 2713,
          "height": 1688,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:33:46.323Z",
          "updatedAt": "2024-11-22T09:33:46.323Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L4-W.webp",
          "thumbnailURL": null
        },
        "id": "673f64811128c70026d0495c"
      },
      {
        "image": {
          "id": "67405028a0480a03f68c2027",
          "publicURL": "/payload/viewMedia/L6-E.webp",
          "filename": "L6-E.webp",
          "mimeType": "image/webp",
          "filesize": 434960,
          "width": 3000,
          "height": 1585,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:34:32.081Z",
          "updatedAt": "2024-11-22T09:34:32.081Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L6-E.webp",
          "thumbnailURL": null
        },
        "id": "674050091128c70026d04c1f"
      },
      {
        "image": {
          "id": "67405038a0480a03f68c203f",
          "publicURL": "/payload/viewMedia/L6-N.webp",
          "filename": "L6-N.webp",
          "mimeType": "image/webp",
          "filesize": 440208,
          "width": 3000,
          "height": 1434,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:34:48.501Z",
          "updatedAt": "2024-11-22T09:34:48.501Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L6-N.webp",
          "thumbnailURL": null
        },
        "id": "674050161128c70026d04c20"
      },
      {
        "image": {
          "id": "67405045a0480a03f68c2057",
          "publicURL": "/payload/viewMedia/L6-NE.webp",
          "filename": "L6-NE.webp",
          "mimeType": "image/webp",
          "filesize": 508586,
          "width": 2984,
          "height": 1688,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:35:01.965Z",
          "updatedAt": "2024-11-22T09:35:01.965Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L6-NE.webp",
          "thumbnailURL": null
        },
        "id": "674050181128c70026d04c21"
      },
      {
        "image": {
          "id": "67405051a0480a03f68c206f",
          "publicURL": "/payload/viewMedia/L6-NW.webp",
          "filename": "L6-NW.webp",
          "mimeType": "image/webp",
          "filesize": 470530,
          "width": 3000,
          "height": 1426,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:35:13.154Z",
          "updatedAt": "2024-11-22T09:35:13.154Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L6-NW.webp",
          "thumbnailURL": null
        },
        "id": "6740501a1128c70026d04c22"
      },
      {
        "image": {
          "id": "67405058a0480a03f68c2083",
          "publicURL": "/payload/viewMedia/L6-S.webp",
          "filename": "L6-S.webp",
          "mimeType": "image/webp",
          "filesize": 406550,
          "width": 3000,
          "height": 1587,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:35:20.040Z",
          "updatedAt": "2024-11-22T09:35:20.040Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L6-S.webp",
          "thumbnailURL": null
        },
        "id": "6740501b1128c70026d04c23"
      },
      {
        "image": {
          "id": "67405063a0480a03f68c20a5",
          "publicURL": "/payload/viewMedia/L6-SE.webp",
          "filename": "L6-SE.webp",
          "mimeType": "image/webp",
          "filesize": 468778,
          "width": 3000,
          "height": 1439,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:35:31.748Z",
          "updatedAt": "2024-11-22T09:35:31.748Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L6-SE.webp",
          "thumbnailURL": null
        },
        "id": "6740501d1128c70026d04c24"
      },
      {
        "image": {
          "id": "6740506aa0480a03f68c20bd",
          "publicURL": "/payload/viewMedia/L6-SW.webp",
          "filename": "L6-SW.webp",
          "mimeType": "image/webp",
          "filesize": 402526,
          "width": 3000,
          "height": 1505,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:35:38.973Z",
          "updatedAt": "2024-11-22T09:35:38.973Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L6-SW.webp",
          "thumbnailURL": null
        },
        "id": "6740501e1128c70026d04c25"
      },
      {
        "image": {
          "id": "67405073a0480a03f68c20d1",
          "publicURL": "/payload/viewMedia/L6-W.webp",
          "filename": "L6-W.webp",
          "mimeType": "image/webp",
          "filesize": 402526,
          "width": 3000,
          "height": 1505,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:35:47.495Z",
          "updatedAt": "2024-11-22T09:35:47.495Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L6-W.webp",
          "thumbnailURL": null
        },
        "id": "674050201128c70026d04c26"
      },
      {
        "image": {
          "id": "6740509ba0480a03f68c211f",
          "publicURL": "/payload/viewMedia/L8-E.webp",
          "filename": "L8-E.webp",
          "mimeType": "image/webp",
          "filesize": 542128,
          "width": 3000,
          "height": 1591,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:36:27.627Z",
          "updatedAt": "2024-11-22T09:36:27.627Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L8-E.webp",
          "thumbnailURL": null
        },
        "id": "674050831128c70026d04c27"
      },
      {
        "image": {
          "id": "674050a5a0480a03f68c2133",
          "publicURL": "/payload/viewMedia/L8-N.webp",
          "filename": "L8-N.webp",
          "mimeType": "image/webp",
          "filesize": 504332,
          "width": 3000,
          "height": 1651,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:36:37.492Z",
          "updatedAt": "2024-11-22T09:36:37.492Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L8-N.webp",
          "thumbnailURL": null
        },
        "id": "674050851128c70026d04c28"
      },
      {
        "image": {
          "id": "674050aea0480a03f68c214b",
          "publicURL": "/payload/viewMedia/L8-NE.webp",
          "filename": "L8-NE.webp",
          "mimeType": "image/webp",
          "filesize": 498192,
          "width": 3000,
          "height": 1570,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:36:46.564Z",
          "updatedAt": "2024-11-22T09:36:46.564Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L8-NE.webp",
          "thumbnailURL": null
        },
        "id": "674050861128c70026d04c29"
      },
      {
        "image": {
          "id": "674050b7a0480a03f68c2163",
          "publicURL": "/payload/viewMedia/L8-NW.webp",
          "filename": "L8-NW.webp",
          "mimeType": "image/webp",
          "filesize": 434676,
          "width": 3000,
          "height": 1510,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:36:55.127Z",
          "updatedAt": "2024-11-22T09:36:55.127Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L8-NW.webp",
          "thumbnailURL": null
        },
        "id": "674050881128c70026d04c2a"
      },
      {
        "image": {
          "id": "674050bea0480a03f68c2177",
          "publicURL": "/payload/viewMedia/L8-S.webp",
          "filename": "L8-S.webp",
          "mimeType": "image/webp",
          "filesize": 380450,
          "width": 3000,
          "height": 1399,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:37:02.561Z",
          "updatedAt": "2024-11-22T09:37:02.561Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L8-S.webp",
          "thumbnailURL": null
        },
        "id": "674050891128c70026d04c2b"
      },
      {
        "image": {
          "id": "674050c5a0480a03f68c218f",
          "publicURL": "/payload/viewMedia/L8-SE.webp",
          "filename": "L8-SE.webp",
          "mimeType": "image/webp",
          "filesize": 459250,
          "width": 3000,
          "height": 1482,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:37:09.663Z",
          "updatedAt": "2024-11-22T09:37:09.663Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L8-SE.webp",
          "thumbnailURL": null
        },
        "id": "6740508b1128c70026d04c2c"
      },
      {
        "image": {
          "id": "674050cda0480a03f68c21a7",
          "publicURL": "/payload/viewMedia/L8-SW.webp",
          "filename": "L8-SW.webp",
          "mimeType": "image/webp",
          "filesize": 348840,
          "width": 3000,
          "height": 1392,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:37:17.682Z",
          "updatedAt": "2024-11-22T09:37:17.682Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L8-SW.webp",
          "thumbnailURL": null
        },
        "id": "6740508c1128c70026d04c2d"
      },
      {
        "image": {
          "id": "674050d3a0480a03f68c21bb",
          "publicURL": "/payload/viewMedia/L8-W.webp",
          "filename": "L8-W.webp",
          "mimeType": "image/webp",
          "filesize": 408344,
          "width": 3000,
          "height": 1584,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:37:23.183Z",
          "updatedAt": "2024-11-22T09:37:23.183Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L8-W.webp",
          "thumbnailURL": null
        },
        "id": "6740508e1128c70026d04c2e"
      },
      {
        "image": {
          "id": "674050f3a0480a03f68c21f7",
          "publicURL": "/payload/viewMedia/L10-E.webp",
          "filename": "L10-E.webp",
          "mimeType": "image/webp",
          "filesize": 511714,
          "width": 3000,
          "height": 1525,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:37:55.789Z",
          "updatedAt": "2024-11-22T09:37:55.789Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L10-E.webp",
          "thumbnailURL": null
        },
        "id": "674050de1128c70026d04c2f"
      },
      {
        "image": {
          "id": "674050fda0480a03f68c220b",
          "publicURL": "/payload/viewMedia/L10-N.webp",
          "filename": "L10-N.webp",
          "mimeType": "image/webp",
          "filesize": 491074,
          "width": 3000,
          "height": 1532,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:38:05.877Z",
          "updatedAt": "2024-11-22T09:38:05.877Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L10-N.webp",
          "thumbnailURL": null
        },
        "id": "674050e01128c70026d04c30"
      },
      {
        "image": {
          "id": "67405108a0480a03f68c2223",
          "publicURL": "/payload/viewMedia/L10-NE.webp",
          "filename": "L10-NE.webp",
          "mimeType": "image/webp",
          "filesize": 525208,
          "width": 3000,
          "height": 1598,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:38:16.783Z",
          "updatedAt": "2024-11-22T09:38:16.783Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L10-NE.webp",
          "thumbnailURL": null
        },
        "id": "674050e11128c70026d04c31"
      },
      {
        "image": {
          "id": "67405110a0480a03f68c223b",
          "publicURL": "/payload/viewMedia/L10-NW.webp",
          "filename": "L10-NW.webp",
          "mimeType": "image/webp",
          "filesize": 434676,
          "width": 3000,
          "height": 1510,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:38:24.814Z",
          "updatedAt": "2024-11-22T09:38:24.814Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L10-NW.webp",
          "thumbnailURL": null
        },
        "id": "674050e31128c70026d04c32"
      },
      {
        "image": {
          "id": "67405119a0480a03f68c2253",
          "publicURL": "/payload/viewMedia/L10-S.webp",
          "filename": "L10-S.webp",
          "mimeType": "image/webp",
          "filesize": 423342,
          "width": 3000,
          "height": 1620,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:38:33.265Z",
          "updatedAt": "2024-11-22T09:38:33.265Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L10-S.webp",
          "thumbnailURL": null
        },
        "id": "674050e41128c70026d04c33"
      },
      {
        "image": {
          "id": "6740511fa0480a03f68c2267",
          "publicURL": "/payload/viewMedia/L10-SE.webp",
          "filename": "L10-SE.webp",
          "mimeType": "image/webp",
          "filesize": 456748,
          "width": 3000,
          "height": 1598,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:38:39.811Z",
          "updatedAt": "2024-11-22T09:38:39.811Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L10-SE.webp",
          "thumbnailURL": null
        },
        "id": "674050e51128c70026d04c34"
      },
      {
        "image": {
          "id": "6740513ba0480a03f68c227f",
          "publicURL": "/payload/viewMedia/L10-SW.webp",
          "filename": "L10-SW.webp",
          "mimeType": "image/webp",
          "filesize": 340426,
          "width": 3000,
          "height": 1569,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:39:07.135Z",
          "updatedAt": "2024-11-22T09:39:07.135Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L10-SW.webp",
          "thumbnailURL": null
        },
        "id": "674050e61128c70026d04c35"
      },
      {
        "image": {
          "id": "67405141a0480a03f68c2297",
          "publicURL": "/payload/viewMedia/L10-W.webp",
          "filename": "L10-W.webp",
          "mimeType": "image/webp",
          "filesize": 326704,
          "width": 2452,
          "height": 1688,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:39:13.811Z",
          "updatedAt": "2024-11-22T09:39:13.811Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L10-W.webp",
          "thumbnailURL": null
        },
        "id": "674050e81128c70026d04c36"
      },
      {
        "image": {
          "id": "674054c2a0480a03f68c22d3",
          "publicURL": "/payload/viewMedia/L12-E.webp",
          "filename": "L12-E.webp",
          "mimeType": "image/webp",
          "filesize": 467194,
          "width": 3000,
          "height": 1416,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:54:10.088Z",
          "updatedAt": "2024-11-22T09:54:10.088Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L12-E.webp",
          "thumbnailURL": null
        },
        "id": "674054ad1128c70026d04c37"
      },
      {
        "image": {
          "id": "674054cba0480a03f68c22e7",
          "publicURL": "/payload/viewMedia/L12-N.webp",
          "filename": "L12-N.webp",
          "mimeType": "image/webp",
          "filesize": 473052,
          "width": 2957,
          "height": 1688,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:54:19.899Z",
          "updatedAt": "2024-11-22T09:54:19.899Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L12-N.webp",
          "thumbnailURL": null
        },
        "id": "674054ae1128c70026d04c38"
      },
      {
        "image": {
          "id": "674054d4a0480a03f68c22ff",
          "publicURL": "/payload/viewMedia/L12-NE.webp",
          "filename": "L12-NE.webp",
          "mimeType": "image/webp",
          "filesize": 478860,
          "width": 3000,
          "height": 1444,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:54:28.330Z",
          "updatedAt": "2024-11-22T09:54:28.330Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L12-NE.webp",
          "thumbnailURL": null
        },
        "id": "674054af1128c70026d04c39"
      },
      {
        "image": {
          "id": "674054dda0480a03f68c2317",
          "publicURL": "/payload/viewMedia/L12-NW.webp",
          "filename": "L12-NW.webp",
          "mimeType": "image/webp",
          "filesize": 434942,
          "width": 3000,
          "height": 1382,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:54:37.802Z",
          "updatedAt": "2024-11-22T09:54:37.802Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L12-NW.webp",
          "thumbnailURL": null
        },
        "id": "674054b11128c70026d04c3a"
      },
      {
        "image": {
          "id": "674054e9a0480a03f68c232f",
          "publicURL": "/payload/viewMedia/L12-S.webp",
          "filename": "L12-S.webp",
          "mimeType": "image/webp",
          "filesize": 350768,
          "width": 3000,
          "height": 1494,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:54:49.087Z",
          "updatedAt": "2024-11-22T09:54:49.087Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L12-S.webp",
          "thumbnailURL": null
        },
        "id": "674054b21128c70026d04c3b"
      },
      {
        "image": {
          "id": "674054f6a0480a03f68c2347",
          "publicURL": "/payload/viewMedia/L12-SE.webp",
          "filename": "L12-SE.webp",
          "mimeType": "image/webp",
          "filesize": 461192,
          "width": 3000,
          "height": 1468,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:55:02.353Z",
          "updatedAt": "2024-11-22T09:55:02.353Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L12-SE.webp",
          "thumbnailURL": null
        },
        "id": "674054b31128c70026d04c3c"
      },
      {
        "image": {
          "id": "674054fea0480a03f68c235b",
          "publicURL": "/payload/viewMedia/L12-SW.webp",
          "filename": "L12-SW.webp",
          "mimeType": "image/webp",
          "filesize": 359134,
          "width": 3000,
          "height": 1598,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:55:10.614Z",
          "updatedAt": "2024-11-22T09:55:10.614Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L12-SW.webp",
          "thumbnailURL": null
        },
        "id": "674054b51128c70026d04c3d"
      },
      {
        "image": {
          "id": "67405506a0480a03f68c2373",
          "publicURL": "/payload/viewMedia/L12-W.webp",
          "filename": "L12-W.webp",
          "mimeType": "image/webp",
          "filesize": 352950,
          "width": 2748,
          "height": 1688,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:55:18.927Z",
          "updatedAt": "2024-11-22T09:55:18.927Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L12-W.webp",
          "thumbnailURL": null
        },
        "id": "674054b61128c70026d04c3e"
      },
      {
        "image": {
          "id": "67405549a0480a03f68c23b3",
          "publicURL": "/payload/viewMedia/L14-E.webp",
          "filename": "L14-E.webp",
          "mimeType": "image/webp",
          "filesize": 444288,
          "width": 3000,
          "height": 1586,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:56:25.304Z",
          "updatedAt": "2024-11-22T09:56:25.304Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L14-E.webp",
          "thumbnailURL": null
        },
        "id": "6740550b1128c70026d04c3f"
      },
      {
        "image": {
          "id": "67405553a0480a03f68c23c7",
          "publicURL": "/payload/viewMedia/L14-N.webp",
          "filename": "L14-N.webp",
          "mimeType": "image/webp",
          "filesize": 473052,
          "width": 2957,
          "height": 1688,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:56:35.198Z",
          "updatedAt": "2024-11-22T09:56:35.198Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L14-N.webp",
          "thumbnailURL": null
        },
        "id": "6740550d1128c70026d04c40"
      },
      {
        "image": {
          "id": "6740555fa0480a03f68c23df",
          "publicURL": "/payload/viewMedia/L14-NE.webp",
          "filename": "L14-NE.webp",
          "mimeType": "image/webp",
          "filesize": 453354,
          "width": 3000,
          "height": 1473,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:56:47.508Z",
          "updatedAt": "2024-11-22T09:56:47.508Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L14-NE.webp",
          "thumbnailURL": null
        },
        "id": "6740550e1128c70026d04c41"
      },
      {
        "image": {
          "id": "6740556ca0480a03f68c23f7",
          "publicURL": "/payload/viewMedia/L14-NW.webp",
          "filename": "L14-NW.webp",
          "mimeType": "image/webp",
          "filesize": 434942,
          "width": 3000,
          "height": 1382,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:57:00.015Z",
          "updatedAt": "2024-11-22T09:57:00.015Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L14-NW.webp",
          "thumbnailURL": null
        },
        "id": "674055101128c70026d04c42"
      },
      {
        "image": {
          "id": "67405574a0480a03f68c240f",
          "publicURL": "/payload/viewMedia/L14-S.webp",
          "filename": "L14-S.webp",
          "mimeType": "image/webp",
          "filesize": 350768,
          "width": 3000,
          "height": 1494,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:57:08.213Z",
          "updatedAt": "2024-11-22T09:57:08.213Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L14-S.webp",
          "thumbnailURL": null
        },
        "id": "674055121128c70026d04c43"
      },
      {
        "image": {
          "id": "6740557aa0480a03f68c2427",
          "publicURL": "/payload/viewMedia/L14-SE.webp",
          "filename": "L14-SE.webp",
          "mimeType": "image/webp",
          "filesize": 397836,
          "width": 3000,
          "height": 1509,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:57:14.898Z",
          "updatedAt": "2024-11-22T09:57:14.898Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L14-SE.webp",
          "thumbnailURL": null
        },
        "id": "674055131128c70026d04c44"
      },
      {
        "image": {
          "id": "67405581a0480a03f68c243b",
          "publicURL": "/payload/viewMedia/L14-SW.webp",
          "filename": "L14-SW.webp",
          "mimeType": "image/webp",
          "filesize": 359134,
          "width": 3000,
          "height": 1598,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:57:21.794Z",
          "updatedAt": "2024-11-22T09:57:21.794Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L14-SW.webp",
          "thumbnailURL": null
        },
        "id": "674055151128c70026d04c45"
      },
      {
        "image": {
          "id": "67405589a0480a03f68c2453",
          "publicURL": "/payload/viewMedia/L14-W.webp",
          "filename": "L14-W.webp",
          "mimeType": "image/webp",
          "filesize": 352950,
          "width": 2748,
          "height": 1688,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-11-22T09:57:29.680Z",
          "updatedAt": "2024-11-22T09:57:29.680Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/L14-W.webp",
          "thumbnailURL": null
        },
        "id": "674055161128c70026d04c46"
      }
    ]
  },
  "detailsData": {
    "relationTo": "unitDetails",
    "name": "Unit Details - West Ham"
  },
  "navRoutes": [
    {
      "path": "orbits",
      "feature": "orbit3d",
      "layout": "fullscreen",
      "label": "Destination",
      "include": "true"
    },
    {
      "path": "map",
      "feature": "googleMaps",
      "layout": "fullscreen",
      "label": "Transport & Lifestyle",
      "include": "true"
    },
    {
      "path": "residences",
      "feature": "residencesType1",
      "layout": "fullscreen",
      "label": "Apartment Finder",
      "include": "true"
    },
    {
      "path": "gallery",
      "feature": "masonrygallery",
      "layout": "fullscreen",
      "label": "Gallery",
      "include": "true"
    },
    {
      "path": "favourites",
      "feature": "favourites",
      "layout": "fullscreen",
      "label": "Favourites",
      "include": "true"
    },
    {
      "path": "explorer",
      "feature": null,
      "layout": "fullscreen",
      "label": "Explorer",
      "include": "false"
    },
    {
      "path": "development",
      "feature": "development",
      "layout": "fullscreen",
      "label": "Development",
      "include": "false"
    },
    {
      "path": "sharedOwnership",
      "feature": "sharedOwnership",
      "layout": "fullscreen",
      "label": "Shared Ownership",
      "include": "true"
    },
    {
      "path": "about",
      "feature": "about",
      "layout": "fullscreen",
      "label": "Peabody & Partners",
      "include": "true"
    },
    {
      "path": "info1",
      "feature": "info1",
      "layout": "fullscreen",
      "label": "Info1 Page",
      "include": "false"
    }
  ],
  "allRoutes": [
    {
      "path": "/",
      "feature": "fullscreen-splash",
      "layout": "fullscreen",
      "include": "true"
    },
    {
      "path": "orbits",
      "feature": "orbit3d",
      "layout": "fullscreen",
      "label": "Destination",
      "include": "true"
    },
    {
      "path": "map",
      "feature": "googleMaps",
      "layout": "fullscreen",
      "label": "Transport & Lifestyle",
      "include": "true"
    },
    {
      "path": "residences",
      "feature": "residencesType1",
      "layout": "fullscreen",
      "label": "Apartment Finder",
      "include": "true"
    },
    {
      "path": "gallery",
      "feature": "masonrygallery",
      "layout": "fullscreen",
      "label": "Gallery",
      "include": "true"
    },
    {
      "path": "favourites",
      "feature": "favourites",
      "layout": "fullscreen",
      "label": "Favourites",
      "include": "true"
    },
    {
      "path": "explorer",
      "feature": null,
      "layout": "fullscreen",
      "label": "Explorer",
      "include": "false"
    },
    {
      "path": "development",
      "feature": "development",
      "layout": "fullscreen",
      "label": "Development",
      "include": "false"
    },
    {
      "path": "sharedOwnership",
      "feature": "sharedOwnership",
      "layout": "fullscreen",
      "label": "Shared Ownership",
      "include": "true"
    },
    {
      "path": "about",
      "feature": "about",
      "layout": "fullscreen",
      "label": "Peabody & Partners",
      "include": "true"
    },
    {
      "path": "info1",
      "feature": "info1",
      "layout": "fullscreen",
      "label": "Info1 Page",
      "include": "false"
    }
  ]
};
  