import { useOrbit3DStore } from "@common/features/Orbit3D/state/orbit3DStore"
import { Billboard, Html, useGLTF } from "@react-three/drei"
import React from "react"
import styled from "styled-components"
import { Vector3 } from "three"

function Labels() {
  const touchPoints = [
    {
      title: "Hero",
      value: 12,
      positions: { x: 10, y: 3, z: -13 },
      image: "/images/orbit/space_2.webp",
    },
    {
      title: "Amenities",
      value: 12,
      positions: { x: 4, y: 3, z: -9 },
      image: "/images/orbit/space_1.webp",
    },
    {
      title: "Park",
      value: 12,
      positions: { x: 0, y: 3, z: -9 },
      image: "/images/orbit/space_4.webp",
    },
    {
      title: "Streetscape",
      value: 12,
      positions: { x: 8, y: 3, z: 0 },
      image: "/images/orbit/space_3.webp",
    },
    {
      title: "Hero",
      value: 12,
      positions: { x: 3, y: 3, z: 5 },
      image: "/images/orbit/space_5.webp",
    },
  ]

  const cityLables = [
    { title: "London Bridge", position: { x: -250, y: 45, z: 300 } },
    { title: "Canary Wharf", position: { x: -210, y: 60, z: 150 } },
    { title: "Stratford", position: { x: -10, y: 30, z: -200 } },
    { title: "Hackney", position: { x: -300, y: 40, z: -300 } },
  ]

  const { nodes: model } = useGLTF(
    "/assets/models/vivid_reality_logo_3d_model/scene.glb",
  ) as any

  const setActiveLabel = useOrbit3DStore((s) => s.setActiveLabel)
  const activeLabel = useOrbit3DStore((s) => s.activeLabel)

  const handleClickTouchPoint = (data) => {
    setActiveLabel(data)
  }

  return (
    <group>
      {touchPoints?.map((data, index) => {
        return (
          <TouchPoint
            handleClickTouchPoint={handleClickTouchPoint}
            data={data}
            model={model}
            key={index}
            index={index}
          />
        )
      })}

      {activeLabel === null &&
        cityLables?.map((data, index) => {
          return (
            <CityLabels
              key={`${data.position.x}-${data.position.y}-${data.position.z}`}
              position={data.position}
              title={data.title}
            />
          )
        })}
    </group>
  )
}

const CityLabels = ({ title, position }) => {
  return (
    <group position={new Vector3(position.x, position.y, position.z)}>
      <Billboard>
        <mesh position={[0, position.y * -0.5 - 7, 0]}>
          <boxGeometry args={[0.1, position.y, 0.1]} />
          <meshBasicMaterial color="#4F5C6B" />
        </mesh>
        <Html center={true}>
          <TitleWr textAlign="left" flexAlign={"flex-start"}>
            <div className="bg" />
            <div
              style={{
                zIndex: 1,
                display: "flex",
                alignItems: "center",
                gap: "0.5em",
              }}
            >
              <div className="icon">
                <span />
              </div>
              <div>
                <h3>{title}</h3>
              </div>
            </div>
          </TitleWr>
        </Html>
      </Billboard>
    </group>
  )
}

const TitleWr = styled.div<{
  flexAlign: string
  textAlign: string
}>`
  display: flex;
  border: 1px solid var(--accent);
  padding: 0.75rem;
  align-items: ${(p) => p.flexAlign};
  font-weight: 800;
  margin-left: auto;
  gap: 1rem;
  white-space: wrap;
  position: relative;

  .icon {
    width: 1.9em;
    height: 1.9em;
    transform: translateY(-0.2em);
    background-color: var(--accent);

    & span {
      display: inline-block;
      background-color: var(--primary);
      width: 100%;
      height: 100%;
      clip-path: polygon(0% 0%, 99% 50%, 0% 100%);
    }
  }

  .bg {
    background-color: var(--primary);
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
  }

  h3 {
    color: var(--green);
    font-size: 1.5em;
    text-align: ${(p) => p.textAlign};
    text-transform: uppercase;
    z-index: 1;
    font-weight: 400;
    line-height: 0.2em;
    white-space: nowrap;
  }
`

type TouchPointProps = {
  data: any
  model: any
  index: number
  handleClickTouchPoint: (data: any) => void
}

export const TouchPoint = (props: TouchPointProps) => {
  const { data, model, handleClickTouchPoint } = props
  const { positions } = data
  const materials = React.useRef<any[]>([])

  return (
    <group
      scale={12}
      onClick={() => {
        handleClickTouchPoint({ ...data })
      }}
      position={new Vector3(positions.x, positions.y, positions.z)}
    >
      <Billboard lockX={true} lockY={false} lockZ={false}>
        <mesh
          scale={0.4}
          onPointerOver={() => (document.body.style.cursor = "pointer")}
          onPointerOut={() => (document.body.style.cursor = "auto")}
          geometry={model["mesh_0"].geometry}
        >
          <meshMatcapMaterial
            ref={(el) => (materials.current[0] = el)}
            opacity={1}
            transparent
            toneMapped={false}
            color={"#25466A"}
          />
        </mesh>
        <mesh scale={0.4} geometry={model["mesh_1"].geometry}>
          <meshMatcapMaterial
            ref={(el) => (materials.current[1] = el)}
            opacity={1}
            transparent
            toneMapped={false}
            color={"#84BFC2"}
          />
        </mesh>
      </Billboard>
    </group>
  )
}

export default Labels
