import styled from "styled-components";
import { useOrbit3DStore } from "../state/orbit3DStore";
import { useEffect, useRef } from "react";
import gsap from "gsap";

const LabelUI = () => {
  const activeLabel = useOrbit3DStore((s) => s.activeLabel);
  // console.log(activeLabel)
  const setActiveLabel = useOrbit3DStore((s) => s.setActiveLabel);
  const labelRef = useRef(null);

  useEffect(() => {
    if (activeLabel) {
      gsap.to(labelRef.current, {
        autoAlpha: 1,
        delay: 0.3,
        duration: 0.4,
      });
    } else {
      gsap.to(labelRef.current, {
        autoAlpha: 0,
        duration: 0.4,
      });
    }
  }, [activeLabel]);

  return (
    <LabelUIWrapper
      ref={labelRef}
      onClick={() => {
        setActiveLabel(null);
      }}
      style={{
        backgroundImage: `url(${activeLabel?.image})`,
      }}
    >
      {/* <img src={activeLabel?.image} /> */}

      {/* <img src="/payload/galleryMedia/c5_SO1C_HERO_opt7_(4).jpg" /> */}
      {/* {activeLabel.title} */}
      {/* <h3>{activeLabel?.title}</h3> */}
    </LabelUIWrapper>
  );
};

export default LabelUI;

const LabelUIWrapper = styled.div`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  background-origin: content-box;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;

  background-color: var(--primary);

  padding: 2em;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  h3 {
    color: white;
    bottom: 4rem;
    left: 4rem;
    position: absolute;
  }
`;
