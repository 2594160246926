import { useResi3DStore } from "@common/features/Residences3D/state/resi3DStore"
import React, { useCallback } from "react"
import styled from "styled-components/macro"
import { HeartFilledIcon, HeartIcon } from "@radix-ui/react-icons"
import { useFavoritesStore } from "@state/favouritesStore"

const TableRow = React.memo(
  ({
    cells,
    row,
    rowIndex,
    isSelected,
    onClick,
    columns,
    onPointerOver,
    onPointerOut,
  }: any) => {
    const hoveredUnit = useResi3DStore((s) => s.hoveredUnit)
    const hovered = hoveredUnit[0] === row.unitName
    const { addToFavorites, isFavorite } = useFavoritesStore()

    const handleClick = useCallback(() => {
      if (onClick) onClick()
    }, [onClick])

    const handlePointerOver = useCallback(() => {
      if (onPointerOver) onPointerOver()
    }, [onPointerOver])

    const handlePointerOut = useCallback(() => {
      if (onPointerOut) onPointerOut()
    }, [onPointerOut])
    return (
      <TableRowContainer
        hovered={hovered}
        onPointerOver={handlePointerOver}
        onPointerOut={handlePointerOut}
        // className={`grid-table-row}`}
        className={`grid-table-row ${isSelected ? "selected" : ""}`}
        onClick={handleClick}
        columns={columns}
        id={row["unitName"]}
      >
        {cells.map((cell, index) => (
          <TableCell key={index}>
            {typeof cell === "object" && cell?.render !== undefined
              ? cell.render(row, rowIndex)
              : processCell(row, cell)}
          </TableCell>
        ))}
        <TableCell>
          {isFavorite(row) ? (
            <HeartFilledIcon
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                addToFavorites(row)
              }}
            />
          ) : (
            <HeartIcon
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                addToFavorites(row)
              }}
            />
          )}
        </TableCell>
      </TableRowContainer>
    )
  },
  (prevProps, nextProps) => {
    return prevProps.isSelected === nextProps.isSelected
  },
)

export { TableRow }

const TableRowContainer = styled.div<{ columns: number; hovered: boolean }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns + 1}, 1fr);
  align-items: center;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  font-size: 1rem;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }

  background-color: 00000000;
  border: 1px solid white;
  border-radius: 2rem;
  padding: 0.25rem 1rem;
  color: white;

  &.selected {
    opacity: 1;

    background-color: var(--secondary);
    color: var(--primary);
    border: 1px solid var(--secondary);
  }

  &:active {
    transform: scale(0.99);
    transition: 0.3s;
    opacity: 0.5;
  }
`

const TableCell = styled.div`
  padding: 1rem 0;
  text-align: center;
  white-space: nowrap;
`

export const poundFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "GBP",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

function processCell(row, cell) {
  switch (cell) {
    case "unitName":
      let unit = row[cell]
      unit = unit.split("-")
      return Number(`${unit[1]}${unit[2]}`)
    case "price":
      return poundFormatter.format(row[cell])
    case "unitType":
      let type = row[cell]
      type = type.split(" ").filter((txt) => txt !== "TYPE")
      return type.join("").replace("1-STUDIO", "1-ST")
    case "aspect":
      let aspect = row[cell]
      aspect = aspect.replaceAll("c", "")
      return aspect
    case "beds":
      return row[cell] === 0 ? "Studio" : row[cell]
    case "baths":
      return row[cell] === 0 ? 1 : row[cell]
    default:
      return row[cell]
  }
}
