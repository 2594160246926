import styled from "styled-components/macro"
import { useStore } from "@state/store"
import { useEffect } from "react"
import gsap from "gsap"
import TextButton from "@common/ui/TextButton"

const FloorplanImage = ({ unit, ...props }) => {
  // const imageOverlay = useStore((s) => s.imageOverlay)
  // const syncState = useStore((s) => s.syncState)
  const contentControls = useStore((s) => s.contentControls)
  const aspect = unit?.[0]?.aspect
  const baseUrl = "/payload/floorPlanMedia/"
  const image = unit?.[0]?.unitType?.replaceAll("/","")
  useEffect(() => {
    gsap.to("#floorplan", {
      autoAlpha: contentControls?.index !== 1 ? 1 : 0,
      duration: 0.6,
      ease: "power3.inOut",
    })
  }, [contentControls])
  return (
    <Wrapper id="floorplan" {...props}>
      {unit && <Floorplan src={`${baseUrl}${image}.webp`} alt={unit[0]?.unitType}/>}
      {/* {unit && (
        <Floorplan
          src="/payload/floorPlanMedia/1.png"
          alt={unit[0]?.floorplan}
        />
      )} */}
      {unit && (
        <svg
          width="141"
          height="141"
          viewBox="0 0 141 141"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="floorplan-compass"
        >
          <path
            d="M58.6737 59.9453L65.9993 81.1082C66.0683 81.3075 66.1985 81.4799 66.3713 81.6007C66.5442 81.7215 66.7508 81.7846 66.9616 81.7809C67.1725 81.7773 67.3768 81.707 67.5453 81.5803C67.7139 81.4535 67.838 81.2767 67.9001 81.0752L70.8445 71.5057C70.8925 71.3498 70.9778 71.208 71.0932 71.0927C71.2085 70.9774 71.3503 70.892 71.5062 70.844L81.0757 67.8996C81.2772 67.8376 81.454 67.7134 81.5808 67.5449C81.7075 67.3763 81.7778 67.172 81.7814 66.9612C81.7851 66.7503 81.722 66.5437 81.6012 66.3709C81.4803 66.198 81.308 66.0678 81.1087 65.9988L59.9458 58.6732C59.7684 58.6118 59.5774 58.6015 59.3945 58.6436C59.2116 58.6857 59.0442 58.7784 58.9115 58.9111C58.7788 59.0438 58.6862 59.2111 58.6441 59.394C58.602 59.5769 58.6123 59.7679 58.6737 59.9453Z"
            fill="#26303B"
            stroke="#26303B"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="rotate(45, 70.5, 70.5)"
          />
          {/* {north} */}
          <path
            d="M39.5598 7.00965L40.9498 6.41965L47.9798 11.0296H47.9998L45.2798 4.58965L46.3398 4.13965L49.6498 11.9696L48.2998 12.5396L41.2198 7.94965H41.1998L43.9198 14.3896L42.8598 14.8396L39.5498 7.00965H39.5598Z"
            fill="#26303B"
            fillOpacity={aspect?.includes("N") ? 1 : 0.4}
          />
          <path
            d="M59.7899 9.6498C59.1499 9.7598 58.5399 9.7498 57.9699 9.6198C57.3999 9.4898 56.8799 9.2598 56.4299 8.9298C55.9799 8.5998 55.5999 8.1898 55.2899 7.6898C54.9799 7.1898 54.7699 6.6298 54.6699 6.0098C54.5599 5.3898 54.5699 4.7898 54.6999 4.2198C54.8199 3.6498 55.0399 3.1298 55.3599 2.6698C55.6699 2.2098 56.0799 1.8198 56.5799 1.4998C57.0799 1.1798 57.6499 0.969805 58.2799 0.859805C58.9099 0.749805 59.5299 0.759805 60.0999 0.889805C60.6699 1.0198 61.1899 1.2498 61.6399 1.5798C62.0899 1.9098 62.4699 2.3198 62.7799 2.8198C63.0899 3.3198 63.2999 3.8798 63.3999 4.4998C63.5099 5.1198 63.4999 5.7198 63.3699 6.2898C63.2499 6.8598 63.0299 7.3798 62.7099 7.8398C62.3999 8.2998 61.9899 8.6898 61.4899 9.0098C60.9899 9.3298 60.4199 9.5398 59.7899 9.6498ZM59.5999 8.5898C60.0799 8.5098 60.4999 8.3398 60.8499 8.0998C61.2099 7.85981 61.4999 7.5598 61.7199 7.2098C61.9399 6.8598 62.0999 6.4698 62.1799 6.0398C62.2599 5.6098 62.2599 5.1598 62.1799 4.7098C62.0999 4.2498 61.9499 3.8298 61.7299 3.4498C61.5099 3.0698 61.2399 2.7498 60.9099 2.4998C60.5799 2.2498 60.2099 2.0698 59.7899 1.9598C59.3699 1.8498 58.9199 1.8398 58.4399 1.9198C57.9599 1.9998 57.5399 2.1698 57.1899 2.4098C56.8299 2.6498 56.5399 2.9498 56.3199 3.2998C56.0999 3.6498 55.9399 4.0398 55.8599 4.4698C55.7799 4.8998 55.7799 5.3498 55.8599 5.7998C55.9399 6.2598 56.0899 6.6798 56.3099 7.0598C56.5299 7.4398 56.7999 7.7598 57.1299 8.0098C57.4599 8.2598 57.8299 8.4398 58.2499 8.5498C58.6699 8.6598 59.1199 8.6698 59.5999 8.5898Z"
            fill="#26303B"
            fillOpacity={aspect?.includes("N") ? 1 : 0.4}
          />
          <path
            d="M70.4999 0L73.4899 0.12C74.0299 0.14 74.4799 0.23 74.8199 0.39C75.1699 0.55 75.4399 0.75 75.6299 0.99C75.8199 1.23 75.9599 1.49 76.0299 1.77C76.0999 2.06 76.1299 2.33 76.1199 2.6C76.1099 2.87 76.0499 3.13 75.9499 3.38C75.8399 3.63 75.6999 3.85 75.5099 4.05C75.3199 4.25 75.0899 4.41 74.8299 4.54C74.5599 4.67 74.2699 4.74 73.9499 4.76L76.2099 8.73L74.7699 8.67L72.7599 4.84L71.4799 4.79L71.3299 8.53L70.1799 8.49L70.5099 0L70.4999 0ZM71.4999 3.79L73.0099 3.85C73.2299 3.85 73.4599 3.85 73.6799 3.82C73.8999 3.79 74.0999 3.73 74.2699 3.64C74.4499 3.55 74.5899 3.42 74.6999 3.24C74.8099 3.06 74.8699 2.83 74.8799 2.54C74.8899 2.25 74.8499 2.02 74.7499 1.83C74.6499 1.64 74.5199 1.5 74.3499 1.4C74.1799 1.3 73.9899 1.22 73.7699 1.18C73.5499 1.14 73.3299 1.11 73.1099 1.1L71.5999 1.04L71.4899 3.77L71.4999 3.79Z"
            fill="#26303B"
            fillOpacity={aspect?.includes("N") ? 1 : 0.4}
          />
          <path
            d="M85.0401 2.6998L82.3701 2.0998L82.6101 1.0498L89.0701 2.5098L88.8301 3.5598L86.1601 2.9598L84.5301 10.1898L83.4101 9.9398L85.0401 2.7098V2.6998Z"
            fill="#26303B"
            fillOpacity={aspect?.includes("N") ? 1 : 0.4}
          />
          <path
            d="M96.02 4.82031L97.07 5.29031L95.62 8.51031L99.64 10.3203L101.09 7.10031L102.14 7.57031L98.66 15.3203L97.61 14.8503L99.2 11.3003L95.18 9.49031L93.59 13.0403L92.54 12.5703L96.02 4.82031Z"
            fill="#26303B"
            fillOpacity={aspect?.includes("N") ? 1 : 0.4}
          />
          {/* {south} */}
          <path
            d="M98.53 127.29C98.21 127.09 97.87 126.99 97.52 126.99C97.16 126.99 96.83 127.06 96.52 127.21C96.35 127.29 96.18 127.4 96.02 127.54C95.86 127.68 95.73 127.84 95.64 128.01C95.54 128.19 95.48 128.38 95.46 128.58C95.44 128.78 95.48 128.99 95.57 129.2C95.71 129.5 95.9 129.68 96.17 129.75C96.44 129.82 96.72 129.84 97.05 129.82C97.38 129.8 97.73 129.76 98.11 129.71C98.49 129.66 98.85 129.65 99.21 129.7C99.57 129.75 99.91 129.87 100.24 130.08C100.56 130.29 100.84 130.64 101.07 131.14C101.17 131.37 101.23 131.63 101.25 131.93C101.27 132.23 101.22 132.53 101.11 132.84C101 133.15 100.81 133.45 100.54 133.74C100.27 134.03 99.91 134.29 99.44 134.5C99.02 134.69 98.59 134.82 98.15 134.88C97.71 134.94 97.26 134.86 96.81 134.64L97.31 133.48C97.53 133.62 97.8 133.7 98.09 133.72C98.39 133.73 98.69 133.67 98.99 133.53C99.28 133.4 99.51 133.25 99.66 133.08C99.82 132.91 99.93 132.75 99.99 132.57C100.05 132.4 100.07 132.23 100.06 132.07C100.04 131.91 100.01 131.77 99.96 131.66C99.81 131.33 99.6 131.12 99.33 131.04C99.06 130.95 98.76 130.92 98.43 130.94C98.1 130.96 97.75 131 97.38 131.06C97.01 131.12 96.65 131.14 96.3 131.11C95.95 131.08 95.62 130.98 95.31 130.8C95 130.62 94.74 130.3 94.52 129.83C94.35 129.46 94.27 129.09 94.27 128.73C94.27 128.37 94.35 128.03 94.5 127.71C94.65 127.4 94.86 127.11 95.14 126.85C95.42 126.59 95.74 126.38 96.11 126.21C96.61 125.98 97.11 125.86 97.64 125.83C98.16 125.8 98.64 125.91 99.07 126.15L98.55 127.29H98.53Z"
            fill="#26303B"
            fillOpacity={aspect?.includes("S") ? 1 : 0.4}
          />
          <path
            d="M83.9001 130.35C84.5301 130.21 85.1401 130.19 85.7201 130.29C86.3001 130.39 86.8201 130.6 87.2901 130.9C87.7601 131.2 88.1601 131.6 88.4901 132.08C88.8201 132.56 89.0601 133.11 89.2001 133.73C89.3401 134.35 89.3601 134.94 89.2701 135.52C89.1801 136.1 88.9801 136.63 88.6901 137.1C88.4001 137.58 88.0101 137.98 87.5301 138.33C87.0501 138.67 86.4901 138.91 85.8601 139.06C85.2301 139.21 84.6201 139.22 84.0401 139.12C83.4601 139.02 82.9401 138.81 82.4701 138.51C82.0001 138.21 81.6001 137.81 81.2701 137.33C80.9401 136.85 80.7001 136.3 80.5601 135.68C80.4201 135.06 80.4001 134.47 80.4901 133.89C80.5901 133.31 80.7801 132.78 81.0701 132.31C81.3601 131.83 81.7501 131.43 82.2301 131.08C82.7101 130.74 83.2701 130.5 83.9001 130.35ZM84.1401 131.4C83.6601 131.51 83.2501 131.69 82.9101 131.95C82.5701 132.21 82.2901 132.52 82.0901 132.88C81.8801 133.24 81.7501 133.63 81.6901 134.07C81.6301 134.51 81.6501 134.95 81.7601 135.4C81.8601 135.85 82.0301 136.26 82.2701 136.63C82.5101 137 82.8001 137.3 83.1401 137.53C83.4801 137.76 83.8601 137.93 84.2801 138.02C84.7001 138.11 85.1501 138.1 85.6301 137.99C86.1101 137.88 86.5101 137.7 86.8601 137.44C87.2101 137.18 87.4801 136.87 87.6801 136.51C87.8901 136.15 88.0201 135.76 88.0801 135.32C88.1401 134.88 88.1201 134.44 88.0101 133.99C87.9101 133.54 87.7401 133.13 87.5001 132.76C87.2601 132.39 86.9701 132.09 86.6301 131.85C86.2901 131.61 85.9101 131.45 85.4901 131.36C85.0701 131.27 84.6201 131.28 84.1401 131.39V131.4Z"
            fill="#26303B"
            fillOpacity={aspect?.includes("S") ? 1 : 0.4}
          />
          <path
            d="M72.78 140.53L72.7 135.32C72.7 135.06 72.66 134.78 72.58 134.5C72.5 134.22 72.38 133.96 72.21 133.72C72.04 133.48 71.81 133.29 71.54 133.14C71.27 132.99 70.93 132.92 70.53 132.93C70.13 132.93 69.79 133.02 69.53 133.17C69.26 133.33 69.04 133.53 68.88 133.77C68.72 134.01 68.6 134.28 68.53 134.56C68.46 134.84 68.43 135.12 68.44 135.38L68.52 140.59L67.37 140.61L67.28 135.22C67.28 134.72 67.35 134.27 67.51 133.87C67.67 133.46 67.9 133.11 68.19 132.81C68.48 132.51 68.83 132.28 69.22 132.11C69.62 131.94 70.05 131.86 70.51 131.85C70.97 131.85 71.41 131.92 71.81 132.07C72.21 132.22 72.56 132.45 72.86 132.74C73.16 133.03 73.4 133.38 73.57 133.78C73.74 134.18 73.84 134.63 73.84 135.13L73.93 140.52L72.78 140.54V140.53Z"
            fill="#26303B"
            fillOpacity={aspect?.includes("S") ? 1 : 0.4}
          />
          <path
            d="M57.6201 138.33L60.3101 138.85L60.1001 139.91L53.6001 138.65L53.8101 137.59L56.5001 138.11L57.9101 130.83L59.0401 131.05L57.6301 138.33H57.6201Z"
            fill="#26303B"
            fillOpacity={aspect?.includes("S") ? 1 : 0.4}
          />
          <path
            d="M46.5901 136.55L45.5301 136.11L46.8801 132.85L42.8101 131.17L41.4601 134.43L40.3901 133.99L43.6301 126.14L44.6901 126.58L43.2101 130.17L47.2801 131.85L48.7601 128.26L49.8201 128.7L46.5801 136.55H46.5901Z"
            fill="#26303B"
            fillOpacity={aspect?.includes("S") ? 1 : 0.4}
          />
          {/* {EAST} */}
          <path
            d="M136.79 47.3899L138.38 52.6399L137.35 52.9499L136.09 48.7999L133.67 49.5399L134.84 53.3999L133.81 53.7099L132.64 49.8499L130.03 50.6399L131.35 54.9899L130.32 55.2999L128.66 49.8499L136.79 47.3799V47.3899Z"
            fill="#26303B"
            fillOpacity={aspect?.includes("E") ? 1 : 0.4}
          />
          <path
            d="M140.2 63.0804L140.3 64.1204L132.19 68.5504L132.06 67.2104L134.07 66.1604L133.69 62.1304L131.52 61.4904L131.39 60.1504L140.2 63.0704V63.0804ZM135.03 65.6504L138.76 63.7004V63.6804L134.72 62.4504L135.03 65.6504Z"
            fill="#26303B"
            fillOpacity={aspect?.includes("E") ? 1 : 0.4}
          />
          <path
            d="M133.74 74.5202C133.41 74.7002 133.15 74.9402 132.98 75.2502C132.8 75.5602 132.69 75.8802 132.66 76.2302C132.64 76.4202 132.66 76.6202 132.69 76.8202C132.73 77.0202 132.8 77.2102 132.91 77.3902C133.01 77.5602 133.15 77.7102 133.31 77.8302C133.47 77.9502 133.67 78.0202 133.9 78.0402C134.23 78.0702 134.48 77.9902 134.68 77.8002C134.88 77.6102 135.04 77.3702 135.18 77.0702C135.32 76.7702 135.47 76.4502 135.61 76.1002C135.75 75.7502 135.93 75.4302 136.15 75.1402C136.37 74.8502 136.65 74.6202 136.99 74.4402C137.33 74.2602 137.78 74.2002 138.33 74.2602C138.58 74.2802 138.83 74.3602 139.1 74.5002C139.37 74.6302 139.61 74.8302 139.82 75.0802C140.03 75.3302 140.19 75.6502 140.31 76.0302C140.43 76.4102 140.47 76.8502 140.42 77.3602C140.38 77.8202 140.27 78.2602 140.1 78.6702C139.93 79.0802 139.64 79.4202 139.22 79.7102L138.46 78.7002C138.7 78.5802 138.9 78.3902 139.06 78.1402C139.22 77.8902 139.32 77.6002 139.35 77.2602C139.38 76.9402 139.36 76.6702 139.3 76.4502C139.24 76.2302 139.15 76.0502 139.03 75.9102C138.91 75.7702 138.78 75.6702 138.63 75.6002C138.48 75.5302 138.35 75.4902 138.22 75.4802C137.86 75.4502 137.58 75.5202 137.37 75.7102C137.16 75.9002 136.98 76.1402 136.83 76.4402C136.68 76.7402 136.54 77.0602 136.41 77.4102C136.28 77.7602 136.11 78.0802 135.91 78.3702C135.71 78.6602 135.45 78.8902 135.14 79.0702C134.83 79.2502 134.42 79.3102 133.91 79.2602C133.5 79.2202 133.14 79.1102 132.83 78.9202C132.52 78.7302 132.26 78.5002 132.07 78.2102C131.87 77.9302 131.73 77.6002 131.65 77.2302C131.57 76.8602 131.54 76.4702 131.58 76.0702C131.63 75.5302 131.78 75.0202 132.02 74.5602C132.26 74.0902 132.59 73.7402 133.02 73.4902L133.74 74.5102V74.5202Z"
            fill="#26303B"
            fillOpacity={aspect?.includes("E") ? 1 : 0.4}
          />
          <path
            d="M136.98 88.8204L137.73 86.1904L138.77 86.4904L136.95 92.8604L135.91 92.5604L136.66 89.9304L129.53 87.8904L129.85 86.7804L136.98 88.8204Z"
            fill="#26303B"
            fillOpacity={aspect?.includes("E") ? 1 : 0.4}
          />
          {/* WEST */}

          <path
            d="M4.50977 95.6904L4.16977 94.5404L10.1598 90.8204V90.8004H3.06977L2.69977 89.5303L8.64977 85.7003V85.6804L1.59977 85.7904L1.25977 84.6404L10.1098 84.6104L10.4598 85.7803L4.37977 89.7104V89.7304H11.6198L11.9698 90.9004L4.51977 95.6804L4.50977 95.6904Z"
            fill="#26303B"
            fillOpacity={aspect?.includes("W") ? 1 : 0.4}
          />
          <path
            d="M0.34 77.3198L0 71.8498L1.08 71.7798L1.35 76.0998L3.88 75.9398L3.63 71.9198L4.71 71.8498L4.96 75.8699L7.68 75.6998L7.4 71.1599L8.48 71.0898L8.84 76.7798L0.36 77.3098L0.34 77.3198Z"
            fill="#26303B"
            fillOpacity={aspect?.includes("W") ? 1 : 0.4}
          />
          <path
            d="M6.98994 64.5695C7.31994 64.3995 7.57994 64.1595 7.76994 63.8595C7.95994 63.5595 8.06994 63.2295 8.10994 62.8895C8.12994 62.6995 8.12994 62.4995 8.08994 62.2995C8.04994 62.0895 7.98994 61.8995 7.88994 61.7295C7.78994 61.5495 7.65994 61.3995 7.49994 61.2795C7.33994 61.1595 7.13994 61.0795 6.90994 61.0495C6.57994 61.0095 6.31994 61.0795 6.12994 61.2695C5.92994 61.4595 5.75994 61.6895 5.60994 61.9895C5.45994 62.2795 5.30994 62.5995 5.15994 62.9495C5.00994 63.2995 4.81994 63.6095 4.58994 63.8995C4.35994 64.1895 4.07994 64.4095 3.72994 64.5795C3.37994 64.7495 2.93994 64.7995 2.38994 64.7295C2.13994 64.6995 1.88994 64.6095 1.61994 64.4695C1.34994 64.3295 1.11994 64.1295 0.919941 63.8695C0.719941 63.6095 0.559941 63.2895 0.449941 62.9095C0.339941 62.5295 0.309941 62.0895 0.379941 61.5795C0.439941 61.1195 0.549941 60.6895 0.729941 60.2795C0.909941 59.8695 1.20994 59.5395 1.63994 59.2695L2.36994 60.2995C2.12994 60.4195 1.92994 60.5995 1.75994 60.8395C1.58994 61.0895 1.48994 61.3795 1.44994 61.7095C1.40994 62.0295 1.41994 62.2995 1.47994 62.5195C1.53994 62.7395 1.61994 62.9195 1.73994 63.0695C1.85994 63.2095 1.98994 63.3195 2.12994 63.3895C2.26994 63.4595 2.40994 63.4995 2.53994 63.5195C2.89994 63.5595 3.17994 63.4895 3.39994 63.3095C3.60994 63.1295 3.79994 62.8895 3.95994 62.5995C4.11994 62.3095 4.25994 61.9895 4.39994 61.6395C4.53994 61.2895 4.70994 60.9695 4.91994 60.6895C5.12994 60.4095 5.38994 60.1795 5.69994 60.0095C6.00994 59.8395 6.42994 59.7795 6.92994 59.8395C7.33994 59.8895 7.68994 60.0095 7.99994 60.2095C8.30994 60.3995 8.55994 60.6495 8.74994 60.9395C8.93994 61.2295 9.06994 61.5595 9.14994 61.9295C9.22994 62.2995 9.23994 62.6895 9.18994 63.0995C9.11994 63.6395 8.96994 64.1395 8.71994 64.5995C8.46994 65.0595 8.12994 65.4095 7.69994 65.6495L6.99994 64.6095L6.98994 64.5695Z"
            fill="#26303B"
            fillOpacity={aspect?.includes("W") ? 1 : 0.4}
          />
          <path
            d="M4.09023 50.1902L3.27023 52.8002L2.24023 52.4802L4.22023 46.1602L5.25023 46.4802L4.43023 49.0902L11.5102 51.3002L11.1702 52.4002L4.09023 50.1902Z"
            fill="#26303B"
            fillOpacity={aspect?.includes("W") ? 1 : 0.4}
          />
        </svg>
      )}
      {/* <TextButton
        className="imageOverlayToggle aptCardImgBtn"
        variant="fill"
        icon={
          imageOverlay ? (
            <img
              src="assets/icons/compress.png"
              alt=""
              style={{ filter: "invert(100%)" }}
            />
          ) : (
            <img
              src="assets/icons/enlarge.png"
              alt=""
              style={{ filter: "invert(100%)" }}
            />
          )
        }
        onClick={() => syncState({ imageOverlay: !imageOverlay })}
      /> */}
    </Wrapper>
  )
}

export default FloorplanImage

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  .floorplan-compass {
    position: absolute;
    top: 10rem;
    right: 2rem;
    width: 8em;
  }
`

const Floorplan = styled.img`
  width: 80%;
  height: 75%;
  object-fit: contain;
  padding: 5rem;
  margin: auto;
`
