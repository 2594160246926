import { Vector3 } from "three"
export const ORBIT_POSITIONS = {
  0: {
    position: new Vector3(29.31, 11.35, -7.72),
    target: new Vector3(0, 0, 0),
  },
  1: {
    position: new Vector3(48.96, 25.26, 39.07),
    target: new Vector3(0, 0, 0),
  },
  2: {
    position: new Vector3(52.87, 11.84, -1.05),
    target: new Vector3(0, 0, -10),
  },
  3: {
    position: new Vector3(52.87, 21.84, -41.05),
    target: new Vector3(0, 0, -10),
  },
}

export const cameraPositionData = {
  N01B: {
    position: new Vector3(15, 5, -10),
    target: new Vector3(9, 2, -14),
  },

  S01C: {
    position: new Vector3(12, 6, 3),
    target: new Vector3(5, 3, 3),
  },
} as any

export const aspectCameraPositions = {
  S01C: {
    N: cameraPositionData.S01C.position,
    NE: cameraPositionData.S01C.position,
    E: new Vector3(8.045, 6.24, 10.27),
    SE: cameraPositionData.S01C.position,
    S: cameraPositionData.S01C.position,
    SW: new Vector3(0.24, 7.14, -2.15),
    W: cameraPositionData.S01C.position,
    NW: cameraPositionData.S01C.position,
  },

  N01B: {
    N: cameraPositionData.N01B.position,
    NE: cameraPositionData.N01B.position,
    E: cameraPositionData.N01B.position,
    SE: new Vector3(14.81, 5.36, -10.02),
    S: cameraPositionData.N01B.position,
    SW: cameraPositionData.N01B.position,
    W: cameraPositionData.N01B.position,
    NW: new Vector3(7.67, 5.27, -23.88),
  },
}

export const CAM_POSITION_INDEX = {
  residences: {
    default: {
      position: new Vector3(29.31, 11.35, -7.72),
      target: new Vector3(2, 5, -7),
    },
    activeBlock: {
      N01B: {
        position: new Vector3(14.8, 5.3, -10),
        target: new Vector3(9, 3, -16),
      },
      S01C: {
        position: new Vector3(8.04, 6.2, 10.2),
        target: new Vector3(5, 3, 3),
      },
    },
    activeUnit: aspectCameraPositions,
  },
}

export const APARTMENT_INDEX = {
  A: new Vector3(7.24, 6.98, 12.7),
  B: new Vector3(11.36, 6.96, 10.77),
  C: new Vector3(13.49, 7.24, -3.27),
  D: new Vector3(0.15, 8.08, -4.24),
  E: new Vector3(-0.68, 9.16, -1.82),
  F: new Vector3(13.48, 6.49, -9.44),
  G: new Vector3(13.48, 6.49, -9.44),
  H: new Vector3(17.41, 7.02, -18.94),
  I: new Vector3(7.3, 7.06, -23.58),
  J: new Vector3(7.3, 7.06, -23.58),
  K: new Vector3(10.22, 8.86, -10.22),
}

export const VIEW_ID_INDEX = {
  "N01B-04-01": "G",
  "N01B-04-02": "F",
  "N01B-04-03": "K",
  "N01B-04-04": "J",
  "N01B-04-05": "J",
  "N01B-04-06": "I",
  "N01B-04-07": "H",
  "N01B-04-08": "G",
  "N01B-05-01": "G",
  "N01B-05-02": "F",
  "N01B-05-03": "K",
  "N01B-05-04": "J",
  "N01B-05-05": "J",
  "N01B-05-06": "I",
  "N01B-05-07": "H",
  "N01B-05-08": "G",
  "N01B-06-01": "G",
  "N01B-06-02": "F",
  "N01B-06-03": "K",
  "N01B-06-04": "J",
  "N01B-06-05": "J",
  "N01B-06-06": "I",
  "N01B-06-07": "H",
  "N01B-06-08": "G",
  "N01B-07-01": "G",
  "N01B-07-02": "F",
  "N01B-07-03": "K",
  "N01B-07-04": "J",
  "N01B-07-05": "J",
  "N01B-07-06": "I",
  "N01B-07-07": "H",
  "N01B-07-08": "G",
  "N01B-08-01": "G",
  "N01B-08-02": "F",
  "N01B-08-03": "K",
  "N01B-08-04": "J",
  "N01B-08-05": "I",
  "N01B-08-06": "H",
  "N01B-08-07": "G",
  "N01B-09-01": "G",
  "N01B-09-02": "F",
  "N01B-09-03": "K",
  "N01B-09-04": "J",
  "N01B-09-05": "I",
  "N01B-09-06": "H",
  "N01B-09-07": "G",
  "S01C-02-01": "B",
  "S01C-02-02": "A",
  "S01C-02-03": "E",
  "S01C-02-04": "D",
  "S01C-02-05": "C",
  "S01C-03-01": "B",
  "S01C-03-02": "A",
  "S01C-03-03": "E",
  "S01C-03-04": "D",
  "S01C-03-05": "C",
  "S01C-04-01": "B",
  "S01C-04-02": "A",
  "S01C-04-03": "E",
  "S01C-04-04": "D",
  "S01C-04-05": "C",
  "S01C-05-01": "B",
  "S01C-05-02": "A",
  "S01C-05-03": "E",
  "S01C-05-04": "D",
  "S01C-05-05": "C",
  "S01C-06-01": "B",
  "S01C-06-02": "A",
  "S01C-06-03": "E",
  "S01C-06-04": "D",
  "S01C-06-05": "C",
  "S01C-07-01": "B",
  "S01C-07-02": "A",
  "S01C-07-03": "E",
  "S01C-07-04": "D",
  "S01C-07-05": "C",
  "S01C-08-01": "B",
  "S01C-08-02": "A",
  "S01C-08-03": "E",
  "S01C-08-04": "D",
  "S01C-08-05": "C",
  "S01C-09-01": "B",
  "S01C-09-02": "A",
  "S01C-09-03": "E",
  "S01C-09-04": "D",
  "S01C-09-05": "C",
  "S01C-10-01": "B",
  "S01C-10-02": "A",
  "S01C-10-03": "E",
  "S01C-10-04": "D",
  "S01C-10-05": "C",
  "S01C-11-01": "B",
  "S01C-11-02": "A",
  "S01C-11-03": "E",
  "S01C-11-04": "D",
  "S01C-11-05": "C",
  "S01C-12-01": "B",
  "S01C-12-02": "A",
  "S01C-12-03": "E",
  "S01C-12-04": "D",
  "S01C-12-05": "C",
  "S01C-13-01": "B",
  "S01C-13-02": "A",
  "S01C-13-03": "E",
  "S01C-13-04": "D",
  "S01C-13-05": "C",
  "S01C-14-01": "B",
  "S01C-14-02": "A",
  "S01C-14-03": "E",
  "S01C-14-04": "D",
  "S01C-14-05": "C",
}
