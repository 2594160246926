import FavouritesInfoCard from "@common/components/ResidenceGeneric/FavouritesInfoCard"
import ImageDisplayCompare from "@common/components/ResidenceGeneric/ImageDisplayCompare"
import { useStore } from "@state/store"
import styled from "styled-components"
import { TrashIcon } from "@radix-ui/react-icons"
import TextButton from "@common/ui/TextButton"
import { useFavoritesStore } from "@state/favouritesStore"
import Modal from "@common/ui/Modal"
import { useState } from "react"

type UnitCompareProps = {
  sections: any[]
  contentControls: any
  selectedRows: any[]
  className?: string
}

const UnitCompare = (props: UnitCompareProps) => {
  const { className, selectedRows } = props
  const { removeFromFavorites } = useFavoritesStore()
  const syncState = useStore((s) => s.syncState)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedUnit, setSelectedUnit] = useState(null)

  const removeSelectedRows = (selectedRow: any) => {
    // spread so we don't mutate the state directly
    let updatedRows = [...selectedRows]

    if (selectedRows[0] === selectedRow) {
      updatedRows[0] = null
    } else if (selectedRows[1] === selectedRow) {
      updatedRows[1] = null
    }

    removeFromFavorites(selectedRow)
    syncState({ selectedRows: updatedRows })
  }

  const handleModalOpen = (open: boolean, selectedUnit: any) => {
    setIsModalOpen(open)
    setSelectedUnit(selectedUnit)
  }

  return (
    <Wrapper className={className}>
      <div className="unit-top">
        {selectedRows[0] ? (
          <>
            <div className="card">
              {/* <StyledTextButton
                onClick={() => removeSelectedRows(selectedRows[0])}
                variant="fill"
                className="addToFavorites"
                icon={<TrashIcon />}
                iconPosition="right"
                color="primary"
              >
                Remove from favourites
              </StyledTextButton> */}
              <TextButton
                onClick={() => handleModalOpen(true, selectedRows[0])}
                variant="fill"
                color="primary"
                className="imageOverlayToggle"
                icon={
                  <img
                    src="assets/icons/enlarge.png"
                    alt=""
                    style={{ filter: "invert(100%)" }}
                  />
                }
              />

              <FavouritesInfoCard compare={true} unit={selectedRows[0]} />
              <ImageDisplayCompare selectedUnit={selectedRows[0]} />
            </div>
          </>
        ) : (
          <div className="no-compare">
            Click an apartment<span>to compare</span>
          </div>
        )}
      </div>
      <div className="unit-bottom">
        {selectedRows[1] ? (
          <>
            <div className="card">
              {/* <StyledTextButton
                onClick={() => removeSelectedRows(selectedRows[1])}
                variant="fill"
                className="addToFavorites"
                icon={<TrashIcon />}
                iconPosition="right"
                color="primary"
              >
                Remove from favourites
              </StyledTextButton> */}
              <TextButton
                className="imageOverlayToggle"
                onClick={() => handleModalOpen(true, selectedRows[1])}
                variant="fill"
                color="primary"
                icon={
                  <img
                    src="assets/icons/enlarge.png"
                    alt=""
                    style={{ filter: "invert(100%)" }}
                  />
                }
              />
              <FavouritesInfoCard compare={true} unit={selectedRows[1]} />
              <ImageDisplayCompare selectedUnit={selectedRows[1]} />
            </div>
          </>
        ) : (
          <div className="no-compare">
            Click an apartment<span>to compare</span>
          </div>
        )}
      </div>
      <Modal
        style={{
          backgroundColor: "var(--primary)",
          height: "100%",
          width: "100%",
        }}
        isFullScreen={true}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <ImageDisplayCompare
          show={setIsModalOpen}
          selectedUnit={selectedUnit}
        />
      </Modal>
    </Wrapper>
  )
}

export default UnitCompare

const Wrapper = styled.div`
  display: flex;
  justify-content: column;
  position: relative;

  .imageOverlayToggle {
    position: absolute;
    bottom: 1em;
    right: 1em;
    z-index: 10;
  }

  .button {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    z-index: 10;
    transform-origin: bottom right;
    border: none;
    background-color: transparent;
    height: 5em;
    width: 5em;
  }

  .line {
    width: 90%;
    height: 1px;
    position: absolute;
    background-color: var(--accent);
  }

  .unit-top,
  .unit-bottom {
    width: 100%;
    max-height: 80%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .card {
      width: 100%;
      height: 100%;
      display: flex;
    }

    .no-compare {
      display: flex;
      flex-direction: column;
      text-transform: uppercase;
      color: #fff;
      font-size: 1.5em;
      letter-spacing: 0.2em;
      line-height: 0.9;

      span {
        font-size: 0.8em;
        color: var(--accent);
        text-transform: lowercase;
        font-family: secondaryFont;
        text-align: right;
        margin-right: -2em;
        font-size: 2em;
      }
    }
  }
`

const StyledTextButton = styled(TextButton)`
  position: absolute;
  bottom: 1em;
  left: 1em;
  height: 4em !important;
  text-transform: capitalize;
`
