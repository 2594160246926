import NavTextButton from "@common/ui/NavTextButton"
import { useStore } from "@state/store"
import { useEffect, useRef, useState } from "react"
import styled from "styled-components/macro"
import gsap from "gsap"
import { loopArrayIndex } from "@utils/functions"

const Community = ({ aboutData }) => {
  const aboutIndex = useStore((s) => s.aboutIndex)
  const [currentIndex, setCurrentIndex] = useState(0)
  const mediaURL = aboutData?.description[0]?.img?.publicURL
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    gsap.to(".communityWrapper", {
      autoAlpha: aboutIndex === 2 ? 1 : 0,
      duration: 0.6,
      ease: "power3.inOut",
    })
    if (videoRef.current) {
      if (aboutIndex === 2) {
        videoRef.current.play()
      } else {
        setTimeout(() => {
          videoRef.current.pause()
          videoRef.current.currentTime = 0
        }, 600)
      }
    }
  }, [aboutIndex])
  return (
    <CommunityWrapper className="communityWrapper">
      <div className="communityContentLeft">
        {mediaURL?.includes("mp") ? (
          <video
            ref={videoRef}
            src={mediaURL}
            muted
            playsInline
            controls
            loop
          />
        ) : (
          <img src={mediaURL} alt="" />
        )}
      </div>
      <div className="rightText">
        <div className="text">{aboutData?.description[currentIndex]?.desc}</div>
        <div className="person">
          {aboutData?.description[currentIndex]?.title}
        </div>
        <div className="controls">
          <div className="srNO">
            {currentIndex + 1}/{aboutData?.description?.length}
          </div>
          <div className="button">
            <div
              onClick={() => {
                const index = loopArrayIndex(
                  false,
                  aboutData?.description,
                  currentIndex,
                )
                setCurrentIndex(index)
              }}
            >
              {" "}
              <img src="/assets/icons/back.svg" alt="" />
            </div>
            <div
              onClick={() => {
                const index = loopArrayIndex(
                  true,
                  aboutData?.description,
                  currentIndex,
                )
                setCurrentIndex(index)
              }}
            >
              <img
                src="/assets/icons/back.svg"
                style={{ transform: "rotate(180deg)" }}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </CommunityWrapper>
  )
}

export default Community

const CommunityWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: var(--peabodyGray);
  display: flex;

  .communityContentLeft {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 50%;
    height: 80%;
    margin: auto 0;
    padding: 2rem;
    gap: 10rem;
    overflow: visible;

    img,
    video {
      width: 115%;
      height: 80%;
      z-index: 10;
      object-fit: cover;
      margin: auto;
      box-shadow: 4px 4px 10px rgba(255, 255, 255, 0.1);
    }
  }

  .rightText {
    width: 50%;
    background-color: var(--peabodyTeal);
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;

    .text {
      width: 50%;
      margin: 0 auto;
      color: white;
      font-size: 3rem;
      font-family: pbdyPrimary;
    }

    .person {
      width: 50%;
      margin: 0 auto;
      font-size: 3rem;
      color: var(--peabodyGray);
      letter-spacing: 0.3rem;
      font-family: pbdySecondary;
    }

    .controls {
      display: flex;
      width: 50%;
      align-items: center;
    }

    .srNO {
      color: white;
      letter-spacing: 1rem;
      font-size: 1.5rem;
    }

    .button {
      margin-left: auto;
      display: flex;
      gap: 1rem;
      cursor: pointer;

      div {
        background-color: var(--peabodyGray);
        padding: 1rem;
        color: #fff;
        width: 4rem;
        height: 4rem;

        img {
          width: 100%;
          height: 100%;
          padding: 0.5rem;
        }
        &:active {
          opacity: 0.75;
          transform: scale(0.95);
          transition: 0.3s;
        }
      }
    }
  }
`
