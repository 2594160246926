import styled from "styled-components/macro"
import { useCallback, useEffect, useRef, useState } from "react"
import { sortTable } from "@lib/utils/residenceTableFn"
import { SortConfig, useStore } from "@state/store"
import { TableBody } from "./TableBody"
import { TableHeader } from "./TableHeader"

type SelectionMode = "single" | "double"

type FlexTableProps = {
  className?: string
  uniqueKey: string
  headers?: any[]
  data: any[]
  cells: any[]
  sortable?: boolean
  sortConfig?: SortConfig
  setSortConfig?: (config: SortConfig) => void
  onSelectionChange?: (rows: any[]) => void
  selection?: SelectionMode
  onPointerOver?: (e: any, unit: any) => void
  onPointerOut?: (e: any, unit: any) => void
  style?: React.CSSProperties
}

const FlexTable: React.FC<FlexTableProps> = ({
  className,
  uniqueKey,
  headers = [],
  data,
  sortConfig = { field: null, direction: "ascending" },
  cells,
  sortable = true,
  setSortConfig,
  onSelectionChange,
  selection = "single",
  onPointerOver,
  onPointerOut,
  style,
}) => {
  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const [dataState, setDataState] = useState<any>(data)
  const syncState = useStore((s) => s.syncState)
  const [sorting, setSorting] = useState<boolean>()

  useEffect(() => {
    if (!sorting) setDataState(data)
    else setSorting(false)
  }, [data])

  useEffect(() => {
    if (selection === "double") setSelectedRows([])
    else if (selection === "single") setSelectedRows([])
  }, [selection])

  const handleSort = useCallback(
    (field: any) => {
      const { sortedData, newSortConfig } = sortTable(dataState, {
        field,
        direction: sortConfig.direction,
      })
      setDataState(sortedData)
      setSorting(true)
      setSortConfig && setSortConfig(newSortConfig)
    },
    [dataState, sortConfig, setSortConfig],
  )

  function handleRowClick(row) {
    const isAlreadySelected = selectedRows.some(
      (selected) => selected[uniqueKey] === row[uniqueKey],
    )

    let newSelectedRows: any[] = []

    if (selection === "single") {
      // Single selection mode
      newSelectedRows = isAlreadySelected ? [] : [row]
    } else if (selection === "double") {
      // Double selection mode
      if (isAlreadySelected) {
        // Deselect the row but keep the anchor (first selected row) if present
        newSelectedRows = selectedRows.filter(
          (selected) => selected[uniqueKey] !== row[uniqueKey],
        )

        // If there's only 1 selected row left, it's the new anchor.
        if (newSelectedRows.length === 1) {
          // Do nothing, the remaining one is the anchor
        } else if (newSelectedRows.length === 0) {
          // No rows left selected
          newSelectedRows = []
        }
      } else if (selectedRows.length < 2) {
        // Add row to selection if less than two rows are selected
        newSelectedRows = [...selectedRows, row]
      } else {
        // Replace the second selected row
        newSelectedRows = [selectedRows[0], row]
      }

      // ENJOY!
    }

    // Ensure to filter out any rows that might have been deleted from dataState
    const validSelectedRows = newSelectedRows.filter((selectedRow) =>
      dataState.some(
        (dataRow: any) => dataRow?.[uniqueKey] === selectedRow?.[uniqueKey],
      ),
    )

    setSelectedRows(validSelectedRows)
    onSelectionChange?.(validSelectedRows)

    if (validSelectedRows.length > 0) {
      const units = validSelectedRows.map((u) =>
        dataState.find((item: any) => item[uniqueKey] === u[uniqueKey]),
      )

      // timeout for transition
      const timeoutId = setTimeout(() => {
        syncState({ selectedUnits: units })
      }, 300)

      return () => clearTimeout(timeoutId)
    }
  }
  return (
    <GridTableContainer className={className} style={{ ...style }}>
      {dataState.length === 0 ? (
        <div className="no-data">No Units</div>
      ) : (
        <>
          <TableHeader
            headers={headers}
            sortConfig={sortConfig}
            handleSort={sortTable && sortable ? handleSort : () => {}}
            columns={cells.length}
          />
          <TableBody
            onPointerOver={onPointerOver}
            onPointerOut={onPointerOut}
            uniqueKey={uniqueKey}
            data={dataState}
            cells={cells}
            selectedRows={selectedRows}
            handleRowClick={handleRowClick}
            columns={cells.length}
          />
        </>
      )}
    </GridTableContainer>
  )
}

export { FlexTable }

const GridTableContainer = styled.div`
  border-top: 3px solid var(--primary);

  top: 0;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-auto-rows: min-content;
  position: relative;
  width: 100%;
  height: 100%;
  gap: 1rem;

  .no-data {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
