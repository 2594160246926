import { useEffect, useRef } from "react"
import styled from "styled-components"
import gsap from "gsap"
import BackButton from "@common/ui/BackButton"
import { useStore } from "@state/store"
import ResidenceInfoCard2 from "../ResidenceGeneric/ResidenceInfoCard2"
import { useProjectDataStore } from "@state/projectDataStore"
import ImageDisplay from "../ResidenceGeneric/ImageDisplay"
import ContentIndexControls from "../ContentIndexControls"

const ResidencePopup2 = ({ selectedUnit }) => {
  const syncState = useStore((s) => s.syncState)
  const wrapperRef = useRef()
  const residencePopup = useStore((s) => s.residencePopup)
  const contentControls = useStore((s) => s.contentControls)
  const imageOverlay = useStore((s) => s.imageOverlay)
  const { splashData, residencesData } = useProjectDataStore((s) => ({
    splashData: s.splashData,
    residencesData: s.residencesData,
  }))
  const bgImage = splashData.splashBg
  let categories = [
    ...Array.from(
      new Set(residencesData?.categories?.map((category) => category)),
    ),
  ]
  categories = [categories[0], categories[1]]

  useEffect(() => {
    gsap.to(wrapperRef.current, {
      autoAlpha: residencePopup ? 1 : 0,
      duration: 0.4,
      ease: "power1.inOut",
    })
  }, [residencePopup])
  return (
    <Wrapper
      className="residences-popup"
      ref={wrapperRef}
      bgImage={bgImage.url}
    >
      <ResidenceInfoCard2 unit={selectedUnit} />
      <ImageDisplay
        style={{
          transition: "transform 0.4s ease-in-out",
          transform: residencePopup ? "translateX(0%)" : "translate(100%)",
        }}
        className="floorplan-popup"
      />
      <div
        className="residencesCategory"
        style={{
          transition: "transform 0.4s ease-in-out",
          transform: residencePopup ? "translateX(0%)" : "translateX(-100%)",
        }}
      >
        <BackButton
          arrowColor="var(--primary)"
          onClick={() => {
            if (imageOverlay) {
              syncState({
                imageOverlay: false,
              })
              return
            }
            if (contentControls?.index === 1) {
              syncState({
                contentControls: {
                  ...contentControls,
                  index: 0,
                },
              })
              return
            }
            syncState({ residencePopup: false })
          }}
        />
        <ContentIndexControls
          className="residencesCategoryIndex"
          categories={categories}
        />
      </div>
    </Wrapper>
  )
}

export default ResidencePopup2

const Wrapper = styled.div<{ bgImage }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  background-image: url(${(props) => props.bgImage});
  background-size: cover;
  background-position: center;
  z-index: 120;

  .controls {
    position: absolute;
    width: 100%;
  }

  .floorplan-popup {
    & img {
      width: 100%;
    }
  }

  .back-btn {
    position: relative !important;
    margin: 0;
    inset: 0 !important;
    height: calc(4em - 1px);
    width: 4em;
    border: none;
    background-color: var(--white);
  }
`
