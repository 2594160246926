import React from "react"
import { useProjectDataStore } from "@state/projectDataStore"
import styled from "styled-components"

import { useAngles } from "./hooks/useAngles"
import ViewUI from "./components/ViewLayout"
import ViewList from "./components/ViewList"
import { useCanvasStore } from "@state/canvasStore"
import LabelUI from "./components/LabelUI"

const Orbit3D = () => {
  const { orbitData } = useProjectDataStore((s) => ({
    orbitData: s.orbitData,
  }))

  const touchPoints = orbitData.touchPoints
  console.log("touchPoints", touchPoints)
  const setTouchPoints = useCanvasStore((s) => s.setTouchPoints)

  React.useEffect(() => {
    setTouchPoints(touchPoints)
  }, [touchPoints])

  const { angleUI, pointsMapped } = useAngles()

  if (!pointsMapped) return null
  return (
    <Wrapper>
      {/* <ResiLandingOverlay /> */}
      <ViewList />
      <ViewUI pointsMapped={pointsMapped} />
      <LabelUI />
      {/* <Angle ref={angleUI} /> */}
    </Wrapper>
  )
}

const Angle = styled.div`
  z-index: 2;
  top: 20px;
  right: 50%;
  transform: translateX(50%);
  color: white;

  position: absolute;
  width: 50px;
  height: 50px;
`

const Wrapper = styled.div`
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;

  .filterIcon {
    position: absolute;
    border-radius: 100%;
    right: 4.5rem;
    margin: 2rem;
    border: 1px solid var(--accent);
  }

  @media (max-width: 480px) {
    .button div img {
      padding: 0;
    }
  }
  @media (max-width: 720px) {
    .button div img {
      padding: 0;
    }
  }
  @media (min-width: 721px) and (max-width: 1080px) {
    .button div img {
      padding: 0.2rem;
    }

    #orbit-info{
      width: 50%;
    }
  }
`

export default Orbit3D
