import styled from "styled-components"

function LandscapePrompt() {
  return (
    <PromptWrapper>
      Please use in<br/>
      landscape mode.
    </PromptWrapper>
  )
}

export default LandscapePrompt

const PromptWrapper = styled.div`
  position: fixed;
  z-index: calc(infinity);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  background-image: url("images/westHamSplash-2.jpg");
  display: none;
  pointer-events: none;
  text-align: center;
  
  @media (min-width: 0px) and (orientation: portrait){
    display: grid;
    place-content: center;
    pointer-events: all;
    font-size: 8rem;
    color: var(--accent);
  }

  @media (max-width: 480px) {
    font-size: 2rem;
  }
  @media (max-width: 720px) {
    font-size: 3rem;
  }
  @media (max-width: 1080px) {
    font-size: 5rem;
  }
  @media (max-width: 1875px) {
    font-size: 7rem;
  }
`