import React from "react"
import { useOrbit3DStore } from "../state/orbit3DStore"
import { useProjectDataStore } from "@state/projectDataStore"
import { useCanvasStore } from "@state/canvasStore"
import { Vector3 } from "three"
import { sphericalCoords } from "@lib/three-utils/sphericalCoords"
import { ORBIT_POSITIONS } from "@data/cameraData"

const defaultValue = [
  {
    title: "defaultValue",
    positions: {
      x: 1,
      y: 1,
      z: 1,
    },
    targets: {
      x: 0,
      y: 0,
      z: 0,
    },
    id: "66e165ee15828e3414217f6f",
  },
]
export const useAngles = () => {
  const { orbitData } = useProjectDataStore((s) => ({
    orbitData: s.orbitData,
  }))

  console.log(orbitData)

  let cameraPoints =
    orbitData?.cameraPoints?.length === 0 ||
    orbitData?.cameraPoints === undefined
      ? defaultValue
      : orbitData?.cameraPoints

  let cameraPointsR = orbitData?.cameraPoints.slice(0, -1)
  console.log(cameraPointsR)

  cameraPoints = cameraPoints.map((point, i) => {
    return { ...point, positions: ORBIT_POSITIONS[i].position }
  })
  // console.log(cameraPoints)
  const angleUI = React.useRef<HTMLDivElement>(null)

  const addLabel = useOrbit3DStore((s) => s.addLabel)
  const removeLabel = useOrbit3DStore((s) => s.removeLabel)

  const addActiveView = useOrbit3DStore((s) => s.addActiveView)
  const removeActiveView = useOrbit3DStore((s) => s.removeActiveView)

  const pointsMapped = React.useMemo(() => {
    const pointsMapped = cameraPoints.map(
      ({ positions, title, subTitle, body }) => {
        const { azimuth, polar } = sphericalCoords(positions)
        // console.log(positions)
        return {
          position: positions,
          text: {
            title,
            subTitle,
            body,
          },
          azimuth,
          polar,
        }
      },
    )

    return pointsMapped
  }, [orbitData, cameraPoints])

  React.useEffect(() => {
    const unsub = useCanvasStore.subscribe(
      (state) => ({
        azimuth: state.azimuth,
        polar: state.polar,
        camera: state.camera,
      }),
      ({ azimuth, polar, camera }) => {
        if (!cameraPoints || !camera) return

        cameraPoints.forEach((camPoint, index) => {
          const vector = new Vector3(
            camPoint.positions.x,
            camPoint.positions.y,
            camPoint.positions.z,
          )

          const angle = camera.angleTo(vector)

          if (angle < 0.25) {
            addLabel(index)
            addActiveView(index)
          } else {
            removeLabel(index)
            removeActiveView(index)
          }
        })

        if (angleUI.current) {
          angleUI.current.innerText = `${Math.round(azimuth)}° / ${Math.round(
            polar,
          )}°`
        }
      },
    )
    return () => unsub()
  }, [pointsMapped])

  return { angleUI, pointsMapped }
}
