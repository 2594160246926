import ContentIndexControls from "@common/components/ContentIndexControls"
import useSortConfig from "@common/hooks/useSortConfig"
import { useStore } from "@state/store"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { Share1Icon, Cross1Icon } from "@radix-ui/react-icons"
import { useFavoritesStore } from "@state/favouritesStore"
import * as Switch from "@radix-ui/react-switch"
import UnitCompare from "./components/UnitCompare"
import UnitSingle from "./components/UnitSingle"
import Prompt from "./components/Prompt"
import FloorplanSection from "@common/components/ResidenceGeneric/FloorplanSection"
import ViewSection from "@common/components/ViewSection"
import SpecsSection from "@common/components/SpecsSection"
import Modal from "@common/ui/Modal"
import Share from "./components/Share"
import { FlexTable } from "@common/components/table/FlexTable"
import TextButton from "@common/ui/TextButton"

const headers = ["unitName", "price", "floor", "beds", "aspect"]
const cells = ["unitName", "price", "floor", "beds", "aspect"]

export default function Favourites(props: any) {
  const { sortConfig, setSortConfig } = useSortConfig()
  const [isShareModalOpen, setIsShareModalOpen] = useState(false)
  const syncState = useStore((s) => s.syncState)
  const contentControls = useStore((s) => s.contentControls)
  const stateCategories = ["Floorplan", "View"]

  useEffect(() => {
    syncState({
      contentControls: {
        categories: stateCategories,
        index: 0,
      },
    })
    return () => {
      syncState({
        contentControls: null,
        compareMode: false,
      })
    }
  }, [])

  const { favorites, removeFromFavorites, clearFavorites } = useFavoritesStore(
    (state) => state,
  )

  const { selectedRows, compareMode } = useStore((state) => state)

  const handleSelectionChange = (newRows: any[]) => {
    let updatedRows = [...selectedRows]

    if (newRows?.find((row) => row.unitName === selectedRows[0]?.unitName)) {
      updatedRows[0] = selectedRows[0]
    } else {
      updatedRows[0] = null
    }

    if (newRows?.find((row) => row.unitName === selectedRows[1]?.unitName)) {
      updatedRows[1] = selectedRows[1]
    } else {
      updatedRows[1] = null
    }

    newRows?.forEach((newRow) => {
      if (!updatedRows.find((row) => row && row.unitName === newRow.unitName)) {
        const nullIndex = updatedRows.indexOf(null)
        if (nullIndex !== -1) {
          updatedRows[nullIndex] = newRow
        }
      }
    })

    syncState({ selectedRows: updatedRows })
  }

  const handleClearFavorites = () => {
    const prompt = document.querySelector(".prompt")
    prompt.classList.toggle("active")
  }

  const sections = [
    { index: 0, component: FloorplanSection },
    { index: 1, component: ViewSection },
    { index: 2, component: SpecsSection },
  ]

  return (
    <>
      <Container className="favourites-container">
        {!favorites || favorites?.length === 0 ? (
          <div className="no-favourites">
            Your Apartment<span>awaits</span>
          </div>
        ) : (
          <>
            <TableContainer className="favourites-table">
              <div className="fav-title">
                <h1>FAVOURITE</h1>
                <h1>Apartments</h1>
              </div>
              <form className="switch">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label
                    className="Label"
                    htmlFor="compare-mode"
                    style={{ paddingRight: 15 }}
                  >
                    Compare
                  </label>
                  <Switch.Root
                    className="SwitchRoot"
                    id="compare-mode"
                    defaultChecked={false}
                    onCheckedChange={(v) => {
                      syncState({ compareMode: v })
                      if (v === true) syncState({ selectedRows: [null, null] })
                      else syncState({ selectedRows: [] })
                    }}
                  >
                    <Switch.Thumb className="SwitchThumb" />
                  </Switch.Root>
                </div>
              </form>
              <FlexTable
                className="table"
                uniqueKey="unitName"
                headers={headers}
                cells={cells}
                data={favorites || []}
                sortConfig={sortConfig}
                setSortConfig={setSortConfig}
                selection={compareMode === true ? "double" : "single"}
                onSelectionChange={(rows) => {
                  if (compareMode === true) {
                    handleSelectionChange(rows)
                  } else {
                    syncState({ selectedRows: rows })
                  }
                }}
              />
              <ContentIndexControls
                className="favourites-control-index"
                categories={stateCategories}
              />
              <BottomControls className="table-bottom-controls">
                <TextButton
                  className="bottom-controls-button"
                  onClick={() => handleClearFavorites()}
                  icon={<Cross1Icon />}
                  iconPosition="right"
                  color="#fff"
                  variant="outline"
                  disabled={favorites.length === 0}
                >
                  Clear Favourites
                </TextButton>
                <div className="clear-favorites">
                  <Prompt
                    onConfirm={() => {
                      clearFavorites()
                      syncState({ selectedRows: [] })
                      document
                        .querySelector(".prompt")
                        .classList.toggle("active")
                    }}
                  />
                </div>

                <TextButton
                  color="#fff"
                  disabled={favorites.length === 0}
                  className="bottom-controls-button"
                  onClick={() => setIsShareModalOpen(true)}
                  style={{ fontWeight: "bold" }}
                  variant="outline"
                  icon={<Share1Icon />}
                  iconPosition="right"
                >
                  Share
                </TextButton>
              </BottomControls>
            </TableContainer>
            <InfoContainer className="favourites-info">
              {compareMode ? (
                <UnitCompare
                  sections={sections}
                  contentControls={contentControls}
                  selectedRows={selectedRows}
                  className="unit-compare-container"
                />
              ) : (
                <UnitSingle
                  sections={sections}
                  contentControls={contentControls}
                  selectedRows={selectedRows[0]}
                  removeFromFavorites={removeFromFavorites}
                />
              )}
            </InfoContainer>
            <Modal
              style={{ backgroundColor: "var(--primary)" }}
              isFullScreen={true}
              isOpen={isShareModalOpen}
              onClose={() => setIsShareModalOpen(false)}
            >
              <Share show={setIsShareModalOpen} config={props} />
            </Modal>
          </>
        )}
      </Container>
    </>
  )
}

const Container = styled.div`
  @media (max-width: 480px) {
    .table-bottom-controls {
      & svg {
        width: 6px;
      }
    }

    .switch div:nth-last-of-type(1){
      align-items: flex-end;
    }

    .switch .Label{
      font-size: 2rem !important;
      padding-right: 8px !important;
    }
    .switch .SwitchRoot{
      transform-origin: left center;
      transform: scale(0.5) !important;
    }

    .spec2{
      letter-spacing: 2px;
    }

    .favourites-info .close-button{
      & svg{
        width: 6px !important;
      }
    }
  }
  @media (max-width: 720px) {
    .table-bottom-controls {
      & svg {
        width: 8px;
      }
    }

    .switch div:nth-last-of-type(1){
      align-items: flex-end;
    }

    .switch .Label{
      font-size: 1.8rem !important;
      padding-right: 0px !important;
    }
    .switch .SwitchRoot{
      transform-origin: left center;
      transform: scale(0.5) !important;
    }

    .spec2{
      letter-spacing: 2px;
    }

    .favourites-info .close-button{
      & svg{
        width: 8px !important;
      }
    }
  }
  @media (max-width: 1080px) {
    .table-bottom-controls {
      & svg {
        width: 12px;
      }
    }

    .switch div:nth-last-of-type(1){
      align-items: flex-end;
    }

    .switch .Label{
      font-size: 1.8rem !important;
      padding-right: 0px !important;
    }
    .switch .SwitchRoot{
      transform-origin: left center;
      transform: scale(0.5) !important;
    }

    .spec, .spec2{
      font-size: 1.8rem;
    }
    .specsContainer{
      font-size: 1.5rem;
    }
    .spec2{
      letter-spacing: 2px;
    }

    .favourites-info .close-button{
      & svg{
        width: 8px !important;
      }
    }
  }
  @media (min-width: 2051px) and (max-width: 3839px) {
    .table-bottom-controls {
      & svg {
        width: 20px;
        height: auto;
      }
    }

    .switch div:nth-last-of-type(1){
      align-items: flex-end;
    }

    .switch .Label{
      font-size: 1.8rem !important;
      padding-right: 40px !important;
    }
    .switch .SwitchRoot{
      transform-origin: left center;
      transform: scale(1.5) !important;
    }

    .spec, .spec2{
      font-size: 1.2rem;
    }
    .specsContainer{
      font-size: 1.1rem;
    }
    .spec2{
      letter-spacing: 2px;
    }

     .close-button{
      & svg{
        width: 20px !important;
        height: auto;
      }
    }
  }
  @media (min-width: 3840px){
    .table-bottom-controls {
      & svg {
        width: 40px;
        height: auto;
      }
    }

    .switch div:nth-last-of-type(1){
      align-items: flex-end;
    }

    .switch .Label{
      font-size: 1.8rem !important;
      padding-right: 60px !important;
    }
    .switch .SwitchRoot{
      transform-origin: left center;
      transform: scale(2) !important;
    }

    .spec, .spec2{
      font-size: 1.1rem;
    }
    .specsContainer{
      font-size: 1rem;
    }
    .spec2{
      letter-spacing: 2px;
    }

     .close-button{
      & svg{
        width: 60px !important;
        height: auto;
      }
    }
  }
`

const InfoContainer = styled.div``

const TableContainer = styled.div`
  .flex-table-header {
    margin-left: 0;
    width: 100%;
  }
  #table-rows-container {
    margin: 0;
    width: 100%;
    padding-left: 0;

    .selected {
      border: 1px solid var(--green);
      background-color: var(--primary) !important;
      color: white;
    }
  }
  @media (max-width: 480px) {
    .flex-table-header {
      padding-left: 1rem;
      padding-right: 1rem;
      & div {
        font-size: 1rem;
      }
    }

    .grid-table-row {
      height: 5rem;
      padding: 0.5rem;
      border-radius: 5rem;

      & div {
        height: 4rem;
      }
      & svg {
        height: auto;
        width: 5px;
      }
    }
  }
  @media (max-width: 720px) {
    .flex-table-header {
      padding-left: 0rem;
      padding-right: 1.8rem;
      & div {
        font-size: 1rem;
      }
    }

    .grid-table-row {
      height: 4.5rem;
      padding: 0.5rem;
      border-radius: 5rem;

      & div {
        height: 4rem;
      }
      & svg {
        height: auto;
        width: 8px;
      }
    }
  }
  @media (max-width: 1080px) {
    .flex-table-header {
      padding-left: 0rem;
      padding-right: 1.8rem;
      & div {
        font-size: 1.3rem;
      }
    }
    
    .grid-table-row {
      height: 4.5rem;
      padding: 0.5rem;
      border-radius: 5rem;
      font-size: 1.3rem;

      & div {
        height: 4rem;
      }
      & svg {
        height: auto;
        width: 9px;
      }
    }
  }
  @media (min-width: 2051px) and (max-width: 3839px) {
    .flex-table-header {
      padding-left: 0rem;
      padding-right: 1.8rem;
      & div {
        font-size: 1rem;
      }
    }
    
    .grid-table-row {
      height: 4.5rem;
      padding: 0.5rem;
      border-radius: 5rem;
      font-size: 1.1rem;

      & div {
        height: 4rem;
      }
      & svg {
        height: auto;
        width: 20px;
      }
    }
  }
  @media (min-width: 3840px){
    .flex-table-header {
      padding-left: 0rem;
      padding-right: 1.8rem;
      & div {
        font-size: 1rem;
      }
    }
    
    .grid-table-row {
      height: 4.5rem;
      padding: 0.5rem;
      border-radius: 5rem;
      font-size: 1rem;

      & div {
        height: 4rem;
      }
      & svg {
        height: auto;
        width: 40px;
      }
    }
  }
`

const BottomControls = styled.div``
