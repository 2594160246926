import { ORBIT_POSITIONS } from "@data/cameraData"
import { useCanvasStore } from "@state/canvasStore"
import React, { useState } from "react"
import styled from "styled-components"
import { Vector3 } from "three"

const ViewList = () => {
  const setCameraData = useCanvasStore((s) => s.setCameraData)
  const canvasReady = useCanvasStore((s) => s.canvasReady)

  const [currentIndex, setCurrentIndex] = useState(0)

  const length = Object.keys(ORBIT_POSITIONS).filter(
    (item) => !isNaN(Number(item)),
  ).length

  const setCaptureID = useCanvasStore((s) => s.setCaptureID)
  const setCanvasImagePath = useCanvasStore((s) => s.setCanvasImagePath)

  React.useEffect(() => {
    setCaptureID(`orbit-${currentIndex}`)
    setCanvasImagePath(`orbit-${currentIndex}`)
    setCameraData({
      position: ORBIT_POSITIONS[currentIndex].position,
      target: new Vector3(0, -0.05, 0),
    })
  }, [canvasReady, currentIndex])

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? length - 1 : prevIndex - 1,
    )
  }

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === length - 1 ? 0 : prevIndex + 1,
    )
  }

  return (
    <PointsUI>
      <div className="button">
        <div onClick={handlePrev}>
          <img src="/assets/icons/back.svg" alt="" />
        </div>
        <div onClick={handleNext}>
          <img
            src="/assets/icons/back.svg"
            style={{ transform: "rotate(180deg)" }}
            alt=""
          />
        </div>
      </div>
    </PointsUI>
  )
}

export default ViewList

const PointsUI = styled.div`
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 2rem;
  gap: 1rem;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  .button {
    display: flex;
    gap: 1rem;
    cursor: pointer;

    div {
      background-color: var(--primary);
      border: var(--accent) solid 1px;
      padding: 1rem;
      color: #fff;
      width: 4rem;
      height: 4rem;

      img {
        width: 100%;
        height: 100%;
        padding: 0.5rem;
      }
      &:active {
        opacity: 0.75;
        transform: scale(0.95);
        transition: 0.3s;
      }
    }
  }
`
