import styled from "styled-components"
import { FlexTable } from "@common/components/table/FlexTable"
import { useStore } from "@state/store"
import useSortConfig from "@common/hooks/useSortConfig"
import { useEffect } from "react"
import ResidencePopup2 from "@common/components/westHam/ResidencePopup2"
import { useProjectDataStore } from "@state/projectDataStore"
import TextButton from "@common/ui/TextButton"
import Filters from "@common/components/ResidenceGeneric/Filters"
import { useCanvasStore } from "@state/canvasStore"
import { CAM_POSITION_INDEX } from "@data/cameraData"
import { HeartIcon } from "@radix-ui/react-icons"

const ResidencesType1 = () => {
  const residencesData = useProjectDataStore((s) => s.residencesData)

  const setCameraData = useCanvasStore((s) => s.setCameraData)

  useEffect(() => {
    setCameraData(CAM_POSITION_INDEX.residences.default)
  }, [])
  const selectedUnit = useStore((s) => s.selectedUnit)

  const { sortConfig, setSortConfig } = useSortConfig()
  const syncState = useStore((s) => s.syncState)

  const filteredUnits = useStore((s) => s.filteredUnits)

  const categories = residencesData?.categories

  useEffect(() => {
    syncState({
      contentControls: {
        categories: categories,
        index: 0,
      },
    })
    return () => {
      syncState({
        residencePopup: false,
        contentControls: null,
      })
    }
  }, [])

  const headers = [
    "unitName",
    "price",
    "floor",
    "unitType",
    "beds",
    "aspect",
    <HeartIcon className="fav-icon" />,
  ]

  const cells = ["unitName", "price", "floor", "unitType", "beds", "aspect"]
  return (
    <>
      <Wrapper>
        <ControlsWrapper>
          <FilterContainer>
            <Filters className="filters" show={true} />
          </FilterContainer>
          <TableContainer>
            {filteredUnits && (
              <FlexTable
                className="table"
                uniqueKey="unitName"
                headers={headers}
                selection="single"
                data={filteredUnits.filter((d) => d.active)}
                cells={cells}
                sortConfig={sortConfig}
                setSortConfig={setSortConfig}
                onSelectionChange={(rows) => {
                  syncState({
                    selectedUnit: rows,
                  })
                }}
              />
            )}
          </TableContainer>
        </ControlsWrapper>
        <TextButton
          className="exploreBtn"
          onClick={() => syncState({ residencePopup: true })}
          style={{
            opacity: selectedUnit?.[0] ? 1 : 0,
            pointerEvents: selectedUnit?.[0] ? "all" : "none",
            transform: `rotate(90deg) translate(-3px, ${
              selectedUnit?.[0] ? -100 : 0
            }%)`,
            width: "50%",
          }}
        >
          View Unit
        </TextButton>
        <ResidencePopup2 selectedUnit={selectedUnit} />
      </Wrapper>
    </>
  )
}

export default ResidencesType1

const ControlsWrapper = styled.div`
  z-index: 2;
  background-color: var(--primary);
  position: absolute;
  display: flex;

  width: 23vw;
  height: 100vh;
  flex-direction: column;
  border-right: white 1px solid;

  padding: 2rem;
  padding-top: 8rem;
  gap: 2rem;
`

const FilterContainer = styled.div`
  flex-shrink: 1;
  pointer-events: all;
`

const TableContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;

  position: relative;

  .table {
    background-color: var(--primary);
    border: 1px solid white;
    pointer-events: all;
  }

  @media (max-width: 480px) {
    .flex-table-header {
      & div {
        font-size: 0.8rem;

        & svg {
          width: 6px;
        }
      }
    }

    .grid-table-row {
      height: 5rem;
      padding: .5rem;
      border-radius: 5rem;
      
      & div{
        height: 4rem;
      }
      & svg {
        height: auto;
        width: 5px;
      }
    }
  }
  @media (max-width: 720px) {
    .flex-table-header {
      padding: 0 1rem;
      & div {
        font-size: 0.9rem;
        
        & svg {
          width: 7px;
        }
      }
    }
    
    .grid-table-row {
      height: 4rem;
      padding: .3rem;
      border-radius: 4rem;
      
      & div{
        height: 4rem;
      }
      & svg {
        height: auto;
        width: 6px;
      }
    }
  }
  @media (max-width: 1080px) {
    .flex-table-header {
      padding: 0 2rem;
      & div {
        font-size: 0.85rem;
        
        & svg {
          width: 9px;
        }
      }
    }
    
    .grid-table-row {
      height: 4.5rem;
      padding: 0.2rem .5rem;
      border-radius: 4rem;
      
      & div{
        height: 5rem;
      }
      & svg {
        height: auto;
        width: 9px;
      }
    }
  }
  @media (min-width: 2501px) and (max-width: 3839px) {
    .flex-table-header {
      padding: 0 2rem;
      & div {
        font-size: 1rem;
        
        & svg {
          width: 20px;
          height: auto;
        }
      }
    }
    
    .grid-table-row {
      height: 4.5rem;
      padding: 0.5rem .5rem;
      border-radius: 4rem;
      font-size: 1.1rem;
      
      & div{
        height: 5rem;
      }
      & svg {
        height: auto;
        width: 20px;
      }
    }
  }
  @media (min-width: 3840px){
    .flex-table-header {
      padding: 0 2rem;
      & div {
        font-size: 0.85rem;
        
        & svg {
          width: 30px;
          height: auto;
        }
      }
    }
    
    .grid-table-row {
      height: 4.5rem;
      padding: 0.5rem .5rem;
      border-radius: 4rem;
      
      & div{
        height: 5rem;
      }
      & svg {
        height: auto;
        width: 39px;
      }
    }
  }
`

const Wrapper = styled.div`
  .toggle {
    transform-origin: left center;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: row;
    width: 60vh;
    height: 4em;
    transform: rotate(90deg) translate(calc(-50% - 2em), -50%);
    transition: opacity 0.4s ease-in-out;
    border-radius: 1rem 1rem 0 0;
  }

  .toggleButton {
    width: 50% !important;
    padding: 1rem;
    color: white;
    border-radius: 1rem 1rem 0 0;
  }

  .filterIcon {
    z-index: 2;
    position: absolute;
    border-radius: 100%;
    left: 26.5%;
    bottom: 38%;
    margin: 2rem;
    border: 1px solid var(--accent);
    transform: scale(0.75);
  }

  .toggleIcon {
    z-index: 2;
    position: absolute;
    border-radius: 100%;
    left: 29%;
    bottom: 38%;
    margin: 2rem;
    border: 1px solid var(--accent);
    transform: scale(0.75);
  }

  .exploreBtn {
    z-index: 1;
    position: absolute;
    border: 3px solid var(--primary);
    border-bottom: none;
    left: calc(23vw);
    max-width: 12rem;
    top: 8rem;
    color: var(--primary);
    transform-origin: left top;
    border-radius: 1rem 1rem 0 0;
    background-color: var(--secondary);
    cursor: pointer;
  }
  
  @media (max-width: 480px) {
    .exploreBtn{
      height: 5rem !important;
      max-width: 12rem;
      font-size: 1.2rem;
    }
  }
  @media (max-width: 720px) {
    .exploreBtn{
      height: 5rem !important;
      max-width: 12rem;
      font-size: 1.2rem;
    }
  }
  @media (max-width: 1080px) {
    .exploreBtn{
      height: 5rem !important;
      max-width: 12rem;
      font-size: 1.2rem;
    }
  }
`
