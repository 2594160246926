import { useStore } from "@state/store"
import { filterTable, getFiltersDynamically } from "@lib/utils/residenceTableFn"
import { useEffect, useMemo, useState } from "react"
import { temp_data } from "@data/temp_data"

export type TFilters = {
  floor: number[]
  price: number[]
  beds: string[]
  baths: string[]
  blocks: string | null
}

export const useFilters = () => {
  const parseTSV = (tsvData) => {
    const rows = tsvData.trim().split("\n") // Split the data into rows
    const headers = rows[0].split("\t") // Get headers from the first row

    // Map each row to an object using headers as keys
    const jsonData = rows.slice(1).map((row) => {
      const values = row.split("\t")
      return headers.reduce((obj, header, index) => {
        obj[header] = values[index] || null // Set to null if empty
        return obj
      }, {})
    })

    return jsonData
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://docs.google.com/spreadsheets/d/e/2PACX-1vQJK1zNAu0OguHotumVzCK2nnM9O8Aq_SBqg3iwj31_xceNb3Kvr54B2VwUGolIojKRgHWugA3uTDOF/pub?gid=1001685203&single=true&output=tsv",
      )

      if (!response.ok) {
        throw new Error("Failed to fetch apartments")
      }

      const data = await response.text()
    }
    fetchData()
  }, [])

  const activeType = useStore((s) => s.activeType)

  // const availabiltyData = useProjectDataStore((s) => s.availabilityData);
  const availabiltyData = temp_data
  const [initialFilters, setInitialFilters] = useState(null)

  const selectedUnit = useStore((s) => s.selectedUnit)
  const { syncState } = useStore((s) => ({
    syncState: s.syncState,
  }))

  const filters = useStore((s) => s.filters)

  useEffect(() => {
    if (selectedUnit !== null && selectedUnit?.length !== 0) {
      const block = selectedUnit[0].unitName.split("-")[0]

      if (!block) return

      const currentBlocks = filters.blocks || []

      if (!currentBlocks.includes(block)) {
        syncState({
          filters: {
            ...filters,
            blocks: [...currentBlocks, block],
          },
        })
      }
    }
  }, [selectedUnit])

  useEffect(() => {
    const filteredData = filterTable(filters, availabiltyData, activeType)

    setTimeout(() => {
      syncState({
        filteredUnits: filteredData,
      })
    }, 250)
  }, [filters, selectedUnit, activeType])

  // useEffect(() => {
  //   if (selectedUnit?.length) {
  //     const unit = selectedUnit[0]
  //     const block = unit.unitName.split("-")[0]

  //     if (filters.blocks && !filters.blocks.includes(block)) {
  //       syncState({
  //         selectedUnit: [],
  //       })
  //       return
  //     }

  //     if (
  //       filters.price &&
  //       (unit.price < filters.price[0] || unit.price > filters.price[1])
  //     ) {
  //       syncState({
  //         selectedUnit: [],
  //       })
  //       return
  //     }

  //     if (
  //       filters.floor &&
  //       (unit.floor < filters.floor[0] || unit.floor > filters.floor[1])
  //     ) {
  //       syncState({
  //         selectedUnit: [],
  //       })
  //       return
  //     }

  //     if (filters.beds && !filters.beds.includes(unit.beds)) {
  //       syncState({
  //         selectedUnit: [],
  //       })
  //       return
  //     }
  //   }
  // }, [filters, selectedUnit])

  const handleReset = () => {
    setTimeout(() => {
      syncState({
        filters: initialFilters,
      })
    }, 250)
  }

  const handleToggleChange = (
    key: keyof TFilters,
    value: any,
    onlyOneSelectable: boolean,
  ) => {
    const currentValues = filters[key] || []

    const updatedValues = onlyOneSelectable
      ? currentValues.includes(value)
        ? []
        : [value]
      : currentValues.includes(value)
        ? currentValues.filter((item) => item !== value)
        : [...currentValues, value]

    setTimeout(() => {
      syncState({
        filters: {
          ...filters,
          [key]: updatedValues,
        },
      })
    }, 250)
  }

  const handleRangeChange = (key: keyof TFilters, value: any) => {
    setTimeout(() => {
      syncState({
        filters: {
          ...filters,
          [key]: value,
        },
      }),
        250
    })
  }

  const dynamicFilters = useMemo(() => {
    const dynamicFilters = getFiltersDynamically(availabiltyData)
    setInitialFilters(dynamicFilters)
    return dynamicFilters
  }, [availabiltyData])

  return {
    initialFilters,
    dynamicFilters,
    handleToggleChange,
    filters,
    handleRangeChange,
    handleReset,
  }
}
