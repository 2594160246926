import React, { useState, useEffect, useLayoutEffect, useRef } from "react"
import { Place, POI, useMapStore } from "../mapStore" // Assuming Place is a type defined elsewhere
import styled from "styled-components/macro"
import { Cross2Icon, Crosshair2Icon } from "@radix-ui/react-icons"

type SearchBarProps = {
  mainMarker: POI
  onResultItemClick: (place: POI) => void
}

const calculateBoundingBox = (
  coordinates: { lat: number; lng: number },
  miles: number = 10,
) => {
  const milesToDegrees = miles / 69 // Rough conversion (1 degree ~ 69 miles)

  return {
    north: coordinates.lat + milesToDegrees, // Latitude increases north
    south: coordinates.lat - milesToDegrees, // Latitude decreases south
    east:
      coordinates.lng +
      milesToDegrees / Math.cos(coordinates.lat * (Math.PI / 180)), // Adjust longitude based on latitude
    west:
      coordinates.lng -
      milesToDegrees / Math.cos(coordinates.lat * (Math.PI / 180)), // Adjust longitude based on latitude
  }
}

const SearchBar: React.FC<SearchBarProps> = ({
  mainMarker,
  onResultItemClick,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [inputValue, setInputValue] = useState<string>("")
  const [suggestions, setSuggestions] = useState<any[]>([])
  const [prediction, setPrediction] = useState<string | null>(null)
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const setActiveSection = useMapStore((state) => state.setActiveSection)
  const [sessionToken, setSessionToken] =
    useState<google.maps.places.AutocompleteSessionToken | null>(null)
  const setActivePOI = useMapStore((s) => s.setActivePOI)

  const refreshToken = async () => {
    try {
      // @ts-ignore
      const { AutocompleteSessionToken } = (await google.maps.importLibrary(
        "places",
      )) as google.maps.PlacesLibrary

      const newToken = new AutocompleteSessionToken()
      setSessionToken(newToken)
    } catch (error) {
      console.error("Error refreshing token:", error)
    }
  }

  const clearInput = () => {
    setInputValue("")
    setPrediction(null)
    setSuggestions([])
    setActiveSection("pois")
    inputRef.current?.focus() // Focus back on the input field
    setActivePOI(null)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
    setIsFetching(true)
  }

  // this is to prevent the search bar from fetching suggestions that are too far away
  // from the main marker (e.g. in a different city)
  // now it's set to a 10 mile radius
  const boundingBox = calculateBoundingBox(mainMarker?.coordinates, 10)

  const fetchSuggestions = async (input: string) => {
    if (!input || !sessionToken) return

    try {
      // @ts-ignore
      const { AutocompleteSuggestion } = (await google.maps.importLibrary(
        "places",
      )) as google.maps.PlacesLibrary

      // Define the request with location restriction, origin, and other parameters
      let request = {
        input: input,
        locationRestriction: boundingBox,
        origin: mainMarker?.coordinates,
        language: "en-GB",
        region: "UK",
        sessionToken: sessionToken,
      }

      const { suggestions: fetchedSuggestions } =
        await AutocompleteSuggestion.fetchAutocompleteSuggestions(request)

      setSuggestions(fetchedSuggestions)
    } catch (error) {
      console.error("Error fetching autocomplete suggestions:", error)
    }
  }

  // on click of a suggestion, fetch the place details and update the prediction
  const onPlaceSelected = async (placePrediction: any) => {
    try {
      const place = placePrediction.toPlace()
      await place.fetchFields({
        fields: ["displayName", "formattedAddress", "location", "photos"],
      })

      const lat = place?.location.lat()
      const lng = place?.location.lng()
      // console.log("PLACE", place)
      // console.log("LOCATION", place?.location)
      // console.log("PHOTOS", place?.photos[0])
      // console.log("PHOTOS", place?.photos[0].getURI())

      // Update the input value but prevent suggestion fetching
      setInputValue(place.displayName)
      setSuggestions([])
      setIsFetching(false) // Disable fetching suggestions after place selection
      setPrediction(`${place.formattedAddress}`)

      // Trigger the onClick with the place details
      onResultItemClick({
        place: place.displayName,
        coordinates: { lat, lng },
        custom: true,
        image: place?.photos[0].getURI(),
      })

      // Refresh the session token for the next session after place selection
      refreshToken()
    } catch (error) {
      console.error("Error fetching place details:", error)
    }
  }

  useEffect(() => {
    refreshToken()
  }, [])

  useEffect(() => {
    if (inputValue && isFetching) {
      const timeoutId = setTimeout(() => {
        fetchSuggestions(inputValue)
      }, 300) // debounce

      return () => clearTimeout(timeoutId)
    }
  }, [inputValue, isFetching])

  return (
    <SearchBarContainer>
      <InputContainer>
        <StyledInput
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Search for places..."
        />
        {inputValue && (
          <ClearIcon onClick={clearInput}>
            <Cross2Icon />
          </ClearIcon>
        )}
      </InputContainer>

      {suggestions.length > 0 && (
        <SuggestionsList>
          {suggestions.map((suggestion, index) => (
            <SuggestionItem
              key={index}
              onClick={() => {
                onPlaceSelected(suggestion.placePrediction)
                setActiveSection("directions")
              }}
            >
              {suggestion.placePrediction.text.toString()}
            </SuggestionItem>
          ))}
        </SuggestionsList>
      )}

      {prediction && (
        <PredictionDisplay>
          {prediction}
          {/* <Crosshair2Icon
            height={30}
            width={40}
            color="rgb(0 0 0 / 50%)"
            style={{
              border: "1px solid rgb(0 0 0 / 30%)",
              borderRadius: "50px",
              padding: "5px 10px",
            }}
            onClick={() => setActiveSection("directions")}
          /> */}
        </PredictionDisplay>
      )}
    </SearchBarContainer>
  )
}

export default SearchBar

const SearchBarContainer = styled.div`
  position: absolute;
  z-index: 10;
  top: 3em;
  right: 4em;
  width: 300px;
`

const InputContainer = styled.div`
  position: relative;
`

const StyledInput = styled.input`
  padding: 10px;
  width: 100%;
  height: 4em;
  border-radius: 10rem;
  padding-left: 2rem;
  border: 1px solid #ccc;
  font-size: 1em;
  font-family: inherit;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  color: var(--primary);

  ::placeholder {
    color: var(--primary);
    opacity: 0.6;
  }
  &:focus {
    outline: none;
    border-color: var(--accent);
    box-shadow: 0px 0px 8px rgba(235, 179, 247, 0.5);
  }
`

const ClearIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #888;

  &:hover {
    color: #333;
  }
`

// Styled components for the suggestion list container
const SuggestionsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 10px;
  max-height: 250px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  position: absolute;
  width: 100%;
  z-index: 10;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`

// Styled components for the list item
const SuggestionItem = styled.li`
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  font-size: 1;
  color: var(--primary);
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f1f1f1;
  }

  &:last-child {
    border-bottom: none;
  }
`

const PredictionDisplay = styled.div`
  padding: 10px;
  height: 4em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1;
  color: var(--primary);
  margin-top: 10px;
  gap: 4em;
`
