import styled from "styled-components"
import { useLayoutEffect, useRef, useState } from "react"

import TextButton from "@common/ui/TextButton"

const ShowHome = () => {
  const [activeTour, setActiveTour] = useState("one")
  const [tourLoaded, setTourLoaded] = useState(false)
  const links = {
    one: "https://my.matterport.com/show/?m=kJ1vUAJwVsD",
    two: "https://my.matterport.com/show/?m=aJoW2Z4KRx5",
  }
  const frameRef = useRef<HTMLIFrameElement>(null)

  function handleTourChange(tour) {
    setTourLoaded(false)
    setTimeout(() => {
      setActiveTour(tour)
    }, 410)
  }

  return (
    <ShowHomeWrapper>
      <Prompt>Loading ...</Prompt>
      <Iframe
        ref={frameRef}
        src={links[activeTour]}
        style={{ opacity: tourLoaded ? 1 : 0 }}
        onLoad={(e) => {
          setTourLoaded(true)
        }}
      />
      <Blocker />
      <Controls>
        <TextButton
          style={{
            height: "4em",
            width: "50%",
            margin: 0,
            opacity: activeTour === "one" ? 1 : 0.5,
          }}
          onClick={() => handleTourChange("one")}
        >
          One Bedroom
        </TextButton>
        <TextButton
          style={{
            height: "4em",
            width: "50%",
            margin: 0,
            opacity: activeTour === "two" ? 1 : 0.5,
          }}
          onClick={() => handleTourChange("two")}
        >
          Two Bedroom
        </TextButton>
      </Controls>
    </ShowHomeWrapper>
  )
}

export default ShowHome

const ShowHomeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  background-image: url("images/westHamSplash-2.jpg");
`

const Controls = styled.div`
  position: absolute;
  bottom: 2rem;
  left: 50%;
  display: flex;
  z-index: 10;
  width: calc(45% - 6rem);
  transform: translateX(-50%);
  background-color: var(--primary);

  @media (min-width: 721px) and (max-width: 1080px) {
    & div {
      height: 5rem !important;
      & span {
        font-size: 1.5rem;
      }
    }
  }
`
const Iframe = styled.iframe`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  transition: opacity 0.4s ease-in-out;
`

const Prompt = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  color: var(--white);
`

const Blocker = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 15vh;
  height: 15vh;
  z-index: 2;
  pointer-events: all;
`
