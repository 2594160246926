import styled from "styled-components";

type Props = {
  main: string;
  sub: string;

  align: "left" | "right" | "center";
  body?: string;
};
const Title = ({ main, sub, align, body }: Props) => {
  const alignment = {
    left: "flex-start",
    right: "flex-end",
    center: "center",
  };

  return (
    <TitleWr textAlign={align} flexAlign={alignment[align]}>
      <div className="bg" />
      <div
        style={{
          zIndex: 1,
          display: "flex",
          alignItems: "center",
          gap: "0.5em",
        }}
      >
        <div className="icon">
          <span />
        </div>
        <div>
          <h3>{main}</h3>
          <span>{sub}</span>
        </div>
      </div>
      <p>{body}</p>
    </TitleWr>
  );
};

export default Title;

const TitleWr = styled.div<{
  flexAlign: string;
  textAlign: string;
}>`
  display: flex;
  border: 1px solid var(--accent);
  padding: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: ${(p) => p.flexAlign};
  font-weight: 800;
  margin-left: auto;
  gap: 1rem;
  white-space: wrap;
  width: 100%;
  height: 100%;
  position: relative;
  width: fit-content;

  .explore:active {
    transform: scale(0.98);
    transition: 0.3s;
    opacity: 0.75;
  }

  .icon {
    width: 1.9em;
    height: 1.9em;
    transform: translateY(-0.2em);
    background-color: var(--accent);

    & span {
      display: inline-block;
      background-color: var(--primary);
      width: 100%;
      height: 100%;
      clip-path: polygon(0% 0%, 99% 50%, 0% 100%);
    }
  }

  .bg {
    background-color: var(--primary);
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
  }

  h3 {
    color: var(--green);
    font-size: 1.2em;
    text-align: ${(p) => p.textAlign};
    text-transform: uppercase;
    z-index: 1;
    font-weight: 400;
    line-height: 0.2em;
    white-space: nowrap;
  }

  p {
    color: #fff;
    font-size: 0.5em;
    padding-left: 5%;
    font-weight: 400;
    text-align: ${(p) => p.textAlign};
    z-index: 1;
    max-width: 100%;
    word-wrap: break-word;
  }

  span {
    font-size: 0.7em;
    font-family: secondaryFont;
    color: white;
    text-transform: lowercase;
    font-weight: 10;
    z-index: 1;
  }
`;
