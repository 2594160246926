import { useProjectDataStore } from "@state/projectDataStore"
import React from "react"

type Props = {
  fontSize?: number
  children: React.ReactNode
  fontColour?: string
  brandingData: any
} & React.ComponentPropsWithoutRef<"h1">

const ListItem: React.FC<Props> = ({
  fontSize = 3.25,
  fontColour = "white",
  children,
  brandingData,
  ...props
}) => {
  const borderStyle = brandingData?.borderStyle || "rounded"

  // const [isHovered, setIsHovered] = React.useState(false)

  // const handlePointerEnter = (
  //   event: React.PointerEvent<HTMLHeadingElement>,
  // ) => {
  //   setIsHovered(true)
  //   if (onPointerEnter) {
  //     onPointerEnter(event)
  //   }
  // }

  // const handlePointerLeave = () => {
  //   setIsHovered(false)
  // }

  // React.useEffect(() => {
  //   onHovered && onHovered()
  // }, [isHovered])

  return (
    <h1
      {...props}
      style={{
        cursor: "pointer",
        fontSize: `${fontSize}rem`,
        fontWeight: "bold",
        color: fontColour,
        borderRadius: borderStyle === "rounded" ? "20px" : "0px",
        ...props.style,
      }}
    >
      {children}
    </h1>
  )
}

export default ListItem
