import gsap from "gsap"
import AnimateCamera from "@lib/AnimateCamera/AnimateCamera"
import { useCallback, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { Canvas, useThree } from "@react-three/fiber"
import Compass from "./Compass"
import Labels from "./Labels"
import { useCanvasStore } from "@state/canvasStore"
import { handleContextLoss } from "./utils/context"
import { useLocation } from "react-router-dom"
import BuildingTouchPoints from "./BuildingTouchPoints"
import { Sky } from "@react-three/drei"
import { Gsc, UnitOverlayGeometry } from "vmi-gsc"
import { Color, Vector3 } from "three"
import { useStore } from "@state/store"
import { useGPUSaver } from "@src/hooks/useScreenTimeout"
import { runInvalidation } from "@lib/AnimateCamera/useCamera"
import Capture from "./Capture"

const LOW_END_DEVICE_MODE = true

const intitalCameraSettings = {
  animationDuration: 2,
  position: new Vector3(29.31, 11.35, -7.72),
  target: new Vector3(2, 5, -7),
  fov: 70,
  near: 0.05,
  far: 1000,
}

const moveCam = false
const initialControlsSettings = {
  rotateSpeed: 0.08,
  enableDamping: true,
  dampingFactor: 0.1,
  enableZoom: moveCam,
  enablePan: moveCam,
  enableRotate: moveCam,
  enableKeys: false,
  minPolarAngle: 0,
  maxPolarAngle: 1.5,
}

export const TCanvas = () => {
  const { pathname } = useLocation()

  const setCanvasRef = useCanvasStore((s) => s.setCanvasRef)
  const canvasRef = useRef(null)
  const canvasWrapperRef = useRef(null)

  useEffect(() => {
    setCanvasRef(canvasRef.current)
  }, [])

  const selectedUnit = useStore((s) => s.selectedUnit)
  const filteredUnits = useStore((s) => s.filteredUnits)

  const renderCanvasOn = ["/residences", "/orbits"]

  useEffect(() => {
    if (!renderCanvasOn.includes(pathname)) {
      gsap.to(canvasWrapperRef.current, {
        autoAlpha: 0,
        duration: 0.5,
      })
    } else {
      gsap.to(canvasWrapperRef.current, {
        autoAlpha: 1,
        duration: 0.5,
      })
    }
  }, [pathname])

  const scrolRef = useRef(0)

  const handleClickMesh = (unitData: any) => {
    const id = unitData.unitName
    const row = document.getElementById(id)
    const table = document.getElementById("table-rows-container")

    if (!row || !table) return

    gsap.fromTo(
      scrolRef,
      {
        current: table.scrollTop,
      },
      {
        current: row.offsetTop - innerHeight * 0.5 + 300,
        onUpdate: () => {
          table.scrollTo({
            top: scrolRef.current,
          })
        },
      },
    )

    row.click()
  }

  const onSelectedUnitChange = useCallback(() => {}, [])

  const {
    gpuSaverRef,
    frameLoop,
    handlePointerDown,
    handlePointerUp,
    handleResume,
    shouldRenderCanvas,
  } = useGPUSaver(pathname, renderCanvasOn, 5 * 60 * 1000)

  const orbit = {
    left: "0%",
    width: "100%",
  }
  const residnces = {
    left: "23%",
    width: "77%",
  }

  const [loaded, setLoading] = useState([])

  const refTest1 = useRef(0)
  const refTest2 = useRef(0)

  const progressBar = useRef(null)

  // const total = 47056320 + 172597792
  const total = 47056320

  const handleLoadingProgress = (progress) => {
    progressBar.current.innerHTML = `${Math.round(
      ((refTest1.current + refTest2.current) / total) * 100,
    )}%`
  }

  const loadingDiv = useRef(null)

  useEffect(() => {
    gsap.to(loadingDiv.current, {
      autoAlpha: loaded.length === 1 ? 0 : 1,
      delay: 0.2,
      duration: 0.5,
    })
  }, [loaded])

  const canvasImagePath = useCanvasStore((s) => s.canvasImagePath)
  console.log(canvasImagePath)
  return (
    <>
      <div
        ref={loadingDiv}
        style={{
          backgroundImage: "url(images/westHamSplash-2.jpg)",

          zIndex: 9999999999999,
          backgroundColor: "var(--primary)",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          gap: "2rem",
          color: "white",
        }}
      >
        <h1>Loading</h1>
        <h3 ref={progressBar}></h3>
      </div>

      <CanvasWrapper
        ref={canvasWrapperRef}
        style={{
          backgroundImage:
            pathname === "/orbits"
              ? `url(canvas-images/${canvasImagePath}.png`
              : "none",

          backgroundPosition: "center",
          backgroundSize: "cover",
          ...(pathname === "/residences" ? residnces : orbit),
        }}
        onPointerDown={handlePointerDown}
        onPointerUp={handlePointerUp}
      >
        {/* <GPUSaver ref={gpuSaverRef}>
        <button onClick={handleResume}>Resume</button>
        </GPUSaver> */}
        <Canvas
          gl={{ preserveDrawingBuffer: true }}
          ref={canvasRef}
          // frameloop={frameLoop}
          // frameloop={loaded.length === 2 ? "demand" : "always"}
          dpr={1.25}
          onCreated={({ gl }) => {
            handleContextLoss(gl)
          }}
        >
          {/* <Capture /> */}

          <SplatLoader
            loaded={loaded}
            handleLoadingProgress={handleLoadingProgress}
            refRest={refTest1}
            visible={pathname === "/residences" ? true : false}
            // visible={false}
            setLoading={setLoading}
            src="assets/models/site-01.splat"
          />

          {!LOW_END_DEVICE_MODE && (
            <group rotation-y={3.1}>
              <SplatLoader
                loaded={loaded}
                handleLoadingProgress={handleLoadingProgress}
                refRest={refTest2}
                // visible={pathname === "/orbits" ? true : false}
                visible={false}
                setLoading={setLoading}
                src="assets/models/master-cleaned.splat"
              />
            </group>
          )}

          {pathname === "/residences" && (
            <>
              {/* <GroundPlane /> */}
              {filteredUnits && (
                <>
                  <BuildingTouchPoints />
                  <UnitOverlayGeometry
                    colours={{
                      on: new Color("#ff8f62"),
                      off: new Color("#3c87ff"),
                    }}
                    selectedUnit={selectedUnit?.[0]}
                    onSelectedUnitChange={onSelectedUnitChange}
                    handleClickMesh={handleClickMesh}
                    data={filteredUnits}
                    glbSrc="assets/models/westham.glb"
                  />
                </>
              )}
            </>
          )}

          {pathname === "/orbits" && (
            <>
              <Labels />
              {!LOW_END_DEVICE_MODE && <Sky sunPosition={[2, 1, 0]} />}
            </>
          )}

          <AnimateCamera
            initialControlsSettings={initialControlsSettings}
            intitalCameraSettings={intitalCameraSettings}
          />
        </Canvas>
        <Compass offset={65} />
      </CanvasWrapper>
    </>
  )
}

const SplatLoader = ({
  src,
  setLoading,
  visible,
  refRest,
  handleLoadingProgress,
  loaded,
}) => {
  const thisSplat = src.split("/").pop()

  const invalidate = useThree().invalidate

  useEffect(() => {
    if (visible && loaded.includes(thisSplat)) {
      console.log("invalidate")
      runInvalidation(Date.now(), invalidate)
    }
  }, [visible, loaded])

  return (
    <Gsc
      visible={visible}
      onProgress={(progress) => {
        handleLoadingProgress()
        refRest.current = progress.loaded
      }}
      // visible={pathname === "/orbits" ? true : false}
      src={src}
      onLoaded={() => {
        setLoading((prevState) => {
          return [...prevState, thisSplat]
        })
      }}
    />
  )
}

const GPUSaver = styled.div`
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--primary);

  display: flex;
  justify-content: center;
  align-items: center;

  button {
    border: 1px solid white;
    border-radius: 40px;
    padding: 10px;
    color: white;
  }
`

const CanvasWrapper = styled.div`
  background-color: var(--primary);
  position: absolute;
  top: 0;

  height: 100%;
`

export default TCanvas

// function TestLoop() {
//   useFrame(() => {
//     // console.log("rendering")
//   })

//   return null
// }

function LogCam() {
  const { camera } = useThree()

  const logCam = () => {
    console.log(
      `new Vector3(${camera.position.x}, ${camera.position.y}, ${camera.position.z})`,
    )
  }

  useEffect(() => {
    window.addEventListener("click", logCam)

    return () => {
      window.removeEventListener("click", logCam)
    }
  }, [camera])

  return null
}
