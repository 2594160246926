import NavTextButton from "@common/ui/NavTextButton"
import { useStore } from "@state/store"
import { useState } from "react"
import styled from "styled-components/macro"
import Peabody from "./components/Peabody"
import Architects from "./components/Architects"
import Community from "./components/Community"
import { useProjectDataStore } from "@state/projectDataStore"

const aboutData = [
  {
    title: "Peabody",
    description: [
      {
        title: "Building History",
        desc: "Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam sem fringilla ut morbi tincidunt. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam se.",
      },
      {
        title: "Social Impact",
        desc: "Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam sem fringilla ut morbi tincidunt. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam se.",
      },
      {
        img: "/assets/imgs/about/1.jpg",
      },
      {
        img: "/assets/imgs/about/2.jpg",
      },
      {
        title: "Customer Focus",
        desc: "Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam sem fringilla ut morbi tincidunt. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam se.",
      },
      {
        title: "Quality and sustainability",
        desc: "Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam sem fringilla ut morbi tincidunt. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam se.",
      },
      {
        title: "Strong focus",
        desc: "Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam sem fringilla ut morbi tincidunt. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam se.",
      },
      {
        title: "Award Winning",
        desc: "Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam sem fringilla ut morbi tincidunt. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam se.",
      },
    ],
  },
  {
    title: "The Architects",
    main: "Architects",
    mainName: "Peter Taylor",
    quote:
      "The vision was to create a well-connected city quarter with nature at its core parkland living, with London at your doorstep.",
    by: "Andrew Taylor & Pankaj Patel",
    by2: "FOUNDING DIRECTORS, PATEL TAYLOR",
    img: "/assets/imgs/about/3.jpg",
    stats: [
      {
        text: "70%",
        type: "Statistic Info",
      },
      {
        text: "5k",
        type: "Statistic Info",
      },
      {
        text: "100+",
        type: "Statistic Info",
      },
    ],
    description: [
      {
        title: "Customer focus",
        desc: "Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam sem fringilla ut morbi tincidunt. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam se.",
      },
      {
        title: "Award winning",
        desc: "Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam sem fringilla ut morbi tincidunt. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam se.",
      },
    ],
  },
  {
    title: "Community",
    description: [
      {
        title: "DOMINIC SCHNEIDER",
        img: "/assets/imgs/about/4.jpg",
        desc: "Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam sem fringilla ut morbi tincidunt. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam se.",
      },
      {
        title: "DOMINIC FIKE",
        img: "/assets/imgs/about/4.jpg",
        desc: "Vesti blandit cursus risus. In aliquam sem fringilla ut morbi tincidunt. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam se.",
      },
    ],
  },
]

const About = (props: any) => {
  const aboutIndex = useStore((s) => s.aboutIndex)
  const syncState = useStore((s) => s.syncState)
  const { aboutData } = useProjectDataStore((s) => ({
    aboutData: s.aboutData,
  }))

  return (
    <AboutWrapper>
      <Peabody aboutData={aboutData.sections[0]} />
      <Architects aboutData={aboutData.sections[1]} />
      <Community aboutData={aboutData.sections[2]} />
      <div className="aboutCategories">
        {aboutData.sections?.map((category, index) => (
          <NavTextButton
            className={`buttons ${index === aboutIndex && "active"}`}
            key={index}
            onClick={() => {
              syncState({ aboutIndex: index })
            }}
          >
            {category.title}
          </NavTextButton>
        ))}
      </div>
    </AboutWrapper>
  )
}

export default About

const AboutWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--peabodyTeal);
  display: flex;

  @media (max-width: 480px) {
    .aboutCategories{
      & div{
        border: none !important;
      }
    }
  }
  @media (max-width: 720px) {
    .aboutCategories{
      & div{
        border: none !important;
      }
    }
  }
`

const Step = styled.div``
