export const temp_data = [
    {
      "Flat Number": "201",
      "Plot Name": "201",
      "viewID": "A-B-3m",
      "aspect": "E",
      "unitName": "S01C-02-01",
      "unitType": "TYPE 1-1B/2P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "2",
      "beds": "1",
      "floor": "2",
      "Floor Area Sq. Ft.": "628.61",
      "Floor Area Sq. M.": "58.40",
      "price": "432500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "108125",
      "Max Percentage Tranche": "75",
      "Deposit": "5406",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "3.7 x 1.9 12’ 1” x 6’ 0”",
      "Living": null,
      "Bedroom 1": "4.3 x 2.9 13’ 11” x 9’ 6”",
      "Bedroom 2": null,
      "Total Internal Area": "58.40m² 629 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "202",
      "Plot Name": "202",
      "viewID": "C-D-12m",
      "aspect": "E/SW",
      "unitName": "S01C-02-02",
      "unitType": "TYPE 2-1B/2P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "2",
      "beds": "1",
      "floor": "2",
      "Floor Area Sq. Ft.": "636.15",
      "Floor Area Sq. M.": "59.10",
      "price": "442500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "110625",
      "Max Percentage Tranche": "75",
      "Deposit": "5531",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "2.2 x 3.2 7’ 2” x 10’ 5”",
      "Living": null,
      "Bedroom 1": "4.1 x 3.2 13’ 5” x 10’ 5”",
      "Bedroom 2": null,
      "Total Internal Area": "59.10m² 637 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "203",
      "Plot Name": "203",
      "viewID": "A-B-3m",
      "aspect": "SW",
      "unitName": "S01C-02-03",
      "unitType": "TYPE 1-2B/4P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "2",
      "beds": "2",
      "floor": "2",
      "Floor Area Sq. Ft.": "833.13",
      "Floor Area Sq. M.": "77.40",
      "price": "542500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "135625",
      "Max Percentage Tranche": "75",
      "Deposit": "6781",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "4.4 x 1.9 14’ 6’’ x 6’ 2”",
      "Living": null,
      "Bedroom 1": "2.8 x 4.5 9’ 1” x 14’ 8”",
      "Bedroom 2": "3.7 x 3.3 12’ 1” x 10’ 8”",
      "Total Internal Area": "77.40m² 833 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "204",
      "Plot Name": "204",
      "viewID": "C-D-12m",
      "aspect": "SW/NW",
      "unitName": "S01C-02-04",
      "unitType": "TYPE 2 V-2B/3P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "2",
      "beds": "2",
      "floor": "2",
      "Floor Area Sq. Ft.": "736.25",
      "Floor Area Sq. M.": "68.40",
      "price": "532500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "133125",
      "Max Percentage Tranche": "75",
      "Deposit": "6656",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": null,
      "Living": "3.3 x 3.6 10’ 9” x 11’ 11”",
      "Bedroom 1": "3.9 x 3.3 12’ 10” x 10’ 8”",
      "Bedroom 2": "2.9 x 3.0 9’ 4” x 9’ 10”",
      "Total Internal Area": "68.4m² 736 ft²",
      "Total Terrace Area": "8.5m² 92 ft²"
   },
    {
      "Flat Number": "205",
      "Plot Name": "205",
      "viewID": "A-B-3m",
      "aspect": "NW/E",
      "unitName": "S01C-02-05",
      "unitType": "TYPE 1-STUDIO",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "2",
      "beds": "0",
      "floor": "2",
      "Floor Area Sq. Ft.": "553.26",
      "Floor Area Sq. M.": "51.40",
      "price": "387500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "96875",
      "Max Percentage Tranche": "75",
      "Deposit": "4844",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "2.9 x 1.9 9’ 4” x 6’ 2”",
      "Living": null,
      "Bedroom 1": "4.1 x 2.7 13’ 4” x 8’ 10”",
      "Bedroom 2": null,
      "Total Internal Area": "51.40m² 554 ft²",
      "Total Terrace Area": "18.5m² 198 ft²"
   },
    {
      "Flat Number": "301",
      "Plot Name": "301",
      "viewID": "C-D-12m",
      "aspect": "E",
      "unitName": "S01C-03-01",
      "unitType": "TYPE 1-1B/2P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "3",
      "beds": "1",
      "floor": "3",
      "Floor Area Sq. Ft.": "628.61",
      "Floor Area Sq. M.": "58.40",
      "price": "435000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "108750",
      "Max Percentage Tranche": "75",
      "Deposit": "5438",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "3.7 x 1.9 12’ 1” x 6’ 0”",
      "Living": null,
      "Bedroom 1": "4.3 x 2.9 13’ 11” x 9’ 6”",
      "Bedroom 2": null,
      "Total Internal Area": "58.40m² 629 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "302",
      "Plot Name": "302",
      "viewID": "A-B-3m",
      "aspect": "E/SW",
      "unitName": "S01C-03-02",
      "unitType": "TYPE 3-2B/3P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "3",
      "beds": "2",
      "floor": "3",
      "Floor Area Sq. Ft.": "777.15",
      "Floor Area Sq. M.": "72.20",
      "price": "500000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "125000",
      "Max Percentage Tranche": "75",
      "Deposit": "6250",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": null,
      "Living": "3.1 x 4.4 10’ 2” x 14’ 6”",
      "Bedroom 1": "4.0 x 4.0 13’ 2” x 13’ 0”",
      "Bedroom 2": "3.5 x 2.3 11’ 5” x 7’ 7”",
      "Total Internal Area": "72.00m² 775 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "303",
      "Plot Name": "303",
      "viewID": "C-D-12m",
      "aspect": "SW",
      "unitName": "S01C-03-03",
      "unitType": "TYPE 1-2B/4P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "3",
      "beds": "2",
      "floor": "3",
      "Floor Area Sq. Ft.": "833.13",
      "Floor Area Sq. M.": "77.40",
      "price": "545000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "136250",
      "Max Percentage Tranche": "75",
      "Deposit": "6813",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "4.4 x 1.9 14’ 6” x 6’ 2”",
      "Living": null,
      "Bedroom 1": "2.8 x 4.5 9’ 1” x 14’ 8”",
      "Bedroom 2": "3.7 x 3.3 12’ 1” x 10’ 8”",
      "Total Internal Area": "77.40m² 833 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "304",
      "Plot Name": "304",
      "viewID": "A-B-3m",
      "aspect": "SW/NW",
      "unitName": "S01C-03-04",
      "unitType": "TYPE 2-2B/3P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "3",
      "beds": "2",
      "floor": "3",
      "Floor Area Sq. Ft.": "736.25",
      "Floor Area Sq. M.": "68.40",
      "price": "500000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "125000",
      "Max Percentage Tranche": "75",
      "Deposit": "6250",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": null,
      "Living": "3.3 x 3.6 10’ 9” x 11’ 11”",
      "Bedroom 1": "3.9 x 3.3 12’ 10” x 10’ 8”",
      "Bedroom 2": "2.9 x 3.0 9’ 4” x 9’ 10”",
      "Total Internal Area": "68.40m² 736 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "305",
      "Plot Name": "305",
      "viewID": "C-D-12m",
      "aspect": "NW/E",
      "unitName": "S01C-03-05",
      "unitType": "TYPE 3-1B/2P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "3",
      "beds": "1",
      "floor": "3",
      "Floor Area Sq. Ft.": "653.37",
      "Floor Area Sq. M.": "60.70",
      "price": "440000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "110000",
      "Max Percentage Tranche": "75",
      "Deposit": "5500",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "3.7 x 2.9 12’ 0” x 9’ 7”",
      "Living": null,
      "Bedroom 1": "3.3 x 3.7 10’ 8” x 12’ 0”",
      "Bedroom 2": null,
      "Total Internal Area": "58.40m² 629 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "401",
      "Plot Name": "401",
      "viewID": "A-B-3m",
      "aspect": "E",
      "unitName": "S01C-04-01",
      "unitType": "TYPE 1-1B/2P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "4",
      "beds": "1",
      "floor": "4",
      "Floor Area Sq. Ft.": "628.61",
      "Floor Area Sq. M.": "58.40",
      "price": "435000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "108750",
      "Max Percentage Tranche": "75",
      "Deposit": "5438",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "3.7 x 1.9 12’ 1” x 6’ 0”",
      "Living": null,
      "Bedroom 1": "4.3 x 2.9 13’ 11” x 9’ 6”",
      "Bedroom 2": null,
      "Total Internal Area": "58.40m² 629 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "402",
      "Plot Name": "402",
      "viewID": "C-D-12m",
      "aspect": "E/SW",
      "unitName": "S01C-04-02",
      "unitType": "TYPE 2-2B/4P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "4",
      "beds": "2",
      "floor": "4",
      "Floor Area Sq. Ft.": "881.56",
      "Floor Area Sq. M.": "81.90",
      "price": "550000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "137500",
      "Max Percentage Tranche": "75",
      "Deposit": "6875",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "2.7 x 3.7 9’ 0” x 12’ 2”",
      "Living": null,
      "Bedroom 1": "3.1 x 4.0 10’ 1” x 13’ 0”",
      "Bedroom 2": "4.8 x 2.9 15’ 10” x 9’ 4”",
      "Total Internal Area": "81.90m² 882 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "403",
      "Plot Name": "403",
      "viewID": "A-B-3m",
      "aspect": "SW",
      "unitName": "S01C-04-03",
      "unitType": "TYPE 1-2B/4P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "4",
      "beds": "2",
      "floor": "4",
      "Floor Area Sq. Ft.": "833.13",
      "Floor Area Sq. M.": "77.40",
      "price": "545000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "136250",
      "Max Percentage Tranche": "75",
      "Deposit": "6813",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "4.4 x 1.9 14’ 6” x 6’ 2”",
      "Living": null,
      "Bedroom 1": "2.8 x 4.5 9’ 1” x 14’ 8”",
      "Bedroom 2": "3.7 x 3.3 12’ 1” x 10’ 8”",
      "Total Internal Area": "77.40m² 833 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "404",
      "Plot Name": "404",
      "viewID": "C-D-12m",
      "aspect": "SW/NW",
      "unitName": "S01C-04-04",
      "unitType": "TYPE 2-2B/3P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "4",
      "beds": "2",
      "floor": "4",
      "Floor Area Sq. Ft.": "736.25",
      "Floor Area Sq. M.": "68.40",
      "price": "500000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "125000",
      "Max Percentage Tranche": "75",
      "Deposit": "6250",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": null,
      "Living": "3.3 x 3.6 10’ 9” x 11’ 11”",
      "Bedroom 1": "3.9 x 3.3 12’ 10”x 10’ 8”",
      "Bedroom 2": "2.9 x 3.0 9’ 4”x 9’ 10”",
      "Total Internal Area": "68.40m² 736 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "405",
      "Plot Name": "405",
      "viewID": "A-B-3m",
      "aspect": "NW/E",
      "unitName": "S01C-04-05",
      "unitType": "TYPE 3-1B/2P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "4",
      "beds": "1",
      "floor": "4",
      "Floor Area Sq. Ft.": "653.37",
      "Floor Area Sq. M.": "60.70",
      "price": "440000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "110000",
      "Max Percentage Tranche": "75",
      "Deposit": "5500",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "3.7 x 2.9 12’ 0” x 9’ 7”",
      "Living": null,
      "Bedroom 1": "3.3 x 3.7 10’ 8” x 12’ 0”",
      "Bedroom 2": null,
      "Total Internal Area": "58.40m² 629 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "501",
      "Plot Name": "501",
      "viewID": "C-D-12m",
      "aspect": "E",
      "unitName": "S01C-05-01",
      "unitType": "TYPE 1-1B/2P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "5",
      "beds": "1",
      "floor": "5",
      "Floor Area Sq. Ft.": "628.61",
      "Floor Area Sq. M.": "58.40",
      "price": "437500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "109375",
      "Max Percentage Tranche": "75",
      "Deposit": "5469",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "3.7 x 1.9 12’ 1” x 6’ 0”",
      "Living": null,
      "Bedroom 1": "4.3 x 2.9 13’ 11” x 9’ 6”",
      "Bedroom 2": null,
      "Total Internal Area": "58.40m² 629 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "502",
      "Plot Name": "502",
      "viewID": "A-B-3m",
      "aspect": "E/SW",
      "unitName": "S01C-05-02",
      "unitType": "TYPE 2-2B/4P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "5",
      "beds": "2",
      "floor": "5",
      "Floor Area Sq. Ft.": "881.56",
      "Floor Area Sq. M.": "81.90",
      "price": "552500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "138125",
      "Max Percentage Tranche": "75",
      "Deposit": "6906",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "2.7 x 3.7 9’ 0” x 12’ 2”",
      "Living": null,
      "Bedroom 1": "3.1 x 4.0 10’ 1” x 13’ 0”",
      "Bedroom 2": "4.8 x 2.9 15’ 10”x 9’ 4”",
      "Total Internal Area": "81.90m² 882 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "503",
      "Plot Name": "503",
      "viewID": "C-D-12m",
      "aspect": "SW",
      "unitName": "S01C-05-03",
      "unitType": "TYPE 1-2B/4P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "5",
      "beds": "2",
      "floor": "5",
      "Floor Area Sq. Ft.": "833.13",
      "Floor Area Sq. M.": "77.40",
      "price": "547500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "136875",
      "Max Percentage Tranche": "75",
      "Deposit": "6844",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "4.4 x 1.9 14’ 6’’ x 6’ 2”",
      "Living": null,
      "Bedroom 1": "2.8 x 4.5 9’ 1” x 14’ 8”",
      "Bedroom 2": "3.7 x 3.3 12’ 1” x 10’ 8”",
      "Total Internal Area": "77.40m² 833 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "504",
      "Plot Name": "504",
      "viewID": "A-B-3m",
      "aspect": "SW/NW",
      "unitName": "S01C-05-04",
      "unitType": "TYPE 2-2B/3P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "5",
      "beds": "2",
      "floor": "5",
      "Floor Area Sq. Ft.": "736.25",
      "Floor Area Sq. M.": "68.40",
      "price": "502500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "125625",
      "Max Percentage Tranche": "75",
      "Deposit": "6281",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": null,
      "Living": "3.3 x 3.6 10’ 9” x 11’ 11”",
      "Bedroom 1": "3.9 x 3.3 12’ 10”x 10’ 8”",
      "Bedroom 2": "2.9 x 3.0 9’ 4”x 9’ 10”",
      "Total Internal Area": "68.40m² 736 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "505",
      "Plot Name": "505",
      "viewID": "C-D-12m",
      "aspect": "NW/E",
      "unitName": "S01C-05-05",
      "unitType": "TYPE 3-1B/2P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "5",
      "beds": "1",
      "floor": "5",
      "Floor Area Sq. Ft.": "653.37",
      "Floor Area Sq. M.": "60.70",
      "price": "442500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "110625",
      "Max Percentage Tranche": "75",
      "Deposit": "5531",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "3.7 x 2.9 12’ 0” x 9’ 7”",
      "Living": null,
      "Bedroom 1": "3.3 x 3.7 10’ 8” x 12’ 0”",
      "Bedroom 2": null,
      "Total Internal Area": "58.40m² 629 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "601",
      "Plot Name": "601",
      "viewID": "A-B-3m",
      "aspect": "E",
      "unitName": "S01C-06-01",
      "unitType": "TYPE 1-1B/2P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "6",
      "beds": "1",
      "floor": "6",
      "Floor Area Sq. Ft.": "628.61",
      "Floor Area Sq. M.": "58.40",
      "price": "437500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "109375",
      "Max Percentage Tranche": "75",
      "Deposit": "5469",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "3.7 x 1.9 12’ 1” x 6’ 0”",
      "Living": null,
      "Bedroom 1": "4.3 x 2.9 13’ 11” x 9’ 6”",
      "Bedroom 2": null,
      "Total Internal Area": "58.40m² 629 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "602",
      "Plot Name": "602",
      "viewID": "C-D-12m",
      "aspect": "E/SW",
      "unitName": "S01C-06-02",
      "unitType": "TYPE 2-2B/4P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "6",
      "beds": "2",
      "floor": "6",
      "Floor Area Sq. Ft.": "881.56",
      "Floor Area Sq. M.": "81.90",
      "price": "552500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "138125",
      "Max Percentage Tranche": "75",
      "Deposit": "6906",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "2.7 x 3.7 9’ 0” x 12’ 2”",
      "Living": null,
      "Bedroom 1": "3.1 x 4.0 10’ 1” x 13’ 0”",
      "Bedroom 2": "4.8 x 2.9 15’ 10”x 9’ 4”",
      "Total Internal Area": "81.90m² 882 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "603",
      "Plot Name": "603",
      "viewID": "A-B-3m",
      "aspect": "SW",
      "unitName": "S01C-06-03",
      "unitType": "TYPE 1-2B/4P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "6",
      "beds": "2",
      "floor": "6",
      "Floor Area Sq. Ft.": "833.13",
      "Floor Area Sq. M.": "77.40",
      "price": "547500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "136875",
      "Max Percentage Tranche": "75",
      "Deposit": "6844",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "4.4 x 1.9 14’ 6’’ x 6’ 2”",
      "Living": null,
      "Bedroom 1": "2.8 x 4.5 9’ 1” x 14’ 8”",
      "Bedroom 2": "3.7 x 3.3 12’ 1” x 10’ 8”",
      "Total Internal Area": "77.40m² 833 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "604",
      "Plot Name": "604",
      "viewID": "C-D-12m",
      "aspect": "SW/NW",
      "unitName": "S01C-06-04",
      "unitType": "TYPE 2-2B/3P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "6",
      "beds": "2",
      "floor": "6",
      "Floor Area Sq. Ft.": "736.25",
      "Floor Area Sq. M.": "68.40",
      "price": "502500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "125625",
      "Max Percentage Tranche": "75",
      "Deposit": "6281",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": null,
      "Living": "3.3 x 3.6 10’ 9” x 11’ 11”",
      "Bedroom 1": "3.9 x 3.3 12’ 10”x 10’ 8”",
      "Bedroom 2": "2.9 x 3.0 9’ 4”x 9’ 10”",
      "Total Internal Area": "68.40m² 736 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "605",
      "Plot Name": "605",
      "viewID": "A-B-3m",
      "aspect": "NW/E",
      "unitName": "S01C-06-05",
      "unitType": "TYPE 3-1B/2P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "6",
      "beds": "1",
      "floor": "6",
      "Floor Area Sq. Ft.": "653.37",
      "Floor Area Sq. M.": "60.70",
      "price": "442500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "110625",
      "Max Percentage Tranche": "75",
      "Deposit": "5531",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "3.7 x 2.9 12’ 0” x 9’ 7”",
      "Living": null,
      "Bedroom 1": "3.3 x 3.7 10’ 8” x 12’ 0”",
      "Bedroom 2": null,
      "Total Internal Area": "58.40m² 629 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "701",
      "Plot Name": "701",
      "viewID": "C-D-12m",
      "aspect": "E",
      "unitName": "S01C-07-01",
      "unitType": "TYPE 1-1B/2P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "7",
      "beds": "1",
      "floor": "7",
      "Floor Area Sq. Ft.": "628.61",
      "Floor Area Sq. M.": "58.40",
      "price": "440000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "110000",
      "Max Percentage Tranche": "75",
      "Deposit": "5500",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "3.7 x 1.9 12’ 1” x 6’ 0”",
      "Living": null,
      "Bedroom 1": "4.3 x 2.9 13’ 11” x 9’ 6”",
      "Bedroom 2": null,
      "Total Internal Area": "58.40m² 629 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "702",
      "Plot Name": "702",
      "viewID": "A-B-3m",
      "aspect": "E/SW",
      "unitName": "S01C-07-02",
      "unitType": "TYPE 2-2B/4P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "7",
      "beds": "2",
      "floor": "7",
      "Floor Area Sq. Ft.": "881.56",
      "Floor Area Sq. M.": "81.90",
      "price": "555000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "138750",
      "Max Percentage Tranche": "75",
      "Deposit": "6938",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "2.7 x 3.7 9’ 0” x 12’ 2”",
      "Living": null,
      "Bedroom 1": "3.1 x 4.0 10’ 1” x 13’ 0”",
      "Bedroom 2": "4.8 x 2.9 15’ 10” x 9’ 4”",
      "Total Internal Area": "81.90m² 882 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "703",
      "Plot Name": "703",
      "viewID": "C-D-12m",
      "aspect": "SW",
      "unitName": "S01C-07-03",
      "unitType": "TYPE 1-2B/4P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "7",
      "beds": "2",
      "floor": "7",
      "Floor Area Sq. Ft.": "833.13",
      "Floor Area Sq. M.": "77.40",
      "price": "550000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "137500",
      "Max Percentage Tranche": "75",
      "Deposit": "6875",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "4.4 x 1.9 14’ 6’’ x 6’ 2”",
      "Living": null,
      "Bedroom 1": "2.8 x 4.5 9’ 1” x 14’ 8”",
      "Bedroom 2": "3.7 x 3.3 12’ 1” x 10’ 8”",
      "Total Internal Area": "77.40m² 833 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "704",
      "Plot Name": "704",
      "viewID": "A-B-3m",
      "aspect": "SW/NW",
      "unitName": "S01C-07-04",
      "unitType": "TYPE 2-2B/3P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "7",
      "beds": "2",
      "floor": "7",
      "Floor Area Sq. Ft.": "736.25",
      "Floor Area Sq. M.": "68.40",
      "price": "505000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "126250",
      "Max Percentage Tranche": "75",
      "Deposit": "6313",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": null,
      "Living": "3.3 x 3.6 10’ 9” x 11’ 11”",
      "Bedroom 1": "3.9 x 3.3 12’ 10” x 10’ 8”",
      "Bedroom 2": "2.9 x 3.0 9’ 4” x 9’ 10”",
      "Total Internal Area": "68.40m² 736 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "705",
      "Plot Name": "705",
      "viewID": "C-D-12m",
      "aspect": "NW/E",
      "unitName": "S01C-07-05",
      "unitType": "TYPE 3-1B/2P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "7",
      "beds": "1",
      "floor": "7",
      "Floor Area Sq. Ft.": "653.37",
      "Floor Area Sq. M.": "60.70",
      "price": "445000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "111250",
      "Max Percentage Tranche": "75",
      "Deposit": "5563",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "3.7 x 2.9 12’ 0” x 9’ 7”",
      "Living": null,
      "Bedroom 1": "3.3 x 3.7 10’ 8” x 12’ 0”",
      "Bedroom 2": null,
      "Total Internal Area": "58.40m² 629 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "801",
      "Plot Name": "801",
      "viewID": "A-B-3m",
      "aspect": "E",
      "unitName": "S01C-08-01",
      "unitType": "TYPE 1-1B/2P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "8",
      "beds": "1",
      "floor": "8",
      "Floor Area Sq. Ft.": "628.61",
      "Floor Area Sq. M.": "58.40",
      "price": "440000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "110000",
      "Max Percentage Tranche": "75",
      "Deposit": "5500",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "3.7 x 1.9 12’ 1” x 6’ 0”",
      "Living": null,
      "Bedroom 1": "4.3 x 2.9 13’ 11” x 9’ 6”",
      "Bedroom 2": null,
      "Total Internal Area": "58.40m² 629 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "802",
      "Plot Name": "802",
      "viewID": "C-D-12m",
      "aspect": "E/SW",
      "unitName": "S01C-08-02",
      "unitType": "TYPE 2-2B/4P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "8",
      "beds": "2",
      "floor": "8",
      "Floor Area Sq. Ft.": "881.56",
      "Floor Area Sq. M.": "81.90",
      "price": "555000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "138750",
      "Max Percentage Tranche": "75",
      "Deposit": "6938",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "2.7 x 3.7 9’ 0” x 12’ 2”",
      "Living": null,
      "Bedroom 1": "3.1 x 4.0 10’ 1” x 13’ 0”",
      "Bedroom 2": "4.8 x 2.9 15’ 10” x 9’ 4”",
      "Total Internal Area": "81.90m² 882 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "803",
      "Plot Name": "803",
      "viewID": "A-B-3m",
      "aspect": "SW",
      "unitName": "S01C-08-03",
      "unitType": "TYPE 1-2B/4P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "8",
      "beds": "2",
      "floor": "8",
      "Floor Area Sq. Ft.": "833.13",
      "Floor Area Sq. M.": "77.40",
      "price": "550000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "137500",
      "Max Percentage Tranche": "75",
      "Deposit": "6875",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "4.4 x 1.9 14’ 6’’ x 6’ 2”",
      "Living": null,
      "Bedroom 1": "2.8 x 4.5 9’ 1” x 14’ 8”",
      "Bedroom 2": "3.7 x 3.3 12’ 1” x 10’ 8”",
      "Total Internal Area": "77.40m² 833 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "804",
      "Plot Name": "804",
      "viewID": "C-D-12m",
      "aspect": "SW/NW",
      "unitName": "S01C-08-04",
      "unitType": "TYPE 2-2B/3P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "8",
      "beds": "2",
      "floor": "8",
      "Floor Area Sq. Ft.": "736.25",
      "Floor Area Sq. M.": "68.40",
      "price": "505000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "126250",
      "Max Percentage Tranche": "75",
      "Deposit": "6313",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": null,
      "Living": "3.3 x 3.6 10’ 9” x 11’ 11”",
      "Bedroom 1": "3.9 x 3.3 12’ 10” x 10’ 8”",
      "Bedroom 2": "2.9 x 3.0 9’ 4” x 9’ 10”",
      "Total Internal Area": "68.40m² 736 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "805",
      "Plot Name": "805",
      "viewID": "A-B-3m",
      "aspect": "NW/E",
      "unitName": "S01C-08-05",
      "unitType": "TYPE 3-1B/2P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "8",
      "beds": "1",
      "floor": "8",
      "Floor Area Sq. Ft.": "653.37",
      "Floor Area Sq. M.": "60.70",
      "price": "445000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "111250",
      "Max Percentage Tranche": "75",
      "Deposit": "5563",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "3.7 x 2.9 12’ 0” x 9’ 7”",
      "Living": null,
      "Bedroom 1": "3.3 x 3.7 10’ 8” x 12’ 0”",
      "Bedroom 2": null,
      "Total Internal Area": "58.40m² 629 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "901",
      "Plot Name": "901",
      "viewID": "C-D-12m",
      "aspect": "E",
      "unitName": "S01C-09-01",
      "unitType": "TYPE 1-1B/2P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "9",
      "beds": "1",
      "floor": "9",
      "Floor Area Sq. Ft.": "628.61",
      "Floor Area Sq. M.": "58.40",
      "price": "442500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "110625",
      "Max Percentage Tranche": "75",
      "Deposit": "5531",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "3.7 x 1.9 12’ 1” x 6’ 0”",
      "Living": null,
      "Bedroom 1": "4.3 x 2.9 13’ 11” x 9’ 6”",
      "Bedroom 2": null,
      "Total Internal Area": "58.40m² 629 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "902",
      "Plot Name": "902",
      "viewID": "A-B-3m",
      "aspect": "E/SW",
      "unitName": "S01C-09-02",
      "unitType": "TYPE 2-2B/4P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "9",
      "beds": "2",
      "floor": "9",
      "Floor Area Sq. Ft.": "881.56",
      "Floor Area Sq. M.": "81.90",
      "price": "557500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "139375",
      "Max Percentage Tranche": "75",
      "Deposit": "6969",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "2.7 x 3.7 9’ 0” x 12’ 2”",
      "Living": null,
      "Bedroom 1": "3.1 x 4.0 10’ 1” x 13’ 0”",
      "Bedroom 2": "4.8 x 2.9 15’ 10” x 9’ 4”",
      "Total Internal Area": "81.90m² 882 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "903",
      "Plot Name": "903",
      "viewID": "C-D-12m",
      "aspect": "SW",
      "unitName": "S01C-09-03",
      "unitType": "TYPE 1-2B/4P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "9",
      "beds": "2",
      "floor": "9",
      "Floor Area Sq. Ft.": "833.13",
      "Floor Area Sq. M.": "77.40",
      "price": "552500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "138125",
      "Max Percentage Tranche": "75",
      "Deposit": "6906",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "4.4 x 1.9 14’ 6” x 6’ 2”",
      "Living": null,
      "Bedroom 1": "2.8 x 4.5 9’ 1” x 14’ 8”",
      "Bedroom 2": "3.7 x 3.3 12’ 1” x 10’ 8”",
      "Total Internal Area": "77.40m² 833 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "904",
      "Plot Name": "904",
      "viewID": "A-B-3m",
      "aspect": "SW/NW",
      "unitName": "S01C-09-04",
      "unitType": "TYPE 2-2B/3P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "9",
      "beds": "2",
      "floor": "9",
      "Floor Area Sq. Ft.": "736.25",
      "Floor Area Sq. M.": "68.40",
      "price": "507500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "126875",
      "Max Percentage Tranche": "75",
      "Deposit": "6344",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": null,
      "Living": "3.3 x 3.6 10’ 9” x 11’ 11”",
      "Bedroom 1": "3.9 x 3.3 12’ 10” x 10’ 8”",
      "Bedroom 2": "2.9 x 3.0 9’ 4” x 9’ 10”",
      "Total Internal Area": "68.40m² 736 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "905",
      "Plot Name": "905",
      "viewID": "C-D-12m",
      "aspect": "NW/E",
      "unitName": "S01C-09-05",
      "unitType": "TYPE 3-1B/2P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "9",
      "beds": "1",
      "floor": "9",
      "Floor Area Sq. Ft.": "653.37",
      "Floor Area Sq. M.": "60.70",
      "price": "447500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "111875",
      "Max Percentage Tranche": "75",
      "Deposit": "5594",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "3.7 x 2.9 12’ 0” x 9’ 7”",
      "Living": null,
      "Bedroom 1": "3.3 x 3.7 10’ 8” x 12’ 0”",
      "Bedroom 2": null,
      "Total Internal Area": "58.40m² 629 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "1001",
      "Plot Name": "1001",
      "viewID": "A-B-3m",
      "aspect": "E",
      "unitName": "S01C-10-01",
      "unitType": "TYPE 1-1B/2P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "10",
      "beds": "1",
      "floor": "10",
      "Floor Area Sq. Ft.": "628.61",
      "Floor Area Sq. M.": "58.40",
      "price": "442500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "110625",
      "Max Percentage Tranche": "75",
      "Deposit": "5531",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "3.7 x 1.9 12’ 1” x 6’ 0”",
      "Living": null,
      "Bedroom 1": "4.3 x 2.9 13’ 11” x 9’ 6”",
      "Bedroom 2": null,
      "Total Internal Area": "58.40m² 629 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "1002",
      "Plot Name": "1002",
      "viewID": "C-D-12m",
      "aspect": "E/SW",
      "unitName": "S01C-10-02",
      "unitType": "TYPE 2-2B/4P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "10",
      "beds": "2",
      "floor": "10",
      "Floor Area Sq. Ft.": "881.56",
      "Floor Area Sq. M.": "81.90",
      "price": "557500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "139375",
      "Max Percentage Tranche": "75",
      "Deposit": "6969",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "2.7 x 3.7 9’ 0” x 12’ 2”",
      "Living": null,
      "Bedroom 1": "3.1 x 4.0 10’ 1” x 13’ 0”",
      "Bedroom 2": "4.8 x 2.9 15’ 10” x 9’ 4”",
      "Total Internal Area": "81.90m² 882 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "1003",
      "Plot Name": "1003",
      "viewID": "A-B-3m",
      "aspect": "SW",
      "unitName": "S01C-10-03",
      "unitType": "TYPE 1-2B/4P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "10",
      "beds": "2",
      "floor": "10",
      "Floor Area Sq. Ft.": "833.13",
      "Floor Area Sq. M.": "77.40",
      "price": "552500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "138125",
      "Max Percentage Tranche": "75",
      "Deposit": "6906",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "4.4 x 1.9 14’ 6” x 6’ 2”",
      "Living": null,
      "Bedroom 1": "2.8 x 4.5 9’ 1” x 14’ 8”",
      "Bedroom 2": "3.7 x 3.3 12’ 1” x 10’ 8”",
      "Total Internal Area": "77.40m² 833 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "1004",
      "Plot Name": "1004",
      "viewID": "C-D-12m",
      "aspect": "SW/NW",
      "unitName": "S01C-10-04",
      "unitType": "TYPE 2-2B/3P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "10",
      "beds": "2",
      "floor": "10",
      "Floor Area Sq. Ft.": "736.25",
      "Floor Area Sq. M.": "68.40",
      "price": "507500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "126875",
      "Max Percentage Tranche": "75",
      "Deposit": "6344",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": null,
      "Living": "3.3 x 3.6 10’ 9” x 11’ 11”",
      "Bedroom 1": "3.9 x 3.3 12’ 10” x 10’ 8”",
      "Bedroom 2": "2.9 x 3.0 9’ 4” x 9’ 10”",
      "Total Internal Area": "68.4m² 736 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "1005",
      "Plot Name": "1005",
      "viewID": "A-B-3m",
      "aspect": "NW/E",
      "unitName": "S01C-10-05",
      "unitType": "TYPE 3-1B/2P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "10",
      "beds": "1",
      "floor": "10",
      "Floor Area Sq. Ft.": "653.37",
      "Floor Area Sq. M.": "60.70",
      "price": "447500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "111875",
      "Max Percentage Tranche": "75",
      "Deposit": "5594",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "3.7 x 2.9 12’ 0” x 9’ 7”",
      "Living": null,
      "Bedroom 1": "3.3 x 3.7 10’ 8” x 12’ 0”",
      "Bedroom 2": null,
      "Total Internal Area": "58.40m² 629 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "1101",
      "Plot Name": "1101",
      "viewID": "C-D-12m",
      "aspect": "E",
      "unitName": "S01C-11-01",
      "unitType": "TYPE 1-1B/2P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "10",
      "beds": "1",
      "floor": "11",
      "Floor Area Sq. Ft.": "628.61",
      "Floor Area Sq. M.": "58.40",
      "price": "445000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "111250",
      "Max Percentage Tranche": "75",
      "Deposit": "5563",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "3.7 x 1.9 12’ 1” x 6’ 0”",
      "Living": null,
      "Bedroom 1": "4.3 x 2.9 13’ 11” x 9’ 6”",
      "Bedroom 2": null,
      "Total Internal Area": "58.40m² 629 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "1102",
      "Plot Name": "1102",
      "viewID": "A-B-3m",
      "aspect": "E/SW",
      "unitName": "S01C-11-02",
      "unitType": "TYPE 2-2B/4P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "11",
      "beds": "2",
      "floor": "11",
      "Floor Area Sq. Ft.": "881.56",
      "Floor Area Sq. M.": "81.90",
      "price": "565000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "141250",
      "Max Percentage Tranche": "75",
      "Deposit": "7063",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "2.7 x 3.7 9’ 0” x 12’ 2”",
      "Living": null,
      "Bedroom 1": "3.1 x 4.0 10’ 1” x 13’ 0”",
      "Bedroom 2": "4.8 x 2.9 15’ 10” x 9’ 4”",
      "Total Internal Area": "81.90m² 882 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "1103",
      "Plot Name": "1103",
      "viewID": "C-D-12m",
      "aspect": "SW",
      "unitName": "S01C-11-03",
      "unitType": "TYPE 1-2B/4P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "11",
      "beds": "2",
      "floor": "11",
      "Floor Area Sq. Ft.": "833.13",
      "Floor Area Sq. M.": "77.40",
      "price": "555000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "138750",
      "Max Percentage Tranche": "75",
      "Deposit": "6938",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "4.4 x 1.9 14’ 6” x 6’ 2”",
      "Living": null,
      "Bedroom 1": "2.8 x 4.5 9’ 1” x 14’ 8”",
      "Bedroom 2": "3.7 x 3.3 12’ 1” x 10’ 8”",
      "Total Internal Area": "77.40m² 833 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "1104",
      "Plot Name": "1104",
      "viewID": "A-B-3m",
      "aspect": "SW/NW",
      "unitName": "S01C-11-04",
      "unitType": "TYPE 2-2B/3P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "11",
      "beds": "2",
      "floor": "11",
      "Floor Area Sq. Ft.": "736.25",
      "Floor Area Sq. M.": "68.40",
      "price": "510000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "127500",
      "Max Percentage Tranche": "75",
      "Deposit": "6375",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": null,
      "Living": "3.3 x 3.6 10’ 9” x 11’ 11”",
      "Bedroom 1": "3.9 x 3.3 12’ 10” x 10’ 8”",
      "Bedroom 2": "2.9 x 3.0 9’ 4” x 9’ 10”",
      "Total Internal Area": "68.4m² 736 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "1105",
      "Plot Name": "1105",
      "viewID": "C-D-12m",
      "aspect": "NW/E",
      "unitName": "S01C-11-05",
      "unitType": "TYPE 3-1B/2P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "11",
      "beds": "1",
      "floor": "11",
      "Floor Area Sq. Ft.": "653.37",
      "Floor Area Sq. M.": "60.70",
      "price": "450000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "112500",
      "Max Percentage Tranche": "75",
      "Deposit": "5625",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "3.7 x 2.9 12’ 0” x 9’ 7”",
      "Living": null,
      "Bedroom 1": "3.3 x 3.7 10’ 8” x 12’ 0”",
      "Bedroom 2": null,
      "Total Internal Area": "58.40m² 629 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "1201",
      "Plot Name": "1201",
      "viewID": "A-B-3m",
      "aspect": "E",
      "unitName": "S01C-12-01",
      "unitType": "TYPE 1-1B/2P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "12",
      "beds": "1",
      "floor": "12",
      "Floor Area Sq. Ft.": "628.61",
      "Floor Area Sq. M.": "58.40",
      "price": "445000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "111250",
      "Max Percentage Tranche": "75",
      "Deposit": "5563",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "3.7 x 1.9 12’ 1” x 6’ 0”",
      "Living": null,
      "Bedroom 1": "4.3 x 2.9 13’ 11” x 9’ 6”",
      "Bedroom 2": null,
      "Total Internal Area": "58.40m² 629 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "1202",
      "Plot Name": "1202",
      "viewID": "C-D-12m",
      "aspect": "E/SW",
      "unitName": "S01C-12-02",
      "unitType": "TYPE 2-2B/4P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "12",
      "beds": "2",
      "floor": "12",
      "Floor Area Sq. Ft.": "881.56",
      "Floor Area Sq. M.": "81.90",
      "price": "565000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "141250",
      "Max Percentage Tranche": "75",
      "Deposit": "7063",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "2.7 x 3.7 9’ 0” x 12’ 2”",
      "Living": null,
      "Bedroom 1": "3.1 x 4.0 10’ 1” x 13’ 0”",
      "Bedroom 2": "4.8 x 2.9 15’ 10” x 9’ 4”",
      "Total Internal Area": "81.90m² 882 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "1203",
      "Plot Name": "1203",
      "viewID": "A-B-3m",
      "aspect": "SW",
      "unitName": "S01C-12-03",
      "unitType": "TYPE 1-2B/4P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "12",
      "beds": "2",
      "floor": "12",
      "Floor Area Sq. Ft.": "833.13",
      "Floor Area Sq. M.": "77.40",
      "price": "555000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "138750",
      "Max Percentage Tranche": "75",
      "Deposit": "6938",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "4.4 x 1.9 14’ 6” x 6’ 2”",
      "Living": null,
      "Bedroom 1": "2.8 x 4.5 9’ 1” x 14’ 8”",
      "Bedroom 2": "3.7 x 3.3 12’ 1” x 10’ 8”",
      "Total Internal Area": "77.40m² 833 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "1204",
      "Plot Name": "1204",
      "viewID": "C-D-12m",
      "aspect": "SW/NW",
      "unitName": "S01C-12-04",
      "unitType": "TYPE 2-2B/3P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "12",
      "beds": "2",
      "floor": "12",
      "Floor Area Sq. Ft.": "736.25",
      "Floor Area Sq. M.": "68.40",
      "price": "510000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "127500",
      "Max Percentage Tranche": "75",
      "Deposit": "6375",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": null,
      "Living": "3.3 x 3.6 10’ 9” x 11’ 11”",
      "Bedroom 1": "3.9 x 3.3 12’ 10” x 10’ 8”",
      "Bedroom 2": "2.9 x 3.0 9’ 4” x 9’ 10”",
      "Total Internal Area": "68.4m² 736 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "1205",
      "Plot Name": "1205",
      "viewID": "A-B-3m",
      "aspect": "NW/E",
      "unitName": "S01C-12-05",
      "unitType": "TYPE 3-1B/2P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "12",
      "beds": "1",
      "floor": "12",
      "Floor Area Sq. Ft.": "653.37",
      "Floor Area Sq. M.": "60.70",
      "price": "450000",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "112500",
      "Max Percentage Tranche": "75",
      "Deposit": "5625",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "3.7 x 2.9 12’ 0” x 9’ 7”",
      "Living": null,
      "Bedroom 1": "3.3 x 3.7 10’ 8” x 12’ 0”",
      "Bedroom 2": null,
      "Total Internal Area": "58.40m² 629 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "1301",
      "Plot Name": "1301",
      "viewID": "C-D-12m",
      "aspect": "E",
      "unitName": "S01C-13-01",
      "unitType": "TYPE 1-1B/2P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "13",
      "beds": "1",
      "floor": "13",
      "Floor Area Sq. Ft.": "628.61",
      "Floor Area Sq. M.": "58.40",
      "price": "447500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "111875",
      "Max Percentage Tranche": "75",
      "Deposit": "5594",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "3.7 x 1.9 12’ 1” x 6’ 0”",
      "Living": null,
      "Bedroom 1": "4.3 x 2.9 13’ 11” x 9’ 6”",
      "Bedroom 2": null,
      "Total Internal Area": "58.40m² 629 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "1302",
      "Plot Name": "1302",
      "viewID": "A-B-3m",
      "aspect": "E/SW",
      "unitName": "S01C-13-02",
      "unitType": "TYPE 2-2B/4P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "13",
      "beds": "2",
      "floor": "13",
      "Floor Area Sq. Ft.": "881.56",
      "Floor Area Sq. M.": "81.90",
      "price": "567500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "141875",
      "Max Percentage Tranche": "75",
      "Deposit": "7094",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "2.7 x 3.7 9’ 0” x 12’ 2”",
      "Living": null,
      "Bedroom 1": "3.1 x 4.0 10’ 1” x 13’ 0”",
      "Bedroom 2": "4.8 x 2.9 15’ 10” x 9’ 4”",
      "Total Internal Area": "81.90m² 882 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "1303",
      "Plot Name": "1303",
      "viewID": "C-D-12m",
      "aspect": "SW",
      "unitName": "S01C-13-03",
      "unitType": "TYPE 1-2B/4P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "13",
      "beds": "2",
      "floor": "13",
      "Floor Area Sq. Ft.": "833.13",
      "Floor Area Sq. M.": "77.40",
      "price": "557500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "139375",
      "Max Percentage Tranche": "75",
      "Deposit": "6969",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "4.4 x 1.9 14’ 6” x 6’ 2”",
      "Living": null,
      "Bedroom 1": "2.8 x 4.5 9’ 1” x 14’ 8”",
      "Bedroom 2": "3.7 x 3.3 12’ 1” x 10’ 8”",
      "Total Internal Area": "77.40m² 833 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "1304",
      "Plot Name": "1304",
      "viewID": "A-B-3m",
      "aspect": "SW/NW",
      "unitName": "S01C-13-04",
      "unitType": "TYPE 2-2B/3P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "13",
      "beds": "2",
      "floor": "13",
      "Floor Area Sq. Ft.": "736.25",
      "Floor Area Sq. M.": "68.40",
      "price": "512500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "128125",
      "Max Percentage Tranche": "75",
      "Deposit": "6406",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": null,
      "Living": "3.3 x 3.6 10’ 9” x 11’ 11”",
      "Bedroom 1": "3.9 x 3.3 12’ 10” x 10’ 8”",
      "Bedroom 2": "2.9 x 3.0 9’ 4” x 9’ 10”",
      "Total Internal Area": "68.4m² 736 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "1305",
      "Plot Name": "1305",
      "viewID": "C-D-12m",
      "aspect": "NW/E",
      "unitName": "S01C-13-05",
      "unitType": "TYPE 3-1B/2P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "13",
      "beds": "1",
      "floor": "13",
      "Floor Area Sq. Ft.": "653.37",
      "Floor Area Sq. M.": "60.70",
      "price": "452500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "113125",
      "Max Percentage Tranche": "75",
      "Deposit": "5656",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "3.7 x 2.9 12’ 0” x 9’ 7”",
      "Living": null,
      "Bedroom 1": "3.3 x 3.7 10’ 8” x 12’ 0”",
      "Bedroom 2": null,
      "Total Internal Area": "58.40m² 629 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "1401",
      "Plot Name": "1401",
      "viewID": "A-B-3m",
      "aspect": "E",
      "unitName": "S01C-14-01",
      "unitType": "TYPE 1-1B/2P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "14",
      "beds": "1",
      "floor": "14",
      "Floor Area Sq. Ft.": "628.61",
      "Floor Area Sq. M.": "58.40",
      "price": "447500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "111875",
      "Max Percentage Tranche": "75",
      "Deposit": "5594",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "3.7 x 1.9 12’ 1” x 6’ 0”",
      "Living": null,
      "Bedroom 1": "4.3 x 2.9 13’ 11” x 9’ 6”",
      "Bedroom 2": null,
      "Total Internal Area": "58.40m² 629 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "1402",
      "Plot Name": "1402",
      "viewID": "C-D-12m",
      "aspect": "E/SW",
      "unitName": "S01C-14-02",
      "unitType": "TYPE 2-2B/4P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "14",
      "beds": "2",
      "floor": "14",
      "Floor Area Sq. Ft.": "881.56",
      "Floor Area Sq. M.": "81.90",
      "price": "567500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "141875",
      "Max Percentage Tranche": "75",
      "Deposit": "7094",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "2.7 x 3.7 9’ 0” x 12’ 2”",
      "Living": null,
      "Bedroom 1": "3.1 x 4.0 10’ 1” x 13’ 0”",
      "Bedroom 2": "4.8 x 2.9 15’ 10” x 9’ 4”",
      "Total Internal Area": "81.90m² 882 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "1403",
      "Plot Name": "1403",
      "viewID": "A-B-3m",
      "aspect": "SW",
      "unitName": "S01C-14-03",
      "unitType": "TYPE 1-2B/4P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "14",
      "beds": "2",
      "floor": "14",
      "Floor Area Sq. Ft.": "833.13",
      "Floor Area Sq. M.": "77.40",
      "price": "557500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "139375",
      "Max Percentage Tranche": "75",
      "Deposit": "6969",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "4.4 x 1.9 14’ 6” x 6’ 2”",
      "Living": null,
      "Bedroom 1": "2.8 x 4.5 9’ 1” x 14’ 8”",
      "Bedroom 2": "3.7 x 3.3 12’ 1” x 10’ 8”",
      "Total Internal Area": "77.40m² 833 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "1404",
      "Plot Name": "1404",
      "viewID": "C-D-12m",
      "aspect": "SW/NW",
      "unitName": "S01C-14-04",
      "unitType": "TYPE 2-2B/3P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "14",
      "beds": "2",
      "floor": "14",
      "Floor Area Sq. Ft.": "736.25",
      "Floor Area Sq. M.": "68.40",
      "price": "512500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "128125",
      "Max Percentage Tranche": "75",
      "Deposit": "6406",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": null,
      "Living": "3.3 x 3.6 10’ 9” x 11’ 11”",
      "Bedroom 1": "3.9 x 3.3 12’ 10” x 10’ 8”",
      "Bedroom 2": "2.9 x 3.0 9’ 4” x 9’ 10”",
      "Total Internal Area": "68.4m² 736 ft²",
      "Total Terrace Area": null
   },
    {
      "Flat Number": "1405",
      "Plot Name": "1405",
      "viewID": "A-B-3m",
      "aspect": "NW/E",
      "unitName": "S01C-14-05",
      "unitType": "TYPE 3-1B/2P",
      "fileName": null,
      "status": "Active",
      "Flat Floor Level": "14",
      "beds": "1",
      "floor": "14",
      "Floor Area Sq. Ft.": "653.37",
      "Floor Area Sq. M.": "60.70",
      "price": "452500",
      "Price qualifier": "Shared Ownership",
      "Current 1st Tranche %": "25",
      "1st tranche value": "113125",
      "Max Percentage Tranche": "75",
      "Deposit": "5656",
      "Monthly Service Charge": null,
      "Monthly Rent (SO)": null,
      "Monthly Mortgage": null,
      "Total Monthly Costs": null,
      "Length of Lease": "999",
      "Kitchen": "3.7 x 2.9 12’ 0” x 9’ 7”",
      "Living": null,
      "Bedroom 1": "3.3 x 3.7 10’ 8” x 12’ 0”",
      "Bedroom 2": null,
      "Total Internal Area": "58.40m² 629 ft²",
      "Total Terrace Area": null
   }
   ]