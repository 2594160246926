const isFilterValid = (filterArray?: any[]) =>
  !filterArray || filterArray.length === 0;

export function filterTable(filters, data: any[], activeType): any[] {
  return data.map((item) => {
    const isFloorValid = isFilterValid(filters.floor) ||
      (filters.floor?.length === 2 &&
        item.floor >= filters.floor[0] &&
        item.floor <= filters.floor[1]);

    const isPriceValid = isFilterValid(filters.price) ||
      (filters.price?.length === 2 &&
        Number(item.price) >= Number(filters.price[0]) &&
        Number(item.price) <= Number(filters.price[1]));

    const isBedroomValid = isFilterValid(filters.beds) ||
      filters.beds?.includes(item.beds?.toString());

    const isStatusValid = item.status === "Active";

    const block = item.unitName.split("-")[0];

    const isBlockValid = isFilterValid(filters.blocks) ||
      filters.blocks?.includes(block);

    const isTypeValid = activeType === item.unitType || activeType === null;

    // const unit = selectedUnit?.[0]?.unitName;

    // const isSelectValid = !unit || unit === item.unitName

    const filtered =
      // isSelectValid &&
      isStatusValid &&
      isBlockValid &&
      isFloorValid &&
      isPriceValid &&
      isTypeValid &&
      isBedroomValid;

    return {
      ...item,
      // active: isSelectValid ? "selected" : filtered,
      active: filtered,
    };
  });
}

export function sortTable(data: any[], sortConfig: any) {
  if (!sortConfig.field) {
    return { sortedData: data, newSortConfig: sortConfig };
  }

  const newDirection = sortConfig.direction === "ascending"
    ? "descending"
    : "ascending";

  const sortedData = [...data].sort((a, b) => {
    let valueA = a[sortConfig.field];
    let valueB = b[sortConfig.field];

    if (sortConfig.field === "unitID") {
      const [numA, alphaA] = valueA.match(/(\d+)([A-Za-z]*)/).slice(1, 3);
      const [numB, alphaB] = valueB.match(/(\d+)([A-Za-z]*)/).slice(1, 3);

      const numericComparison = parseInt(numA, 10) - parseInt(numB, 10);
      if (numericComparison !== 0) {
        return newDirection === "ascending"
          ? numericComparison
          : -numericComparison;
      }

      return newDirection === "ascending"
        ? alphaA.localeCompare(alphaB)
        : alphaB.localeCompare(alphaA);
    }

    if (!isNaN(valueA) && !isNaN(valueB)) {
      valueA = parseFloat(valueA);
      valueB = parseFloat(valueB);
    }

    if (newDirection === "ascending") {
      return valueA > valueB ? 1 : -1;
    } else {
      return valueA < valueB ? 1 : -1;
    }
  });

  return {
    sortedData,
    newSortConfig: { field: sortConfig.field, direction: newDirection },
  };
}

type UnitData = {
  price: string | number;
  floor: string | number;
  unitName: string;
  beds: string | number;
  baths: string | number;
};

type Filters = {
  priceRange: [number, number];
  floorRange: [number, number];
  bedOptions: string[];
  blockOptions: string[];
};

export const getFiltersDynamically = (data: UnitData[] | null): Filters => {
  if (!data) {
    return {
      priceRange: [0, 0],
      floorRange: [0, 0],
      bedOptions: [],
      blockOptions: [],
    };
  }

  const parsePrice = (price: string | number): number => {
    if (typeof price === "string") {
      return parseInt(price.replace(/[^0-9]/g, ""), 10);
    }
    return price;
  };

  const prices = data.map((unit: UnitData) => parsePrice(unit.price));
  const floors = data.map((unit: UnitData) =>
    parseInt(unit.floor.toString(), 10)
  );

  const blocks = new Set(data.map((d) => d.unitName.split("-")[0]));

  const priceRange: [number, number] = [
    Math.min(...prices),
    Math.max(...prices),
  ];
  const floorRange: [number, number] = [
    Math.min(...floors),
    Math.max(...floors),
  ];

  const getRoundingFactor = (price: number): number => {
    if (price >= 1000000) return 1000000;
    else if (price >= 100000) return 100000;
    return 10000;
  };

  const roundDown = (value: number): number => {
    const factor = getRoundingFactor(value);
    return Math.floor(value / factor) * factor;
  };
  const roundUp = (value: number): number => {
    const factor = getRoundingFactor(value);
    return Math.ceil(value / factor) * factor;
  };

  const roundedPriceRange: [number, number] = [
    roundDown(priceRange[0]),
    roundUp(priceRange[1]),
  ];

  const bedOptions = [
    ...new Set(
      data.map((unit: UnitData) => parseInt(unit.beds.toString(), 10) || 0),
    ),
  ]
    .filter((b) => !isNaN(b))
    .map((b: number) => String(b));

  return {
    priceRange: roundedPriceRange,
    floorRange,
    bedOptions,
    blockOptions: Array.from(blocks),
  };
};
